import {ApiClient} from './api-client';
import {Observable, ReplaySubject} from 'rxjs';
import {Endpoints} from './endpoints';
import {GenerateUploadUrlRequest} from '../models/image/requests/generate-upload-url-request';
import {SignedUploadUrl} from '../models/shared/signed-upload-url';
import {LoggableAPI} from '../models/protocols/loggable-api';
import {LoggingService} from '../services/logging-service';
import {Injectable} from '@angular/core';
import {HttpBackend, HttpClient, HttpHeaders} from '@angular/common/http';
import {MediaUtils} from '../utils/media-utils';
import {FullImage} from '../models/image/shared/full-image';
import * as buffer from 'buffer';
import {CreateCompanyLogoRequest} from '../models/account/requests/createCompanyLogoRequest';
import {Asset} from '../models/image/dto/asset';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {EntityApi} from './entity-api';
import {AssetSize} from '../models/enum/dto/image-size.enum';

export type ImageServiceCacheType = 'company';

@Injectable({
  providedIn: 'root'
})
export class ImageAPI implements LoggableAPI {
  private backendHttpClient: HttpClient;
  cachedMedia = new Map<string, ReplaySubject<string | SafeResourceUrl>>();
  sizes = ['thumb', 'small', 'medium', 'large', 'original'];
  CacheErrorTimeOut = 5000;

  constructor(
    private apiClient: ApiClient,
    private httpBackend: HttpBackend,
    private loggingService: LoggingService,
    private sanitizer: DomSanitizer,
    private entityApi: EntityApi,
  ) {
    this.backendHttpClient = new HttpClient(httpBackend);
  }

  // Variables

  public serviceName = 'Image';

  // Asset

  public deleteAsset(id, md5: string): Observable<string> {
    const url = Endpoints.deleteAsset(id, md5);
    return this.apiClient.deleteStr(url, null, null, 'text');
  }

  public generateUploadUrl(req: GenerateUploadUrlRequest): Observable<SignedUploadUrl> {
    const url = Endpoints.generateUploadUrl();
    return this.apiClient.postObj<SignedUploadUrl>(SignedUploadUrl, url, req);
  }

  public getPreview(locationId: number, displayId, menuId: string, returnLastSaved: boolean): Observable<FullImage> {
    const url = Endpoints.getPreview(locationId, displayId, menuId, returnLastSaved);
    return this.apiClient.getObj<FullImage>(FullImage, url);
  }

  public putImageUploadUrl(url: string, file: string, fileName: string): Observable<any> {
    const adjustedFileName = fileName.replace(' ', '').toLowerCase();
    const type = MediaUtils.getMediaType(adjustedFileName.split('.').pop());
    let newFileContents = file.replace(/^data:image\/\w+;base64,/, '');
    newFileContents = newFileContents.replace(/^data:video\/\w+;base64,/, '');

    const buff = buffer.Buffer.from(newFileContents, 'base64');
    let headers = new HttpHeaders();
    headers = headers.append('Content-Type', type);
    headers = headers.append('Content-Encoding', 'base64');

    const blob = new Blob([new Uint8Array(buff)]);
    return this.backendHttpClient.put<any>(url, blob, {headers});
  }

  public getAsset(id, md5Hash: string): Observable<FullImage> {
    const url = Endpoints.getAsset(id, md5Hash);
    return this.apiClient.getObj<FullImage>(FullImage, url);
  }

  public getBlobFromUrl(url: string): Observable<Blob> {
    return this.apiClient.getBlob<Blob>(url);
  }

  public createCompanyLogo(companyId: number, req: CreateCompanyLogoRequest): Observable<FullImage> {
    const url = Endpoints.createCompanyLogo(companyId);
    return this.apiClient.postObj(FullImage, url, req);
  }

  clearCachedImages(type: ImageServiceCacheType, id: number) {
    this.sizes.forEach(s => {
      const key = this.getCacheKey(type, id, s);
      this.cachedMedia.delete(key);
    });
  }

  imageUploadSuccessful(cacheType: ImageServiceCacheType, id: number, fileUrl: string | SafeResourceUrl) {
    this.clearCachedImages(cacheType, id);
    this.sizes.forEach(s => {
      const r = new ReplaySubject<string | SafeResourceUrl>(1);
      this.cachedMedia.set(this.getCacheKey(cacheType, id, s), r);
      r.next(fileUrl);
    });
  }

  private getCacheKey(type: ImageServiceCacheType, id: number, size: string): string {
    return type + id + size;
  }

  public getCompanyImage(companyId: number, size: AssetSize ,isPublic:boolean): Observable<string | SafeResourceUrl> {
    const key = this.getCacheKey('company', companyId, size);
    return this.getCachedMedia(key, size, this.entityApi.getCompanyLogos(companyId,isPublic));
  }

  public getPresignedUrl(asset: Asset, size: string): string {
    return asset?.links.find(l => l.size === size)?.presignedUrl;
  }

  private getCachedMedia(key: string, size: string, imageResourceObservable: Observable<Asset[]>): Observable<string | SafeResourceUrl> {
    if (this.cachedMedia.has(key)) {
      return this.cachedMedia.get(key);
    }

    const errorHandler = (error: Error) => {
      setTimeout(() => {
        this.cachedMedia.delete(key);
      }, this.CacheErrorTimeOut);
      r.error(error);
    };

    const r = new ReplaySubject<string | SafeResourceUrl>(1);
    imageResourceObservable.subscribe(photos => {
      const primaryPhoto = photos[0];
      const url = this.getPresignedUrl(primaryPhoto, size);
      if (url == null) {
        r.next(null);
        return;
      }
      this.backendHttpClient.get(url, {responseType: 'blob'}).subscribe(blob => {
        r.next(this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob)));
      }, errorHandler);
    }, errorHandler);
    this.cachedMedia.set(key, r);
    return r;
  }
}
