import { Component, OnInit } from '@angular/core';
import { NavItem } from '../../../models/shared/nav-item';
import { ActivatedRoute, Router } from '@angular/router';
import { Viewable } from '../../../models/protocols/viewable';
import '../../../utils/observable.extensions';
import { ToastService } from '../../../services/toast-service';
import { NavItemId } from '../../../models/enum/shared/nav-item-id.enum';
import { BaseComponent } from '../../../models/base/base-component';
import { SidebarNavViewModel } from './sidebar-nav-view-model';
import { SessionService } from '../../../services/session-service';

@Component({
    selector: 'app-sidebar-navigation',
    templateUrl: './sidebar-nav.component.html',
    styleUrls: ['./sidebar-nav.component.scss'],
    providers: [SidebarNavViewModel]
})
export class SidebarNavComponent extends BaseComponent implements OnInit, Viewable {
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        public viewModel: SidebarNavViewModel,
        private toastService: ToastService,
        private session: SessionService
    ) {
        super();
    }

    ngOnInit(): void {
        this.setupBindings();
        this.setupViews();
        // if (this.router.url === "/unsubscribed-email-logs") {
        //     this.subMenuHideShow = !this.subMenuHideShow
        // }
    }

    setupViews() {
    }

    setupBindings() {
    }

    navigateToRoot() {
        this.router.navigate(['/search-orders']);
    }

    async navItemSelected(navItem: NavItem) {
        if (navItem.id === NavItemId.SignOut) {
            this.signOutClicked();
        } else if (navItem.id === NavItemId.InvalidateSession) {
            this.testRefreshSession();
        } else {
            if (navItem?.subMenu?.length > 0) {
                this.viewModel.navItems.forEach(x => {
                    if (navItem.id != x.id)
                        x.showSubmenu = false;
                });
                navItem.showSubmenu = !navItem.showSubmenu;
            }
            else {
                this.viewModel.navItems.forEach(x => { x.showSubmenu = false; });
            }
            this.router.navigate([navItem.routerPath]);
        }

        this.viewModel.navItems.forEach(x => {

            //Admin

            if (x.id === 22 && navItem.id !== 22)
                x.routerPath = "/admin-sent-email-logs";

            if (navItem.id === 24 && x.id === 22) {
                x.routerPath = "/admin-unsubscribed-email";
            }

            if (navItem.id === 29 && x.id === 22) {
                x.routerPath = "/admin-unsubscribed-sms";
            }

            if (navItem.id === 28 && x.id === 22)
                x.routerPath = "/admin-sent-sms-logs";

            //Client
            if (x.id === 19 && navItem.id !== 19)
                x.routerPath = "/sent-email-logs";

            if (navItem.id === 21 && x.id === 19) {
                 x.routerPath = "/unsubscribed-email-logs";
            }
            if (navItem.id === 27 && x.id === 19) {
                x.routerPath = "/unsubscribed-sms-logs";
            }

            if (navItem.id == 26 && x.id === 19)
                x.routerPath = "/sent-sms-logs";  
            
        })

        // if (navItem.id === 21) {
        //     this.viewModel.navItems.forEach(x => {
        //         if (x.id === 19)
        //             x.routerPath = "/unsubscribed-email-logs";
        //     })
        // }

        // if (navItem.id === 27) {
        //     this.viewModel.navItems.forEach(x => {
        //         if (x.id === 25)
        //             x.routerPath = "/unsubscribed-sms-logs";
        //     })
        // }
        

    }

    subMenuSelected(navItem: NavItem) {
        this.navItemSelected(navItem)
        navItem.showSubmenu = !navItem.showSubmenu
    }

    testRefreshSession() {
        const s = this.viewModel.sessionContainer$.value;
        s.user.userSession.accessToken = null;
        this.toastService.publishInfoMessage('Session Invalidated');
    }

    signOutClicked() {
        this.viewModel.signOut();
    }

    getBadgeContentForNavItem(navItem: NavItem): string {
        return this.viewModel.badgeContent.get(navItem.routerPath);
    }

    isNavItemActive(navItem: NavItem): boolean {
        if (navItem.id === 19 || navItem.id === 22 || navItem.id === 25)
            return false;

        return this.router.url.startsWith(navItem.routerPath);
    }

    multilineNavLabel(i: NavItem): boolean {
        return i.name.length > 12;
    }

    showNavItem(navItem: NavItem): boolean {
        return this.session.hasPermission(navItem.permissionRule);
    }

    manageSMSSubMenuRoute(navItem: NavItem) {
        if (navItem.id === 25) {
            this.viewModel.navItems.forEach(x => {
                if (x.id === 26)
                    x.routerPath = "/sent-sms-logs";
                if (x.id === 27)
                    x.routerPath = "/unsubscribed-sms-logs";
            });
        }

        // if (navItem.id === 25)
        //     navItem.routerPath = "/sent-sms-logs";

        if (navItem.id === 26)
            navItem.routerPath = "/sent-sms-logs";

        if (navItem.id === 27)
            navItem.routerPath = "/unsubscribed-sms-logs";

        return navItem;
    }
}
