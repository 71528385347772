import { Component } from '@angular/core';
import {BaseIconComponent} from '../base-icon-component/base-icon-component';

@Component({
  selector: 's2d-icon-office-building-outline',
  templateUrl: `./office-building-outline.html`,
  styleUrls: ['./office-building-outline.scss'],
})
export class OfficeBuildingOutlineIconComponent extends BaseIconComponent {
}
