// noinspection JSMethodCanBeStatic

import {ApiClient} from './api-client';
import {Observable} from 'rxjs';
import {Endpoints} from './endpoints';
import {SignInRequest} from '../models/account/requests/sign-in-request';
import {Injectable} from '@angular/core';
import {LoggableAPI} from '../models/protocols/loggable-api';
import {User} from '../models/account/dto/user';
import {RefreshSessionRequest} from '../models/account/requests/refresh-session-request';
import {RefreshSessionResponse} from '../models/account/dto/refresh-session-response';
import {ResetPasswordRequest} from '../models/account/requests/reset-password-request';
import {ResetPasswordResponse} from '../models/account/dto/reset-password-response';
import {environment} from '../../environments/environment';
import {ZendeskToken} from '../models/account/dto/zendesk-token';
import {UpdateQrCodeRequest} from '../models/account/requests/update-qr-code-request';
import {UpdateQrCodeResponse} from '../models/account/dto/update-qr-code-response';
import {OneTimePasswordRequest} from '../models/account/requests/one-time-password-request';
import {OneTimePasswordResponse} from '../models/account/dto/one-time-password-response';
import {LoginRequest} from "./auth/requests/login-request";
import {NewPasswordRequest} from "../models/account/requests/new-password-request";

@Injectable({
  providedIn: 'root',
})
export class AccountAPI implements LoggableAPI {

  constructor(
    private apiClient: ApiClient,
  ) {
  }

  // Variables
  public serviceName = 'Account';

  // Session

  private isDriverCompanyId(companyId: number): boolean {
    return companyId === environment.driverCompanyId;
  }

  public signIn(companyId: number, email: string, req: SignInRequest): Observable<User> {
    const url = this.isDriverCompanyId(companyId) ?
      Endpoints.driverLogin(Endpoints.encodeParam(email)) :
      Endpoints.login(companyId, Endpoints.encodeParam(email));

    return this.apiClient.postObj<User>(User, url, req);
  }

  public signOut(companyId: number, email: string): Observable<any> {
    const url = this.isDriverCompanyId(companyId) ?
      Endpoints.driverSignOut(Endpoints.encodeParam(email)) :
      Endpoints.signOut(companyId, Endpoints.encodeParam(email));
    return this.apiClient.postObj<any>(User, url, null, null, 'text');
  }

  public refreshSession(companyId: number, email: string, req: RefreshSessionRequest): Observable<RefreshSessionResponse> {
    const url = this.isDriverCompanyId(companyId) ?
      Endpoints.driverRefreshSession(Endpoints.encodeParam(email)) :
      Endpoints.refreshSession(companyId, Endpoints.encodeParam(email));
    return this.apiClient.postObj<RefreshSessionResponse>(RefreshSessionResponse, url, req);
  }

  public getPasswordResetCode(companyId: number, email: string): Observable<string> {
    const url = this.isDriverCompanyId(companyId) ?
      Endpoints.driverGetForgotPasswordCode(Endpoints.encodeParam(email)) :
      Endpoints.getForgotPasswordCode(companyId, Endpoints.encodeParam(email));
    return this.apiClient.getStr(url);
  }

  public resetPassword(companyId: number, email: string, req: ResetPasswordRequest): Observable<ResetPasswordResponse> {
    const url = this.isDriverCompanyId(companyId) ?
      Endpoints.driverResetForgottenPassword(Endpoints.encodeParam(email)) :
      Endpoints.resetForgottenPassword(companyId, Endpoints.encodeParam(email));
    return this.apiClient.postObj<ResetPasswordResponse>(ResetPasswordResponse, url, req);
  }

  public updateQrCode(req: UpdateQrCodeRequest): Observable<User> {
      const url = this.isDriverCompanyId(req.companyId) ?
          Endpoints.driverLogin(Endpoints.encodeParam(req.email)) :
          Endpoints.login(req.companyId, Endpoints.encodeParam(req.email));
      return this.apiClient.postObj<any>(User, url, req);
  }

  public submitOneTimePassword(req: OneTimePasswordRequest): Observable<User> {
      const url = this.isDriverCompanyId(req.companyId) ?
          Endpoints.driverLogin(Endpoints.encodeParam(req.email)) :
          Endpoints.login(req.companyId, Endpoints.encodeParam(req.email));
      return this.apiClient.postObj<any>(User, url, req);
  }

  public submitNewPassword(req: NewPasswordRequest): Observable<User> {
      const url = this.isDriverCompanyId(req.companyId) ?
          Endpoints.driverLogin(Endpoints.encodeParam(req.email)) :
          Endpoints.login(req.companyId, Endpoints.encodeParam(req.email));
      return this.apiClient.postObj<any>(User, url, req);
  }

  public getZendeskToken(user: User): Observable<ZendeskToken> {
    const url = this.isDriverCompanyId(user.companyId) ?
        Endpoints.driverGetZendeskToken(user.email) :
        Endpoints.getZendeskToken(user.email, user.companyId);
    return this.apiClient.getObj(ZendeskToken, url);
  }

  public checkPhone(phone: string, id?: number) {
      const url = Endpoints.driverCheckPhone(phone, id);
      return this.apiClient.getStr(url);
  }

  public checkEmail(email: string) {
      const url = Endpoints.driverCheckEmail(email);
      return this.apiClient.getStr(url);
  }

}
