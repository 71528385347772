import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DateInputComponent} from "./components/date-input/date-input.component";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {S2dIconsModule} from "../../icons/s2d-icons.module";
import {StdTextModule} from "../../std-text/std-text.module";
import {StdFormsModule} from "../../std-forms/std-forms.module";
import {ReactiveFormsModule} from "@angular/forms";
import {StdFormControlProviders} from "../../std-forms/std-form-control-providers";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from "@angular/material/core";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {STD_DATE_FORMATS} from "./date-constants";
import {StdLayoutModule} from "../../layout/std-layout.module";
import {DayOfWeekControlComponent} from "./day-of-week-control/day-of-week-control.component";
import { DaysOfWeekPipe } from './pipes/days-of-week.pipe';
import {TimePipe} from "./pipes/time.pipe";

@NgModule({
  declarations: [
      DateInputComponent,
      DayOfWeekControlComponent,
      TimePipe,
      DaysOfWeekPipe
  ],
  imports: [
    CommonModule,
    MatDatepickerModule,
    S2dIconsModule,
    StdTextModule,
    StdFormsModule,
    StdLayoutModule,
    ReactiveFormsModule
  ],
    exports: [
        DateInputComponent,
        DayOfWeekControlComponent,
        TimePipe,
        DaysOfWeekPipe
    ],
  providers: [
    StdFormControlProviders.formField(DateInputComponent),
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: STD_DATE_FORMATS},
  ],
})
export class DateModule { }
