import { Component, Input, ViewEncapsulation } from '@angular/core';
import { FontSizes } from '../font-sizes';

export type LabelSizes = FontSizes | 'hint';

@Component({
  selector: 'std-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LabelComponent {
  @Input() size: LabelSizes = 'sm';
  @Input() subLabel: boolean = false;
  @Input() inline: boolean = false;
}
