/* tslint:disable:max-line-length */
import {Injectable} from '@angular/core';
import {LoggableAPI} from '../models/protocols/loggable-api';
import {ApiClient} from './api-client';
import {Observable} from 'rxjs';
import {Endpoints} from './endpoints';
import {Company} from '../models/account/dto/company';
import {Asset} from '../models/image/dto/asset';
import {CompanyLocation} from '../models/account/dto/company-location';
import {PickupTime} from '../models/account/dto/pickup-time';
import {DeliveryZone, DeliveryZoneRate} from '../models/account/dto/delivery-zone';
import {DeserializeHelper} from '../models/protocols/deserializable';
import {CompanyIntegration} from '../models/account/dto/company-integration';
import {LocationIntegration} from '../models/account/dto/company-location-integration';
import {ExtraCharge} from '../models/account/dto/extra-charge';
import {PickupLocationCity} from '../models/account/dto/pickup-location-city';
import {CompanyPreview} from '../models/account/dto/company-preview';
import { ApiResponse } from '../models/account/response/ApiResponse';
import { ClientCompany, ClientCompanyLocation } from '../models/account/dto/clientCompany';
import { CompanyEmailLogs } from '../models/account/dto/company-email-logs';
import { EmailLogRequest } from '../models/account/requests/email-log-request';
import { UnsubscribeEmailRequest } from '../models/account/requests/UnsubscribeEmailRequest';
import { CompanyUnsubscribedEmail } from '../models/account/dto/compnay-unsubscribed-email';
import { SMSLogRequest } from '../models/account/requests/SMSLogRequest';
import { UnsubscribeSMSRequest } from '../models/account/requests/unsubscribe-sms-request';
import { CompanyUnsubscribedSMS } from '../models/account/dto/compnay-unsubscribed-sms';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class EntityApi implements LoggableAPI {

  constructor(
    private apiClient: ApiClient,
  ) {
  }

  // Variables
  public serviceName = 'Entity';

  public getCompanies(): Observable<Company[]> {
    const url = Endpoints.getCompanies();
    return this.apiClient.getArr(Company, url);
  }

  public getPickupLocationCities(): Observable<PickupLocationCity[]> {
    const url = Endpoints.getPickupLocationCities();
    let additionalHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'S2D-Api-Key': `${environment.publicAPI}`
    });
    return this.apiClient.getArr(PickupLocationCity, url, additionalHeaders);
  }

  public getCompanyLocations(companyId: number): Observable<CompanyLocation[]> {
    const url = Endpoints.getCompanyLocations(companyId);
    return this.apiClient.getArr(CompanyLocation, url);
  }

  // company integration
  public getCompanyIntegrations(companyId: number): Observable<CompanyIntegration[]> {
    const url = Endpoints.getCompanyIntegrations(companyId);
    return this.apiClient.getArr(CompanyIntegration, url);
  }

  public createCompanyIntegration(companyId: number, companyIntegration: CompanyIntegration): Observable<CompanyIntegration> {
    const url = Endpoints.createCompanyIntegration(companyId);
    return this.apiClient.postObj(CompanyIntegration, url, companyIntegration);
  }

  public updateCompanyIntegration(companyId: number, companyIntegration: CompanyIntegration): Observable<CompanyIntegration> {
    const url = Endpoints.updateCompanyIntegration(companyId, companyIntegration.id);
    return this.apiClient.putObj(CompanyIntegration, url, companyIntegration);
  }

  public deleteCompanyIntegration(companyId: number, locationIntegrationId: number): Observable<string> {
    const url = Endpoints.deleteCompanyIntegration(companyId, locationIntegrationId);
    return this.apiClient.deleteStr(url, null);
  }

  // location integration
  public getCompanyLocationIntegrations(companyId: number, locationId: number): Observable<LocationIntegration[]> {
    const url = Endpoints.getCompanyLocationIntegrations(companyId, locationId);
    return this.apiClient.getArr(LocationIntegration, url);
  }

  public createCompanyLocationIntegration(companyId: number, locationId: number, locationIntegration: LocationIntegration): Observable<LocationIntegration> {
    delete locationIntegration.updatedDate;
    delete locationIntegration.createdDate;
    const url = Endpoints.createCompanyLocationIntegration(companyId, locationId);
    return this.apiClient.postObj(LocationIntegration, url, locationIntegration);
  }

  public updateCompanyLocationIntegration(companyId: number, locationId: number, locationIntegration: LocationIntegration): Observable<LocationIntegration> {
    const url = Endpoints.updateCompanyLocationIntegration(companyId, locationId, locationIntegration.id);
    return this.apiClient.putObj(LocationIntegration, url, locationIntegration);
  }

  public deleteCompanyLocationIntegration(companyId: number, locationId: number, locationIntegrationId: number): Observable<string> {
    const url = Endpoints.deleteCompanyLocationIntegration(companyId, locationId, locationIntegrationId);
    return this.apiClient.deleteStr(url, null);
  }

  // pickup times
  public getCompanyLocationPickupTimes(companyId: number, locationId: number): Observable<PickupTime[]> {
    const url = Endpoints.getCompanyLocationPickupTimes(companyId, locationId);
    return this.apiClient.getArr(PickupTime, url);
  }

  public createCompanyLocationPickupTime(companyId: number, locationId: number, pickupTime: PickupTime): Observable<PickupTime> {
    delete pickupTime.updatedDate;
    delete pickupTime.createdDate;
    pickupTime.cutOffTimeInMinutes = pickupTime.cutOffTimeInMinutes ?? 0;
    const url = Endpoints.createCompanyLocationPickupTime(companyId, locationId);
    return this.apiClient.postObj(PickupTime, url, pickupTime);
  }

  public updateCompanyLocationPickupTime(companyId: number, locationId: number, pickupTime: PickupTime): Observable<PickupTime> {
    pickupTime.cutOffTimeInMinutes = pickupTime.cutOffTimeInMinutes ?? 0;
    const url = Endpoints.updateCompanyLocationPickupTime(companyId, locationId, pickupTime.id);
    return this.apiClient.putObj(PickupTime, url, pickupTime);
  }

  public deleteCompanyLocationPickupTime(companyId: number, locationId: number, pickupTimeId: number): Observable<string> {
    const url = Endpoints.deleteCompanyLocationPickupTime(companyId, locationId, pickupTimeId);
    return this.apiClient.deleteStr(url, null);
  }

  // Delivery Zones
  public getCompanyLocationDeliveryZones(companyId: number, locationId: number): Observable<DeliveryZone[]> {
    const url = Endpoints.getCompanyLocationDeliveryZones(companyId, locationId);
    return this.apiClient.getArr(DeliveryZone, url);
  }

  public createCompanyLocationDeliveryZone(companyId: number, locationId: number, deliveryZone: DeliveryZone): Observable<DeliveryZone> {
    const requestBody = DeserializeHelper.deserializeToInstance(DeliveryZone, deliveryZone);
    delete requestBody.updatedDate;
    delete requestBody.createdDate;
    delete requestBody.rates;
    const url = Endpoints.createCompanyLocationDeliveryZone(companyId, locationId);
    return this.apiClient.postObj(DeliveryZone, url, requestBody);
  }

  public updateCompanyLocationDeliveryZone(companyId: number, locationId: number, deliveryZone: DeliveryZone): Observable<DeliveryZone> {
    const url = Endpoints.updateCompanyLocationDeliveryZone(companyId, locationId, deliveryZone.id);
    const requestBody = DeserializeHelper.deserializeToInstance(DeliveryZone, deliveryZone);
    delete requestBody.rates;
    return this.apiClient.putObj(DeliveryZone, url, deliveryZone);
  }

  public getCompanyLocationDeliveryZoneRates(companyId: number, locationId: number, deliveryZoneId: string): Observable<DeliveryZoneRate[]> {
    const url = Endpoints.getCompanyLocationDeliveryZoneRates(companyId, locationId, deliveryZoneId);
    return this.apiClient.getArr(DeliveryZoneRate, url);
  }

  public createCompanyLocationDeliveryZoneRate(companyId: number, locationId: number, deliveryZoneId: string, deliveryZoneRate: DeliveryZoneRate): Observable<DeliveryZoneRate> {
    delete deliveryZoneRate.updatedDate;
    delete deliveryZoneRate.createdDate;
    const url = Endpoints.createCompanyLocationDeliveryZoneRate(companyId, locationId, deliveryZoneId);
    return this.apiClient.postObj(DeliveryZoneRate, url, deliveryZoneRate);
  }

  public updateCompanyLocationDeliveryZoneRate(companyId: number, locationId: number, deliveryZoneId: string, deliveryZoneRate: DeliveryZoneRate): Observable<DeliveryZoneRate> {
    const url = Endpoints.updateCompanyLocationDeliveryZoneRate(companyId, locationId, deliveryZoneId, deliveryZoneRate.id);
    return this.apiClient.putObj(DeliveryZoneRate, url, deliveryZoneRate);
  }

  public deleteCompanyLocationDeliveryZoneRate(companyId: number, locationId: number, deliveryZoneId: string, deliveryZoneRateId: string): Observable<string> {
    const url = Endpoints.deleteCompanyLocationDeliveryZoneRate(companyId, locationId, deliveryZoneId, deliveryZoneRateId);
    return this.apiClient.deleteStr(url, null);
  }

  public getCompanyLocation(companyId: number, locationId: number): Observable<CompanyLocation> {
    const url = Endpoints.getCompanyLocation(companyId, locationId);
    return this.apiClient.getObj(CompanyLocation, url);
  }

  public updateCompanyLocation(location: CompanyLocation): Observable<CompanyLocation> {
    const url = Endpoints.updateCompanyLocation(location.companyId, location.id);
    return this.apiClient.putObj(CompanyLocation, url, location);
  }

  public createCompanyLocation(location: CompanyLocation, companyId: number): Observable<CompanyLocation> {
    const url = Endpoints.createCompanyLocation(companyId);
    return this.apiClient.postObj(CompanyLocation, url, location);
  }

  public getCompany(companyId: number): Observable<Company> {
    const url = Endpoints.getCompany(companyId);
    return this.apiClient.getObj(Company, url);
  }

  public getCompanyLogos(companyId: number, isPublic: boolean): Observable<Asset[]> {
    const url = Endpoints.getCompanyLogos(companyId);
    if (isPublic) {
      const additionalHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'S2D-Api-Key': `${environment.publicAPI}`
      });
      return this.apiClient.getArr(Asset, url,additionalHeaders);
    }
    else { return this.apiClient.getArr(Asset, url); }
  }

  public deleteCompanyLogo(companyLogoId: string): Observable<string> {
    const url = Endpoints.deleteCompanyLogo(companyLogoId);
    return this.apiClient.deleteStr(url, null);
  }


  public createCompany(company: Company): Observable<Company> {
    const url = Endpoints.createCompany();
    return this.apiClient.postObj(Company, url, company);
  }

  public updateCompany(company: Company): Observable<Company> {
    const url = Endpoints.updateCompany(company.id);
    return this.apiClient.putObj(Company, url, company);
  }

    public getCompanyPreviews(): Observable<CompanyPreview[]> {
        const url = Endpoints.getCompanyPreviews();
        return this.apiClient.getArr(CompanyPreview, url);
    }


  // Extra Charges
    public createExtraCharge(charge: ExtraCharge, companyId: number): Observable<ExtraCharge> {
      const url = Endpoints.createExtraCharge(companyId);
      return this.apiClient.postObj(ExtraCharge, url, charge);
    }

    public getExtraChargesForCompany(companyId: number): Observable<ExtraCharge[]> {
      const url = Endpoints.getExtraChargesForCompany(companyId);
      return this.apiClient.getArr(ExtraCharge, url);
    }

    public updateExtraCharge(charge: ExtraCharge): Observable<ExtraCharge> {
        const url = Endpoints.updateExtraCharge(charge.companyId, charge.id);
        return this.apiClient.putObj(ExtraCharge, url, charge);
    }

    public deleteExtraCharge(charge: ExtraCharge): Observable<string> {
      const url = Endpoints.deleteExtraCharge(charge.companyId, charge.id);
      return this.apiClient.deleteStr(url, null);
    }

    //Report
    public getClients():  Observable<ApiResponse<any>>{
      const url = Endpoints.getClients();
      return this.apiClient.getObj(ApiResponse, url);
    }

    public getclientlocations(companyId:any): Observable<ApiResponse<any>>{
      const url = Endpoints.getclientlocations(companyId);
      return this.apiClient.getObj(ApiResponse, url);
    }

    public searchEmailLog(request:EmailLogRequest){
      const url = Endpoints.searchEmailLog(request);
      return this.apiClient.postArr(CompanyEmailLogs, url,request);
    }

    public searchUnsubscribedEmails(request:UnsubscribeEmailRequest){
      const url = Endpoints.searchUnsubscribedEmails(request);
      return this.apiClient.postArr(CompanyUnsubscribedEmail, url,request);
    }

    public searchUnsubscribedSMS(request:UnsubscribeSMSRequest){
      const url = Endpoints.searchUnsubscribedSMS(request);
      return this.apiClient.postArr(CompanyUnsubscribedSMS, url,request);
    }

    public searchSMSLog(request:SMSLogRequest){
      const url = Endpoints.searchSMSLog(request);
      return this.apiClient.postArr(CompanyEmailLogs, url,request);
    }
}
