import {
  Directive,
  ElementRef,
  Inject,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  Optional,
  Self,
  SimpleChanges
} from '@angular/core';
import {FormGroupDirective, NgControl, NgForm, Validators} from '@angular/forms';
import {Subject} from "rxjs";
import {StdFormControlProviders} from "../../std-form-control-providers";
import {BooleanInput, coerceBooleanProperty} from '@angular/cdk/coercion';
import {MAT_INPUT_VALUE_ACCESSOR} from "@angular/material/input";
import {ErrorStateMatcher, mixinErrorState} from "@angular/material/core";
import {Platform} from "@angular/cdk/platform";
import {AutofillMonitor} from "@angular/cdk/text-field";
import {MatFormFieldControl} from "@angular/material/form-field";

const _StdInputBase = mixinErrorState(
    class {
      /**
       * Emits whenever the component state changes and should cause the parent
       * form field to update. Implemented as part of `MatFormFieldControl`.
       * @docs-private
       */
      readonly stateChanges = new Subject<void>();

      constructor(
          public _defaultErrorStateMatcher: ErrorStateMatcher,
          public _parentForm: NgForm,
          public _parentFormGroup: FormGroupDirective,
          /**
           * Form control bound to the component.
           * Implemented as part of `MatFormFieldControl`.
           * @docs-private
           */
          public ngControl: NgControl,
      ) {
      }
    },
);

@Directive({
  selector: '[stdInput]',
  providers: [StdFormControlProviders.formField(FormInputDirective)],
  host: {
    // Native input properties that are overwritten by Angular inputs need to be synced with
    // the native input element. Otherwise property bindings for those don't work.
    '[id]': 'id',
    '[disabled]': 'disabled',
    '[required]': 'required',
    '[attr.name]': 'name || null',
    '[attr.readonly]': 'readonly && !_isNativeSelect || null',
    '[attr.autocomplete]': 'browserAutoComplete ? "on" : "off"',

    '(focus)': '_focusChanged(true)',
    '(blur)': '_focusChanged(false)',
  }
})
export class FormInputDirective extends _StdInputBase implements MatFormFieldControl<any>, OnChanges, OnDestroy {
  constructor(protected _elementRef: ElementRef<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
              protected _platform: Platform,
              @Optional() @Self() public ngControl: NgControl,
              @Optional() public _parentForm: NgForm,
              @Optional() public _parentFormGroup: FormGroupDirective,
              public _defaultErrorStateMatcher: ErrorStateMatcher,
              @Optional() @Self() @Inject(MAT_INPUT_VALUE_ACCESSOR) inputValueAccessor: any,
              private _autofillMonitor: AutofillMonitor,
              public  ngZone: NgZone,
  ) {
    super(_defaultErrorStateMatcher, _parentForm, _parentFormGroup, ngControl);
    const element = (_elementRef.nativeElement as HTMLElement);
    element.classList.remove('form-control');
    element.classList.remove('custom-select');
    element.classList.remove('custom-textfield');
    element.classList.remove('std-form-control');
    if (!element.getAttribute('disabled')) {
      element.setAttribute('disabled', null);
    }

    // If no input value accessor was explicitly specified, use the element as the input value
    // accessor.
    this._inputValueAccessor = inputValueAccessor || element;

  }


  @Input() browserAutoComplete: boolean = false;

  _required: boolean;
  @Input()
  get required(): boolean {
    return this._required ?? this.ngControl?.control?.hasValidator(Validators.required) ?? false;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }
  @Input()
  get value(): string {
    return this._inputValueAccessor.value;
  }
  set value(value: any) {
    if (value !== this.value) {
      this._inputValueAccessor.value = value;
      this.stateChanges.next();
    }
  }
  private _inputValueAccessor: {value: any};
  @Input() name: string;
  /** Whether the element is readonly. */
  @Input()
  get readonly(): boolean {
    return this._readonly;
  }
  set readonly(value: BooleanInput) {
    this._readonly = coerceBooleanProperty(value);
  }
  private _readonly = false;

  readonly _isNativeSelect: boolean;
  readonly shouldLabelFloat: boolean;
  readonly userAriaDescribedBy: string;
  readonly stateChanges = new Subject<void>();
  autofilled: boolean;
  controlType: string = 'form-input';
  empty: boolean;
  errorState: boolean;
  focused: boolean;
  id: string;
  readonly placeholder: string;
  protected _disabled = false;
  @Input()
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);

    // Browsers may not fire the blur event if the input is disabled too quickly.
    // Reset from here to ensure that the element doesn't become stuck.
    if (this.focused) {
      this.focused = false;
      this.stateChanges.next();
    }
  }
  get disabled(): boolean {
    return this._disabled || this?.ngControl.disabled;
  }


  onContainerClick(event: MouseEvent): void {
    if (!this.focused) {
      this.focus();
    }
  }

  focus(options?: FocusOptions): void {
    this._elementRef.nativeElement.focus(options);
  }

  setDescribedByIds(ids: string[]): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.stateChanges.next();
  }

  ngOnDestroy(): void {
    this.stateChanges.complete();
  }

  _focusChanged(isFocused: boolean) {
    if (isFocused !== this.focused) {
      this.focused = isFocused;
      this.stateChanges.next();
    }
  }

}
