<app-loading
        *ngIf="loadingOpts?.isLoading"
  [options]="loadingOpts">
</app-loading>

<div class="app-body mt-0 mb-0">
  <!-- Nav Bar -->
  <app-sidebar
    [fixed]="true"
    [hidden]="sidebarMinimized"
    [ngClass]="{ 'sidebar-show': !sidebarMinimized,
               'sidebar-hide': sidebarMinimized}"
    class="sidebar">
    <app-sidebar-navigation>
    </app-sidebar-navigation>
  </app-sidebar>
  <!-- Main content -->
    <main class="main">
      <main class="main-content position-relative" #main>
        <app-loading
                *ngIf="isLoading"
                [options]="loadingOpts">
        </app-loading>
        <router-outlet></router-outlet>
      </main>
    </main>
  <app-aside [display]="false" [fixed]="true">
  </app-aside>
</div>
