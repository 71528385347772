import { Component } from '@angular/core';
import {BaseIconComponent} from '../base-icon-component/base-icon-component';

@Component({
  selector: 's2d-icon-logout-solid',
  templateUrl: `./logout-solid.html`,
  styleUrls: ['./logout-solid.scss'],
})
export class LogoutSolidIconComponent extends BaseIconComponent {
}
