import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class StoredataService {
  public EstimateByRatesSubject = new Subject<string>();
  constructor() { }
  saveEstimatedByRates(data){
    this.EstimateByRatesSubject.next(data);
  }
}
