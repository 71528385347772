import {Injectable} from '@angular/core';
import {Cachable} from '../models/protocols/cachable';
import {DeserializeHelper} from '../models/protocols/deserializable';
import {Asset} from '../models/image/dto/asset';
import {DateUtils} from '../utils/date-utils';
import {DomSanitizer} from '@angular/platform-browser';
import {StringifyUtils} from '../utils/stringify-utils';
import {BlobUtils} from '../utils/blob-utils';
import {GenericCacheItem} from '../models/shared/generic-cache-item';
import {AssetUrl} from '../models/image/dto/asset-url';
import {MediaType} from '../models/enum/dto/media-type.enum';
import {SessionContainer} from '../models/shared/session-container';
import {DefaultCacheKey} from '../models/enum/shared/default-cache-key.enum';
import {ImageAPI} from '../api/image-api';
import {CustomError} from '../models/shared/custom-error';
import {CachePolicy} from '../models/enum/shared/cachable-image-policy.enum';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  private persistent = localStorage;
  private session = sessionStorage;
  private service = new Map<string, string>();

  constructor(
    private sanitizer: DomSanitizer,
    private imageAPI: ImageAPI,
  ) {
  }

  isPersistentEnabled(): boolean {
    return this.persistent === localStorage;
  }

  clearSessionCache() {
    this.session.clear();
  }

  clearPersistentCache() {
    this.persistent.clear();
  }

  // Getters

  public getCachedGeneric<T extends string | number>(key: string, persistentCache: boolean = false): T {
    const cachePolicy = persistentCache ? CachePolicy.Persistent : CachePolicy.Session;
    const result = this.getCacheItemString(key, cachePolicy);
    if (result) {
      const resp = DeserializeHelper.deserializeToInstance(GenericCacheItem, JSON.parse(result));
      if (resp.isExpired()) {
        // Expired, clear cache
        const imagePolicy = persistentCache ? CachePolicy.Persistent : CachePolicy.Session;
        this.removeCachedObject(key, imagePolicy);
        return null;
      } else {
        return resp.item as T;
      }
    } else {
      return null;
    }
  }

  public getCachedObject<T extends Cachable>(respObjectType: new () => T, key: string, persistentCache: boolean = false): T {
    const cachePolicy = persistentCache ? CachePolicy.Persistent : CachePolicy.Session;
    const result = this.getCacheItemString(key, cachePolicy);
    if (result) {
      const resp = DeserializeHelper.deserializeToInstance(respObjectType, JSON.parse(result));
      const imagePolicy = resp.imageCachePolicy() || CachePolicy.Session;
      // Pull any images from image cache
      if (resp.isExpired()) {
        // Expired, clear cache
        const imagesToCache = this.parseImagesFromObject(resp);
        imagesToCache?.forEach((i) => {
          this.removeCachedImage(i, imagePolicy);
        });
        this.removeCachedObject(key);
        return null;
      } else {
        this.getCachedObjectImages(resp, imagePolicy);
        return resp;
      }
    } else {
      return null;
    }
  }

  public getCachedArray<T extends Cachable>(respObjectType: new () => T, key: string, persistentCache: boolean = false): T[] {
    const cachePolicy = persistentCache ? CachePolicy.Persistent : CachePolicy.Session;
    const result = this.getCacheItemString(key, cachePolicy);
    if (result) {
      const resp = DeserializeHelper.deserializeArray(respObjectType, JSON.parse(result));
      const imagePolicy = (resp.length > 0 ? resp[0].imageCachePolicy() : CachePolicy.Session) || CachePolicy.Session;
      // Pull any images from image cache
      resp.map(r => this.getCachedObjectImages(r, imagePolicy));
      const containsExpiredObject = resp.filter(o => o.isExpired()).length > 0;
      if (containsExpiredObject) {
        // Expired, clear cache
        this.removeCachedObject(key, imagePolicy);
        return null;
      } else {
        return resp;
      }
    } else {
      return null;
    }
  }


  public cacheGeneric<T extends string | number>(key: string, object: T, persistentCache: boolean = false) {
    const gc = new GenericCacheItem();
    gc.key = key;
    gc.item = object;
    this.setCacheItem(key, gc, persistentCache);
  }

  public cacheWithJson<T extends any>(key: string, object: T, persistentCache: boolean = false) {
    this.cacheGeneric(key, JSON.stringify(object), persistentCache);
  }

  public getCachedJson<T extends any>(key: string, persistentCache: boolean = false): T {
    return JSON.parse(this.getCachedGeneric(key, persistentCache));
  }

  public cacheObject<T extends Cachable>(key: string, object: T, persistentCache: boolean = false) {
    const cachePolicy = object.imageCachePolicy() || CachePolicy.Session;
    this.setCacheObjectImages(object, cachePolicy);
    this.setCacheItem(key, object, persistentCache);
  }

  public removeCachedImage(img: Asset, cachePolicy: CachePolicy = CachePolicy.Session) {
    if (img && img.links) {
      img.links.forEach((u) => {
        const key = Asset.buildCacheKey(img.id, u.size);
        this.removeCachedObject(key, cachePolicy);
        u.srcUrl.next(null);
      });
    }
  }

  public cacheArray<T extends Cachable >(key: string, objects: T[], persistentCache: boolean = false) {
    const cachePolicy = (objects.length > 0 ? objects[0].imageCachePolicy() : CachePolicy.Session) || CachePolicy.Session;
    objects.map(o => this.setCacheObjectImages(o, cachePolicy));
    this.setCacheItems(key, objects, persistentCache);
  }

  public removeCachedObject(key: string, cachePolicy: CachePolicy = CachePolicy.Session) {
    switch (cachePolicy) {
      case CachePolicy.Persistent:
        this.persistent.removeItem(key);
        break;
      case CachePolicy.Session:
        this.session.removeItem(key);
        break;
      case CachePolicy.Service:
        this.service.delete(key);
    }
  }

  // Setters

  private getCachedImage(img: Asset, cachePolicy: CachePolicy = CachePolicy.Session) {
    let getCacheSuccess = true;
    img.links.forEach((u) => {
      const key = Asset.buildCacheKey(img.id, u.size);
      if (img.isExpired()) {
        this.removeCachedObject(key, cachePolicy);
        u.srcUrl.next(null);
        getCacheSuccess = false;
      } else {
        const success = this.getCachedImageUrl(u, key, img.type, cachePolicy);
        if (!success) {
          getCacheSuccess = false;
        }
      }
    });
    if (!getCacheSuccess && cachePolicy === CachePolicy.Service) {
      // If CachePolicy is Service, we should try to refresh the image
      this.refreshImage(img, cachePolicy);
    }
  }

  private getCachedImageUrl(u: AssetUrl, key: string, mediaType: MediaType, cachePolicy: CachePolicy = CachePolicy.Session): boolean {
    const blobString = this.getCacheItemString(key, cachePolicy);
    const blob = BlobUtils.b64toBlob(blobString, mediaType);
    if (blob) {
      u.srcUrl.next(this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob)));
      return true;
    } else {
      this.removeCachedObject(key, cachePolicy);
      u.srcUrl.next(null);
      return false;
    }
  }

  private refreshImage(img: Asset, cachePolicy: CachePolicy = CachePolicy.Session) {
    // Fetch the image from the API and re-cache
    this.imageAPI.getAsset(img.id, img.id).subscribe((refreshedImage) => {
      // Pull presigned urls from refreshed image and assign on existing image, so ReplaySubject bindings remain
      img.links.map(u => {
        const newUrl = refreshedImage.links.find(ru => ru.size === u.size);
        if (newUrl) {
          u.presignedUrl = newUrl.presignedUrl;
        }
      });
      this.setCacheImage(img, cachePolicy);
    }, (error: CustomError) => {
      console.log(`Unable to refresh image with id: ${img.id}. Error: ${error.message}`);
    });
  }

  private getCachedObjectImages<T>(object: T, cachePolicy: CachePolicy = CachePolicy.Session) {
    // iterate over object and recursively get images from objects
    const imagesToCache = this.parseImagesFromObject(object);
    // Cache all image sizes
    imagesToCache.forEach((img) => {
      this.getCachedImage(img, cachePolicy);
    });
  }

  private setCacheImage(img: Asset, cachePolicy: CachePolicy = CachePolicy.Session) {
    let refreshImage = false;
    img.links.forEach((u) => {
      const key = Asset.buildCacheKey(img.id, u.size);
      if (u.presignedUrl) {
        const urlCopy = String(u.presignedUrl);
        u.presignedUrl = '';
        this.downloadBlob(u, urlCopy, key, cachePolicy);
      } else {
        const success = this.getCachedImageUrl(u, key, img.type, cachePolicy);
        if (!success) {
          refreshImage = true;
        }
      }
    });
    if (refreshImage) {
      this.refreshImage(img, cachePolicy);
    }
  }

  private downloadBlob(u: AssetUrl, urlCopy, key: string, cachePolicy: CachePolicy = CachePolicy.Session) {
    this.imageAPI.getBlobFromUrl(urlCopy).subscribe((blob: Blob) => {
      // Cache the blob
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function() {
        const base64data = reader.result;
        switch (cachePolicy) {
          case CachePolicy.Persistent:
            this.persistent.setItem(key, base64data);
            break;
          case CachePolicy.Session:
            this.session.setItem(key, base64data);
            break;
          case CachePolicy.Service:
            this.service.set(key, base64data);
            break;
        }
      }.bind(this);
      // Pass the Blob Url to the srcUrl
      u.presignedUrl = '';
      u.srcUrl.next(this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob)));
    }, error => {
      console.log(`Unable to get image from url: ${error.toString()}`);
    });
  }

  private setCacheObjectImages<T>(object: T, cachePolicy: CachePolicy = CachePolicy.Session) {
    // iterate over object and recursively get images from objects
    const imagesToCache = this.parseImagesFromObject(object);
    // Cache all image sizes
    imagesToCache.forEach((img) => {
      img.cachedTime = DateUtils.currentTimestamp();
      this.setCacheImage(img, cachePolicy);
    });
  }

  // Methods to read / write to cache

  private setCacheItem<T extends Cachable>(key: string, object: T, persistentCache: boolean = false) {
    object.cachedTime = DateUtils.currentTimestamp();
    const objString = JSON.stringify(object, StringifyUtils.replacer);
    try {
      if (persistentCache) {
        this.persistent.setItem(key, objString);
      } else {
        this.session.setItem(key, objString);
      }
    } catch (e) {
      this.clearFullCache(persistentCache);
    }
  }

  private setCacheItems<T extends Cachable>(key: string, object: T[], persistentCache: boolean = false) {
    const currTime = DateUtils.currentTimestamp();
    object.map(o => o.cachedTime = currTime);
    try {
      if (persistentCache) {
        this.persistent.setItem(key, JSON.stringify(object, StringifyUtils.replacer));
      } else {
        this.session.setItem(key, JSON.stringify(object, StringifyUtils.replacer));
      }
    } catch (e) {
      this.clearFullCache(persistentCache);
    }
  }

  private getCacheItemString(key: string, cachePolicy: CachePolicy = CachePolicy.Session): string {
    let result: string;
    switch (cachePolicy) {
      case CachePolicy.Persistent:
        result = this.persistent.getItem(key);
        break;
      case CachePolicy.Session:
        result = this.session.getItem(key);
        break;
      case CachePolicy.Service:
        result = this.service.get(key);
        break;
    }
    return result;
  }

  //  Helpers

  private parseImagesFromObject<T>(object: T, imgs: Asset[] = []): Asset[] {
    const keys = Object.keys(object);
    keys.forEach((k) => {
      if (object[k] instanceof Asset) {
        if ((object[k] as Asset).id) {
          imgs.push(object[k]);
        }
      } else if (object[k] instanceof Array && (object[k] as Array<Object>).length > 0) {
        if (object[k][0] instanceof Asset) {
          imgs.push(...object[k]);
        } else {
          (object[k] as Array<Object>).forEach((obj) => {
            imgs = this.parseImagesFromObject(obj, imgs);
          });
        }
      } else if (object[k] instanceof Object) {
        imgs = this.parseImagesFromObject(object[k], imgs);
      }
    });
    return imgs;
  }

  private clearFullCache(persistentCache: boolean) {
    console.log(`${persistentCache ? 'Persistent cache' : 'Session cache'} is full. Performing clear cache.`);
    // Get cached session contain, as it should always be cached
    const sessKey = DefaultCacheKey.SessionContainer;
    const cachedSessContainer = this.getCachedObject<SessionContainer>(SessionContainer, sessKey, persistentCache);
    if (persistentCache) {
      this.clearPersistentCache();
    } else {
      this.clearSessionCache();
    }
    this.setCacheItem(sessKey, cachedSessContainer, persistentCache);
  }

}
