import {Viewable} from '../protocols/viewable';
import {Subscribable} from './subscribable';
import {TypeUtils} from '../../utils/type-utils';
import {AfterViewInit, Component, OnInit} from '@angular/core';

@Component({
  template: ''
})
export abstract class BaseComponent extends Subscribable implements OnInit, AfterViewInit, Viewable {
  Types = TypeUtils;
  abstract setupViews();
  abstract setupBindings();
  ngOnInit(): void {
    this.setupViews();
  }
  ngAfterViewInit(): void {
    this.setupBindings();
  }

  scrollElementWithIdIntoView(elementId: string, position: ScrollLogicalPosition = 'nearest') {
    setTimeout(() => {
      document.getElementById(elementId)?.scrollIntoView({behavior: 'smooth', block: position, inline: position});
    });
  }
}
