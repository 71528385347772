import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  Input,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { StdFormFieldControl } from '../inputs/std-form-field-control';
import { LabelComponent } from '../../std-text/label/label.component';
import { AbstractControlDirective } from '@angular/forms';
import { merge } from 'rxjs';
import {
  STD_SUFFIX,
  StdSuffix,
} from '../../layout/suffix/std-suffix.directive';
import {
  STD_PREFIX,
  StdPrefix,
} from '../../layout/prefix/std-prefix.directive';

@Component({
  selector: 'std-form-field',
  templateUrl: './std-form-field.component.html',
  styleUrls: ['./std-form-field.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    // Note that these classes reuse the same names as the non-MDC version, because they can be
    // considered a public API since custom form controls may use them to style themselves.
    // See https://github.com/angular/components/pull/20502#discussion_r486124901.
    '[class.std-form-field-invalid]': '_control.errorState',
    '[class.std-form-field-disabled]': '_control.disabled',
    '[class.std-form-field-autofilled]': '_control.autofilled',
    '[class.std-form-field-focused]': '_control.focused',
    '[class.ng-untouched]': '_shouldForward("untouched")',
    '[class.ng-touched]': '_shouldForward("touched")',
    '[class.ng-pristine]': '_shouldForward("pristine")',
    '[class.ng-dirty]': '_shouldForward("dirty")',
    '[class.ng-valid]': '_shouldForward("valid")',
    '[class.ng-invalid]': '_shouldForward("invalid")',
    '[class.ng-pending]': '_shouldForward("pending")',
  },
})
// tslint:disable-next-line:component-class-suffix
export class StdFormField implements AfterContentInit {
  constructor(
    private _elementRef: ElementRef,
    private _changeDetectorRef: ChangeDetectorRef
  ) {}

  @ContentChildren(STD_PREFIX, { descendants: true })
  _prefixChildren: QueryList<StdPrefix>;
  @ContentChildren(STD_SUFFIX, { descendants: true })
  _suffixChildren: QueryList<StdSuffix>;

  @Input() hint: string;
  private _explicitFormFieldControl: StdFormFieldControl<any>;
  @ContentChild(StdFormFieldControl)
  public _formFieldControl: StdFormFieldControl<any>;
  @ContentChild(LabelComponent) public label;
  @Input() hideRequiredMarker: boolean;
  @Input() errorMessages: Map<string, string> = new Map<string, string>();
  @Input() placeholder: string;
  public _isFocused: boolean;
  public _hasIconSuffix = false;
  public _hasButtonSuffix = false;
  public _hasButtonPrefix = false;
  public _hasIconPrefix = false;

  get hasSuffix() {
    return this._hasButtonSuffix || this._hasIconSuffix;
  }

  get hasPrefix() {
    return this._hasIconPrefix || this._hasButtonPrefix;
  }

  get _control(): StdFormFieldControl<any> {
    return this._explicitFormFieldControl || this._formFieldControl;
  }

  set _control(value) {
    this._explicitFormFieldControl = value;
  }

  get required() {
    return this._control?.required;
  }

  get focused() {
    return this._control.focused;
  }

  get showPlaceholder(): boolean {
    if (this._elementRef.nativeElement.placeholder) {
      return false;
    }
    return !this._control.value || !this._control.ngControl?.value;
  }

  private _checkPrefixAndSuffixTypes() {
    this._hasIconPrefix = !!this._prefixChildren.find((p) => !p._isButton);
    this._hasButtonPrefix = !!this._prefixChildren.find((p) => p._isButton);
    this._hasIconSuffix = !!this._suffixChildren.find((s) => !s._isButton);
    this._hasButtonSuffix = !!this._suffixChildren.find((s) => s._isButton);
  }

  private _initializePrefixAndSuffix() {
    this._checkPrefixAndSuffixTypes();
    // Mark the form field as dirty whenever the prefix or suffix children change. This
    // is necessary because we conditionally display the prefix/suffix containers based
    // on whether there is projected content.
    merge(this._prefixChildren.changes, this._suffixChildren.changes).subscribe(
      () => {
        this._checkPrefixAndSuffixTypes();
        this._changeDetectorRef.markForCheck();
      }
    );
  }

  ngAfterContentInit(): void {
    this._initializeControl();
    this._initializePrefixAndSuffix();
  }

  private _initializeControl() {
    const control = this._control;
    if (control?.controlType) {
      this._elementRef.nativeElement.classList.add(
        `std-form-field-type-${control.controlType}`
      );
    }
    this._control?.stateChanges.subscribe(() => {
      this._updateFocusState();
    });
  }

  _shouldForward(prop: keyof AbstractControlDirective): boolean {
    const control = this._control ? this._control.ngControl : null;
    return control && control[prop];
  }

  private _updateFocusState() {
    // Usually the MDC foundation would call "activateFocus" and "deactivateFocus" whenever
    // certain DOM events are emitted. This is not possible in our implementation of the
    // form field because we support abstract form field controls which are not necessarily
    // of type input, nor do we have a reference to a native form field control element. Instead
    // we handle the focus by checking if the abstract form field control focused state changes.
    if (this._control.focused && !this._isFocused) {
      this._isFocused = true;
    } else if (
      !this._control.focused &&
      (this._isFocused || this._isFocused === null)
    ) {
      this._isFocused = false;
    }
  }

  getErrorMessage() {
    const errors = this._control.ngControl?.errors;
    if (!errors) {
      return null;
    }
    const keys = Object.keys(errors);
    const requireKey = keys.find((k) => k === 'required');
    if (requireKey) {
      return this.getErrorMessageFromKey(requireKey, errors[requireKey]);
    }
    const key = keys[0];
    return this.getErrorMessageFromKey(key, errors[key]);
  }

  getErrorMessageFromKey(keyError: string, error) {
    switch (keyError) {
      case 'required':
        return $localize`Required.`;
      case 'email':
        return (
          this.errorMessages.get(keyError) ??
          $localize`Must be a valid email address.`
        );
      case 'searchFor':
        return (
          this.errorMessages.get(keyError) ??
          $localize`Search list must contain selection.`
        );
      case 'phoneNumber':
        return (
          this.errorMessages.get(keyError) ?? $localize`Invalid phone number.`
        );
      case 'minlength':
        const minLength = error.requiredLength;
        return (
          this.errorMessages.get(keyError) ??
          $localize`Must be more than ${minLength} characters.`
        );
      case 'maxlength':
        const maxLength = error.requiredLength;
        return (
          this.errorMessages.get(keyError) ??
          $localize`Must be less than ${maxLength} characters.`
        );
      case 'min':
        const minimum = error.min;
        return this.errorMessages.get(keyError) ?? minimum === 0.01
          ? $localize`Must be greater than 0.`
          : $localize`Must be greater than or equal to ${minimum}.`;
      case 'max':
        const maximum = error.max;
        return (
          this.errorMessages.get(keyError) ??
          $localize`Must be less than ${maximum}.`
        );
      case 'matDatepickerParse':
        const matDatepickerParse = error.matDatepickerParse;
        return this.errorMessages.get(keyError) ?? $localize`Invalid Date`;
      default:
        return this.errorMessages.get(keyError) ?? error;
    }
  }

  showError(): boolean {
    return (
      (this._control?.ngControl?.dirty || this._control?.ngControl?.touched) &&
      !!this.hasErrors
    );
  }

  get hasErrors() {
    return !!this._control.ngControl?.errors;
  }

  showMarker() {
    return this.required && !this.hideRequiredMarker;
  }

  getLabelId() {}
}
