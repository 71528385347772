import {BaseModel} from './base-model';
import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {FormListable} from '../../protocols/form-listable';

export class DeliveryZone extends BaseModel implements Deserializable, FormListable {
  public id: string;
  public name: string = '';
  public postalCodes: string = null;
  public rushDeliveryRate: number = null;
  public rushDriverRate: number = null;
  public enabled: boolean = true;
  public overridePostalCodes: boolean = false;
  public rates: DeliveryZoneRate[] = [];
  public labelIdentifier: string = null;
  public driverId: number = null;
  public teamId: number = null;

  itemChanged: boolean = false;
  itemDeleted: boolean = false;
  itemCreated: boolean = false;

  public onDeserialize() {
    super.onDeserialize();
    this.postalCodes = this.postalCodes ?? null;
    this.rushDeliveryRate = this.rushDeliveryRate ?? null;
    this.rushDriverRate = this.rushDriverRate ?? null;
    this.rates = DeserializeHelper.deserializeArray(DeliveryZoneRate, this.rates);
    this.driverId = this.driverId ?? null;
    this.teamId = this.teamId ?? null;
    this.labelIdentifier = this.labelIdentifier ?? null;
  }

  getEnabledStatus(): boolean {
    return this.enabled;
  }

  getListItemSubtitle(): string {
    return this.postalCodes;
  }

  getListItemTitle(): string {
    return this.name;
  }

  getErrorState(): boolean {
    return false;
  }
}

export class DeliveryZoneRate extends BaseModel implements Deserializable {
  public id: string;
  public quantityLow: number = null;
  public quantityHigh: number = null;
  public deliveryRate: number = null;
  public driverRate: number = null;
  public oversizeDeliveryRate: number = null;
  public oversizeDriverRate: number = null;

  itemChanged: boolean = false;
  itemDeleted: boolean = false;
  itemCreated: boolean = false;

  public onDeserialize() {
    super.onDeserialize();
    this.oversizeDeliveryRate = this.oversizeDeliveryRate ?? null;
    this.oversizeDriverRate = this.oversizeDriverRate ?? null;
  }
}
