import {InjectionToken, Provider} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {StdFormFieldControl} from "./inputs/std-form-field-control";

export class StdFormControlProviders {
    public static valueAccessorProvider(component, provide: InjectionToken<ControlValueAccessor> = NG_VALUE_ACCESSOR, multi: boolean = true): Provider {
        return {
            useExisting: component,
            provide: NG_VALUE_ACCESSOR,
            multi: multi,
        }
    }
    public static formField(component): Provider {
        return {provide: StdFormFieldControl, useExisting: component};
    }
}
