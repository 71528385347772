import { Pipe, PipeTransform } from '@angular/core';
import { DaysOfWeek } from '../day-of-week';
import { DayOfWeek } from '../../../models/account/dto/pickup-time';

@Pipe({
  name: 'daysOfWeek',
})
export class DaysOfWeekPipe implements PipeTransform {
  transform(value: DaysOfWeek, ...args: unknown[]): unknown {
    return value
      ?.map((day) => DayOfWeek.getWeekDayShortName(day.dayOfWeekId))
      .join(', ');
  }
}
