import {Placement, Placements} from 'ngx-popper';
import {DateRange} from '@angular/material/datepicker';
import {EventEmitter} from '@angular/core';

export class DatatableColumnFilter {
  public headerIconSrc: string = 'assets/icons/dark/outline/filter-alt-grey.svg';
  public headerIconActiveSrc: string = 'assets/icons/dark/outline/filter-alt.svg';
  public filterType: DatatableColumnFilterType = DatatableColumnFilterType.SelectionList;
  public selectionListItems: DatatableColumnFilterListItem[];
  public selectedItem: DatatableColumnFilterListItem = null;
  public selectedDateRange = new DateRange<Date>(null, null);
  public defaultDateRange = new DateRange<Date>(null, null);
  public popperPlacement: Placement = Placements.BottomEnd;
  public appliedDateRange: DateRange<Date>;
  public isOpen: boolean = false;
  public dateRangeFilterMethod: (rowData: any, dateRange: DateRange<Date>) => boolean;
  public filterChanged = new EventEmitter<DatatableColumnFilter>();

  getIconSrc() {
    if (this.isOpen
      || (this.filterType === DatatableColumnFilterType.SelectionList && !this.selectedItem?.defaultFilterItem)
      || (this.filterType === DatatableColumnFilterType.DateRange && !!this.appliedDateRange)) {
      return this.headerIconActiveSrc;
    } else {
      return this.headerIconSrc;
    }
  }

  listItemSelected(item: DatatableColumnFilterListItem) {
    this.selectedItem = item;
    this.filterChanged.emit(this);
  }

  clearFilter() {
    this.selectedItem = this.selectionListItems?.find(i => i.defaultFilterItem);
    this.selectedDateRange = this.defaultDateRange;
    this.applyFilter();
  }

  applyFilter() {
    this.appliedDateRange = (!!this.selectedDateRange?.start && !!this.selectedDateRange?.end) ? this.selectedDateRange : null;
    this.filterChanged.emit(this);
  }

  constructor(items: DatatableColumnFilterListItem[] = null) {
    this.selectionListItems = items;
    this.selectedItem = this.selectionListItems?.find(i => i.defaultFilterItem);
  }
}

export class DatatableColumnFilterListItem {
  public displayName: string;
  public filterValue: any;
  public defaultFilterItem: boolean;

  constructor(displayName: string, filterValue: any = displayName, defaultFilterItem: boolean = false) {
    this.displayName = displayName;
    this.filterValue = filterValue;
    this.defaultFilterItem = defaultFilterItem;
  }
}

export enum DatatableColumnFilterType {
  SelectionList,
  DateRange,
}
