import {Directive, ElementRef, InjectionToken} from '@angular/core';
export const STD_PREFIX: InjectionToken<StdPrefix> = new InjectionToken<StdPrefix>('StdPrefix');

@Directive({
  selector: '[stdPrefix], [stdPrefixBtn]',
  providers: [{provide: STD_PREFIX, useExisting: StdPrefix}],
})
export class StdPrefix {

  public _isButton: boolean = false;
  constructor(elementRef: ElementRef) {
    this._isButton = elementRef.nativeElement.hasAttribute('stdPrefixBtn');
  }
}
