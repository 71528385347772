import { Component } from '@angular/core';
import {BaseIconComponent} from '../base-icon-component/base-icon-component';

@Component({
  selector: 's2d-icon-calendar-outline',
  templateUrl: `./calendar-outline.html`,
  styleUrls: ['./calendar-outline.scss'],
})
export class CalendarOutlineIconComponent extends BaseIconComponent {
}
