import {BaseComponent} from './base-component';
import {Component, HostListener} from '@angular/core';

@Component({
  template: ``
})
export abstract class BaseModal extends BaseComponent {

  @HostListener('document:keydown', ['$event'])

  onKeyDownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.cancel();
    }
  }

  abstract cancel(value?);

}
