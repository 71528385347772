import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TabBarComponent} from './components/tab-bar/tab-bar.component';
import {MaterialModule} from './material/material.module';
import {TestTabComponent} from './components/tab-bar/test-tab/test-tab.component';
import {TabContentComponent} from './components/tab-bar/tab-content/tab-content.component';
import {TabContentContainerDirective} from './components/tab-bar/tab-content/tab-content-container.directive';
import {LoadingComponent} from './components/loading/loading.component';
import {NgxSpinnerModule} from 'ngx-spinner';
import {ColorPickerModule} from 'ngx-color-picker';
import {ProgressBarComponent} from './components/progress-bar/progress-bar.component';
import {PillComponent} from './components/pills/pill/pill.component';
import {DropDownComponent} from './components/drop-down/drop-down.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormGroupComponent} from './components/form-group/form-group.component';
import {LetterOnlyValidatorDirective} from './components/form-group/validators/letter-only-validator.directive';
import {PasswordValidatorDirective} from './components/form-group/validators/password-validator.directive';
import {CheckboxContainerComponent} from './components/checkboxes/checkbox-container/checkbox-container.component';
import {CheckboxComponent} from './components/checkboxes/checkbox/checkbox.component';
import {DropDownMenuComponent} from './components/drop-down-menu/drop-down-menu.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {PhoneValidatorDirective} from './components/form-group/validators/phone-validator.directive';
import {AbandonFormComponent} from './modals/abandon-form/abandon-form.component';
import {LocationPickerComponent} from './modals/location-picker/location-picker.component';
import {CardDeckComponent} from './components/card-deck/card-deck.component';
import {CardComponent} from './components/card-deck/card/card.component';
import {
    ConfirmationCodeValidatorDirective
} from './components/form-group/validators/confirmation-code-validator.directive';
import {UploadItemComponent} from './components/upload-asset/upload-item/upload-item.component';
import {UploadAssetComponent} from './components/upload-asset/upload-asset.component';
import {DndDirective} from './directives/dnd-directive';
import {HeaderComponent} from './components/header/header.component';
import {DataTableComponent} from './components/data-table/data-table.component';
import {NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import {CardTableComponent} from './components/card-table/card-table.component';
import {BreadcrumbsComponent} from './components/breadcrumbs/breadcrumbs.component';
import {LabelGroupComponent} from './components/label-group/label-group.component';
import {ConfirmationModalComponent} from './components/confirmation-modal/confirmation-modal.component';
import {ReorderModalComponent} from './components/reorder-modal/reorder-modal.component';
import {ReorderListComponent} from './components/reorder-list/reorder-list.component';
import {ActionListComponent} from './components/action-list/action-list.component';
import {ActionComponent} from './components/action-list/action/action.component';
import {RangeSliderComponent} from './components/range-slider/range-slider.component';
import {ActiveComponent} from './components/active/active.component';
import {SingleSelectionListComponent} from './components/single-selection-list/single-selection-list.component';
import {AssetPreviewComponent} from './components/asset-preview/asset-preview.component';
import {SegmentedControlComponent} from './components/segmented-control/segmented-control.component';
import {ThemeCarouselImageComponent} from './components/card-deck/theme-carousel-image/theme-carousel-image.component';
import {NgxPopperModule} from 'ngx-popper';
import {
    DetailContentContainerComponent
} from './components/detail-content-container/detail-content-container.component';
import {DetailBackButtonComponent} from './components/detail-back-button/detail-back-button.component';
import {DetailTitleComponent} from './components/detail-title/detail-title.component';
import {UploadImageModalComponent} from './components/upload-image-modal/upload-image-modal.component';
import {ImageCropperModule} from 'ngx-image-cropper';
import {IMaskModule} from 'angular-imask';
import {PostalZipCodeValidatorDirective} from './components/form-group/validators/postal-zip-code-validator.directive';
import {CheckboxListFilterPipe} from './pipes/company-list-filter.pipe';
import {
    DetailTitleNoUnderlineComponent
} from './components/detail-title-no-underline/detail-title-no-underline.component';
import {EmailListValidatorDirective} from './components/form-group/validators/email-list-validator.directive';
import {LabelWithValueComponent} from './components/label-with-value/label-with-value.component';
import {ImageLoaderComponent} from './components/image-loader/image-loader.component';
import {PaginationComponent} from './paginatation/pagination.component';
import {PaginateNextButtonDirective} from './paginatation/paginate-next-button.directive';
import {PaginatePreviousButtonDirective} from './paginatation/paginate-previous-button.directive';
import {PaginateMiddleContentDirective} from './paginatation/paginate-middle-content.directive';
import {TabBarRoutingComponent} from './components/tab-bar/tab-bar-routing/tab-bar-routing.component';
import {RouterModule} from '@angular/router';
import {ContentContainerComponent} from './components/content-container/content-container.component';
import {QrCodeComponent} from './components/qr-code/qr-code.component';
import {QRCodeModule} from 'angularx-qrcode';
import {TabStepperComponent} from './components/tab-stepper/tab-stepper.component';
import {TabGroupComponent} from './components/tab-group/tab-group.component';
import {TabComponent} from './components/tab-group/tab/tab.component';
import {StepComponent} from './components/tab-stepper/tab-step/step.component';
import {AlertComponent} from './components/alert/alert.component';
import {MinimumAgeValidatorDirective} from './components/form-group/validators/minimum-age-validator.directive';
import {TodoStepperComponent} from './components/todo-stepper/todo-stepper.component';
import {TodoStepComponent} from './components/todo-step/todo-step.component';
import {StdTextModule} from "../../std-text/std-text.module";
import {S2dIconsModule} from "../../icons/s2d-icons.module";
import { StdComponentsModule } from "../../std-components/std-components.module";

@NgModule({
    providers: [],
    declarations: [
        TabBarComponent,
        TestTabComponent,
        TabContentComponent,
        TabContentContainerDirective,
        DropDownComponent,
        PillComponent,
        LoadingComponent,
        ProgressBarComponent,
        FormGroupComponent,
        LetterOnlyValidatorDirective,
        PasswordValidatorDirective,
        ConfirmationCodeValidatorDirective,
        PhoneValidatorDirective,
        EmailListValidatorDirective,
        CheckboxContainerComponent,
        CheckboxComponent,
        DropDownMenuComponent,
        AbandonFormComponent,
        LocationPickerComponent,
        CardDeckComponent,
        CardComponent,
        DataTableComponent,
        UploadItemComponent,
        UploadAssetComponent,
        DndDirective,
        HeaderComponent,
        CardTableComponent,
        BreadcrumbsComponent,
        LabelGroupComponent,
        ConfirmationModalComponent,
        ReorderModalComponent,
        ReorderListComponent,
        ActionListComponent,
        ActionComponent,
        ReorderListComponent,
        RangeSliderComponent,
        ActiveComponent,
        SingleSelectionListComponent,
        AssetPreviewComponent,
        SegmentedControlComponent,
        ThemeCarouselImageComponent,
        DetailContentContainerComponent,
        DetailBackButtonComponent,
        DetailTitleComponent,
        DetailTitleNoUnderlineComponent,
        UploadImageModalComponent,
        PostalZipCodeValidatorDirective,
        CheckboxListFilterPipe,
        LabelWithValueComponent,
        ImageLoaderComponent,
        PaginationComponent,
        PaginateNextButtonDirective,
        PaginatePreviousButtonDirective,
        PaginateMiddleContentDirective,
        TabBarRoutingComponent,
        ContentContainerComponent,
        TabStepperComponent,
        TabGroupComponent,
        TabComponent,
        StepComponent,
        AlertComponent,
        MinimumAgeValidatorDirective,
        TodoStepperComponent,
        TodoStepComponent,
        QrCodeComponent,
    ],
    exports: [
        // Shared Components
        TabBarComponent,
        MaterialModule,
        TestTabComponent,
        LoadingComponent,
        NgxSpinnerModule,
        DropDownComponent,
        PillComponent,
        ColorPickerModule,
        ProgressBarComponent,
        FormsModule,
        ReactiveFormsModule,
        FormGroupComponent,
        LetterOnlyValidatorDirective,
        CheckboxComponent,
        CheckboxContainerComponent,
        BsDropdownModule,
        DropDownMenuComponent,
        AbandonFormComponent,
        CardDeckComponent,
        CardComponent,
        DataTableComponent,
        UploadItemComponent,
        UploadAssetComponent,
        HeaderComponent,
        CardTableComponent,
        BreadcrumbsComponent,
        LabelGroupComponent,
        ConfirmationModalComponent,
        ReorderModalComponent,
        RangeSliderComponent,
        ActionListComponent,
        ActiveComponent,
        SingleSelectionListComponent,
        AssetPreviewComponent,
        SegmentedControlComponent,
        DetailContentContainerComponent,
        DetailBackButtonComponent,
        DetailTitleComponent,
        DetailTitleNoUnderlineComponent,
        UploadImageModalComponent,
        CheckboxListFilterPipe,
        LabelWithValueComponent,
        ImageLoaderComponent,
        PaginationComponent,
        PaginateNextButtonDirective,
        PaginatePreviousButtonDirective,
        PaginateMiddleContentDirective,
        TabBarRoutingComponent,
        ContentContainerComponent,
        TabStepperComponent,
        StepComponent,
        PasswordValidatorDirective,
        PhoneValidatorDirective,
        AlertComponent,
        MinimumAgeValidatorDirective,
        TodoStepperComponent,
        DndDirective,
        TodoStepComponent,
        QrCodeComponent,
    ],
    imports: [
        CommonModule,
        MaterialModule,
        NgxSpinnerModule.forRoot({ type: 'ball-clip-rotate' }),
        ColorPickerModule,
        FormsModule,
        ReactiveFormsModule,
        BsDropdownModule,
        NgbTooltipModule,
        IMaskModule,
        NgxPopperModule,
        ImageCropperModule,
        RouterModule,
        S2dIconsModule,
        StdTextModule,
        QRCodeModule,
        StdComponentsModule
    ]
})
export class SharedModule {
}
