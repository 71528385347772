import { Deserializable } from "../../protocols/deserializable";

export class unsubscribedEmailResponse implements Deserializable{
    
    public success:boolean;
    public data:unsubscribedEmailResponse
    public error:string;
    onDeserialize() {
        
    }
}