import {Component, Input, OnInit} from '@angular/core';
import {IconSettings} from "./icon-settings";

@Component({
  selector: 'lib-base-icon-component',
  templateUrl: './base-icon-component.component.html',
  styleUrls: ['./base-icon-component.component.css'],
  host: {
    '[class.std-icon]': 'true'
  }
})
export class BaseIconComponent implements OnInit {
  // @ts-ignore
  @Input() iconSettings: IconSettings|any = new IconSettings();
  constructor() { }

  ngOnInit(): void {
  }

}
