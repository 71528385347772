import {AfterContentInit, Component, QueryList, ViewChild} from '@angular/core';
import {CdkStepper} from "@angular/cdk/stepper";
import {TabBarRouterItem} from "../../../../models/shared/stylesheet/tab-bar-router-item";
import {map, takeUntil} from "rxjs/operators";
import {StepComponent} from "./tab-step/step.component";
import {BehaviorSubject} from "rxjs";

@Component({
    selector: 'app-tab-stepper',
    templateUrl: './tab-stepper.component.html',
    styleUrls: ['./tab-stepper.component.scss'],
    providers: [{provide: CdkStepper, useExisting: TabStepperComponent}]
})
export class TabStepperComponent extends CdkStepper implements AfterContentInit {
    tabs$$: BehaviorSubject<TabBarRouterItem[]> = new BehaviorSubject(null);

    get currentTab(): TabBarRouterItem {
        return (this.steps.toArray()[this.selectedIndex] as StepComponent).tab;
    }

    onClick(index: number): void {
        this.selectedIndex = index;
    }

    ngAfterViewInit() {
        super.ngAfterViewInit();
    }

    ngOnInit(): void {
        this.steps.changes.pipe(
            takeUntil(this._destroyed),
            map((queryList: QueryList<StepComponent>) => queryList.toArray().map((step, index) => {
                step.tab.id = index;
                return step.tab;
            })),
        ).subscribe(this.tabs$$);
    }
}
