import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PercentInputComponent } from './inputs/percent-input/percent-input.component';
import { FormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SwitchInputComponent } from './inputs/switch-input/switch-input.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { StdFormField } from './form-field/std-form-field.component';
import { FormInputDirective } from './inputs/form-input/form-input.directive';
import { PhoneInputComponent } from './inputs/phone-input/phone-input.component';
import { PasswordInputComponent } from './inputs/password-input/password-input.component';
import { EmailInputComponent } from './inputs/email-input/email-input.component';
import { CheckboxComponent } from './inputs/checkbox/checkbox.component';
import { FileInputComponent } from './inputs/file-input/file-input.component';
import { FileListItemComponent } from './inputs/file-input/file-list-item/file-list-item.component';
import { SharedModule } from '../views/shared/shared.module';
import { StdTextModule } from '../std-text/std-text.module';
import { S2dIconsModule } from '../icons/s2d-icons.module';
import { StdComponentsModule } from '../std-components/std-components.module';
import { NativeSelectDirective } from './inputs/native-select.directive';
import { CurrencyInputComponent } from './inputs/currency-input/currency-input.component';
import { StdLayoutModule } from '../layout/std-layout.module';
import { SearchComponent } from './inputs/search/search.component';
import {
  SelectComponent,
  StdSelectTrigger,
} from './inputs/select/select.component';
import { CheckboxGroupComponent } from './inputs/checkbox-group/checkbox-group.component';
import { FormErrorComponent } from './errors/form-error/form-error.component';

export const EmitEventFalse = { emitEvent: false };

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IMaskModule,
    MatFormFieldModule,
    MatInputModule,
    S2dIconsModule,
    NgbTooltipModule,
    SharedModule,
    StdTextModule,
    StdComponentsModule,
    StdLayoutModule,
    StdComponentsModule,
  ],
  declarations: [
    PercentInputComponent,
    SwitchInputComponent,
    StdFormField,
    FormInputDirective,
    PasswordInputComponent,
    FileInputComponent,
    PhoneInputComponent,
    EmailInputComponent,
    FileListItemComponent,
    CurrencyInputComponent,
    NativeSelectDirective,
    SearchComponent,
    SelectComponent,
    StdSelectTrigger,
    CheckboxGroupComponent,
    CheckboxComponent,
    FormErrorComponent,
  ],
  exports: [
    PercentInputComponent,
    SwitchInputComponent,
    StdFormField,
    FormInputDirective,
    PasswordInputComponent,
    FileInputComponent,
    PhoneInputComponent,
    EmailInputComponent,
    CheckboxComponent,
    CurrencyInputComponent,
    NativeSelectDirective,
    SearchComponent,
    SelectComponent,
    StdSelectTrigger,
    CheckboxGroupComponent,
    FormErrorComponent,
  ],
})
export class StdFormsModule {}
