import {defer, Observable, of, Subject} from 'rxjs';
import {catchError, filter, finalize, take, takeUntil, tap} from 'rxjs/operators';
import {LoadingOptions} from '../models/shared/loading-options';

declare module 'rxjs/internal/Observable' {
  interface Observable<T> {
    notNull(): Observable<T>;

    firstNotNull(): Observable<T>;

    takeUntil(takeUntilObject: Observable<any>): Observable<T>;
    take(count:  number): Observable<T>;
    indicate(loadingOpts: LoadingOptions, message: string): Observable<T>;
    clearAndCatchError(error: Subject<Error>, clearValue?: any): Observable<T>;
  }
}

Observable.prototype.notNull =
  function(): Observable<any> {
    return this.pipe(filter(x => x != null));
  };

Observable.prototype.firstNotNull =
  function(): Observable<any> {
    return this.pipe(filter(x => x != null), take(1));
  };

Observable.prototype.takeUntil =
  function(takeUntilObject: Observable<any>): Observable<any> {
    return this.pipe(takeUntil(takeUntilObject));
  };

Observable.prototype.take =
  function(count: number): Observable<any> {
    return this.pipe(take(count));
  };

Observable.prototype.indicate =
  function(loadingOpts: LoadingOptions, message: string): Observable<any> {
    return this.pipe(indicate(loadingOpts, message));
  };

Observable.prototype.clearAndCatchError =
  function(error: Subject<any>, clearValue: any = null): Observable<any> {
    return this.pipe(
        tap(() => error.next(clearValue)),
        catchError((e, caught)=> {
          error.next(e);
          return error;
        })
    );
  };


export function indicate<T>(loadingOpts: LoadingOptions, message: string): (source: Observable<T>) => Observable<T> {
  return (source: Observable<T>): Observable<T> => source.pipe(
    prepare(() => loadingOpts.addRequest(message)),
    finalize(() => loadingOpts.removeRequest(message)),
  );
}

export function clearAndCatchError<T>(error: Subject<any>, clearValue: any = null): (source: Observable<T>) => Observable<T> {
  return (source: Observable<T>): Observable<T> => source.pipe(
      tap(() => error.next(clearValue)),
      catchError((e, caught)=> {
          error.next(e);
          return of(null);
      })
  );
}
export function indicateOnNext<T>(loadingOpts: LoadingOptions, message: string): (source: Observable<T>) => Observable<T> {
  return (source: Observable<T>): Observable<T> => source.pipe(
    tap(() => loadingOpts.removeRequest(message)),
    finalize(() => loadingOpts.removeRequest(message)),
  );
}

export function prepare<T>(callback: () => void): (source: Observable<T>) => Observable<T> {
  return (source: Observable<T>): Observable<T> => defer(() => {
    callback();
    return source;
  });
}

