import { Component } from '@angular/core';
import {BaseIconComponent} from '../base-icon-component/base-icon-component';

@Component({
  selector: 's2d-icon-clipboard-list-outline',
  templateUrl: `./clipboard-list-outline.html`,
  styleUrls: ['./clipboard-list-outline.scss'],
})
export class ClipboardListOutlineIconComponent extends BaseIconComponent {
}
