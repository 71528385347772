import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {SessionService} from '../session-service';
import {catchError, filter, switchMap} from 'rxjs/operators';
import {AccountDomainModel} from '../../domainModels/account-domain-model';
import {Session} from '../../models/account/dto/session';
import {CustomError} from "../../models/shared/custom-error";

@Injectable()
export class AuthInterceptorInterceptor implements HttpInterceptor {

    private refreshingToken: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private router: Router,
        private session: SessionService,
        private accountDomainModel: AccountDomainModel
    ) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        request = this.addAuthenticationToken(request);
        return next.handle(request)
            .pipe(catchError(err => {
                if (err.status !== 401) {
                    return throwError(new CustomError(err, request.url));
                }

                if (this.refreshingToken.value) {
                    // wait until new token is ready and we can retry the request
                    return this.refreshingToken
                        .pipe(
                            filter(refreshing => refreshing === false),
                            switchMap(() => {
                                if(!this.session.getAuthToken()) {
                                    return throwError('No auth token found after session refresh attempt')
                                }
                                return next.handle(this.addAuthenticationToken(request, this.session.getAuthToken()));

                            })
                        );
                }
                // ensure subsequent calls are waiting until token is retrieved
                this.refreshingToken.next(true);
                return this.accountDomainModel.isAuthenticated(true).pipe(
                    switchMap((session: Session) => {
                        this.refreshingToken.next(false);
                        if (!session) {
                            this.accountDomainModel.signOut()
                            return throwError('Could not refresh access token');
                        }
                        return next.handle(this.addAuthenticationToken(request, session.accessToken));

                    }),
                );
            }));
    }

    addAuthenticationToken(request, newToken?: string) {
        if (newToken) {
            return request.clone({
                headers: this.createHeaders(newToken, request.headers)
            });
        } else {
            const token = this.session.getAuthToken();
            return request.clone({
                headers: this.createHeaders(token, request.headers)
            });
        }
    }

    private createHeaders(token: string, headers: HttpHeaders): HttpHeaders {
        if (!headers.get('Content-Type')) {
            headers = headers.append('Content-Type', 'application/json');
        }
        if (!headers.get('Accept')) {
            headers = headers.append('Accept', 'application/json');
        }
        if (token) {
            headers = headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    }
}
