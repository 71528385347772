import { Component } from '@angular/core';
import {BaseIconComponent} from '../base-icon-component/base-icon-component';

@Component({
  selector: 's2d-icon-gas-station-solid',
  templateUrl: `./gas-station-solid.html`,
  styleUrls: ['./gas-station-solid.scss'],
})
export class GasStationSolidIconComponent extends BaseIconComponent {
}
