import { Component } from '@angular/core';
import {BaseIconComponent} from '../base-icon-component/base-icon-component';

@Component({
  selector: 's2d-icon-bell-outline',
  templateUrl: `./bell-outline.html`,
  styleUrls: ['./bell-outline.scss'],
})
export class BellOutlineIconComponent extends BaseIconComponent {
}
