export class IconSettings {
  fillColor: string;
  strokeColor: string;
  styles: string

  defaultOutlineSettings(): IconSettings {
    const icon = new IconSettings();
    icon.fillColor = '#000';
    icon.strokeColor = '#111111';
    return icon;
  }
  defaultSolidSettings(): IconSettings {
    const icon = new IconSettings();
    icon.fillColor = '#000';
    icon.strokeColor = '#111111';
    return icon;
  }
}
