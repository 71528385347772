import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {DatatableData} from '../../protocols/datatable-data';
import {BaseModel} from './base-model';
import {environment} from '../../../../environments/environment';
import {Session} from "./session";
import {AuthChallengeType} from "../../../views/login/auth-challenge-type";
import { Destination } from './destination';
import { PhoneUtils } from 'src/app/utils/phone-utils';

export class CompanyUnsubscribedSMS extends BaseModel implements DatatableData, Deserializable {
  public id: number;
  public companyId: number;
  public locationId: number;
  public phone: string;
  public name: string;
  public location:any;
  public createdBy: string;
  public createdDate: Date;
  public updatedBy:string;
  public updatedDate:Date;
  public status:boolean

  get formattedPhoneNo(): string {
    if (!this.phone) {
      return null;
    }
    return PhoneUtils.formatPhoneNumber(this.phone);
  }


  public onDeserialize() {
    super.onDeserialize();

  }

  public get isDriver(): boolean {
    return this.companyId === environment.driverCompanyId;
  }

  public get isAdmin(): boolean {
    return this.companyId === environment.adminCompanyId;
  }

  public get isClient(): boolean {
    return !this.isAdmin && !this.isDriver;
  }

  getChildrenUniqueIds(): string[] {
    return [];
  }

  getColor(): string {
    return '';
  }

  getTextColor(): string {
    return '';
  }
}


