<app-loading *ngIf="viewModel.loadingOpts.isLoading" [options]="viewModel.loadingOpts">
</app-loading>

<!--Admin Logo-->
<img (click)="navigateToRoot()" *ngIf="!(viewModel.isStoreToDoorClient$|async)"
  [src]="'assets/logo/dark/logo-stroke.svg'" alt="logo" class="nav-logo">
<!--Client Logo-->
<img (click)="navigateToRoot()" *ngIf="(viewModel.companyLogo$|async) && (viewModel.isStoreToDoorClient$|async)"
  [src]="(viewModel.companyLogo$|async) || ''" alt="logo" class="nav-client-logo">
<!--No Client Logo-->
<div (click)="navigateToRoot()" *ngIf="!(viewModel.companyLogo$|async) && (viewModel.isStoreToDoorClient$|async)"
  class="nav-client-logo no-logo">
</div>
<!--Nav Items-->
<ul class="nav-list custom-menu-nav">
  <ng-container *ngFor="let navItem of viewModel.navItems">

    
<li (click)="navItemSelected(navItem)" *ngIf="showNavItem(navItem)" [ngClass]="{ 'passive-selection' : navItem.locationPicker,
       'active' : isNavItemActive(navItem)}" class="main-menu nav-item active custom-nav-item">
      <div class="nav-item-icon">
        <div class="nav-bar-icon">
          <ng-container *ngComponentOutlet="navItem.icon"></ng-container>
        </div>
        <div class="nav-bar-icon-filled">
          <ng-container *ngComponentOutlet="navItem.iconFilled"></ng-container>
        </div>
      </div>
      <div class="nav-item-label" [ngClass]="{'multi-line-label': multilineNavLabel(navItem)}">
        {{navItem.name}}
      </div>
      <div class="nav-item-icon" *ngIf="navItem.subMenu.length > 0">
        <div class="nav-bar-icon" *ngIf="navItem.showSubmenu">
          <ng-container *ngComponentOutlet="navItem.subMenuicon"></ng-container>
        </div>
        <div class="nav-bar-icon-filled" *ngIf="navItem.showSubmenu">
          <ng-container *ngComponentOutlet="navItem.subMenuicon"></ng-container>
        </div>
        <div class="nav-bar-icon" *ngIf="!navItem.showSubmenu">
          <ng-container *ngComponentOutlet="navItem.subMenuiconFilled"></ng-container>
        </div>
        <div class="nav-bar-icon-filled" *ngIf="!navItem.showSubmenu">
          <ng-container *ngComponentOutlet="navItem.subMenuiconFilled"></ng-container>
        </div>
      </div>

      <div *ngIf="navItem.showSubmenu">
        <div *ngFor="let subMenu of navItem.subMenu" class="pt-3">
          <li (click)="subMenuSelected(subMenu)" *ngIf="showNavItem(subMenu)" [ngClass]="{ 'passive-selection' : subMenu.locationPicker,
              'active' : isNavItemActive(subMenu)}" class="sub-menu nav-item active custom-nav-item">
            <div class="sub-menu nav-item-icon">
              <div class="sub-menu nav-bar-icon">
                <ng-container *ngComponentOutlet="subMenu.icon"></ng-container>
              </div>
              <div class="sub-menu nav-bar-icon-filled">
                <ng-container *ngComponentOutlet="subMenu.iconFilled"></ng-container>
              </div>
            </div>
            <div class="sub-menu nav-item-label" [ngClass]="{'multi-line-label': multilineNavLabel(subMenu)}">
              {{subMenu.name}}
            </div>
            <div *ngIf="getBadgeContentForNavItem(subMenu)" class="sub-menu nav-item-notification-pill">
              {{getBadgeContentForNavItem(navItem)}}
            </div>
            <div *ngIf="navItem.locationPicker" class="sub-menu location-picker">
              <img alt="icon" class="nav-bar-icon" src="../../../../assets/icons/light/outline/selector.svg">
            </div>
          </li>
        </div>
      </div>

    <div *ngIf="getBadgeContentForNavItem(navItem)" class="nav-item-notification-pill">
      {{getBadgeContentForNavItem(navItem)}}
    </div>
    <div *ngIf="navItem.locationPicker" class="location-picker">
      <img alt="icon" class="nav-bar-icon" src="../../../../assets/icons/light/outline/selector.svg">
    </div>
    </li>

    <!-- <li (click)="navItemSelected(navItem)" *ngIf="showNavItem(navItem)" [ngClass]="{ 'passive-selection' : navItem.locationPicker,
       'active' : isNavItemActive(navItem)}" class="main-menu nav-item active custom-nav-item">
      <div class="nav-item-icon">
        <div class="nav-bar-icon">
          <ng-container *ngComponentOutlet="navItem.icon"></ng-container>
        </div>
        <div class="nav-bar-icon-filled">
          <ng-container *ngComponentOutlet="navItem.iconFilled"></ng-container>
        </div>
      </div>
      <div class="nav-item-label" [ngClass]="{'multi-line-label': multilineNavLabel(navItem)}">
        {{navItem.name}}
      </div>
      <div class="nav-item-icon" *ngIf="navItem.subMenu.length > 0">
        <div class="nav-bar-icon" *ngIf="subMenuHideShow">
          <ng-container *ngComponentOutlet="navItem.subMenuicon"></ng-container>
        </div>
        <div class="nav-bar-icon-filled" *ngIf="subMenuHideShow">
          <ng-container *ngComponentOutlet="navItem.subMenuicon"></ng-container>
        </div>
        <div class="nav-bar-icon" *ngIf="!subMenuHideShow">
          <ng-container *ngComponentOutlet="navItem.subMenuiconFilled"></ng-container>
        </div>
        <div class="nav-bar-icon-filled" *ngIf="!subMenuHideShow">
          <ng-container *ngComponentOutlet="navItem.subMenuiconFilled"></ng-container>
        </div>
      </div>

      <div *ngIf="subMenuHideShow">
        <div *ngFor="let subMenu of navItem.subMenu" class="pt-3">
          <li (click)="subMenuSelected(subMenu)" *ngIf="showNavItem(subMenu)" [ngClass]="{ 'passive-selection' : subMenu.locationPicker,
              'active' : isNavItemActive(subMenu)}" class="sub-menu nav-item active custom-nav-item">
            <div class="sub-menu nav-item-icon">
              <div class="sub-menu nav-bar-icon">
                <ng-container *ngComponentOutlet="subMenu.icon"></ng-container>
              </div>
              <div class="sub-menu nav-bar-icon-filled">
                <ng-container *ngComponentOutlet="subMenu.iconFilled"></ng-container>
              </div>
            </div>
            <div class="sub-menu nav-item-label" [ngClass]="{'multi-line-label': multilineNavLabel(subMenu)}">
              {{subMenu.name}}
            </div>
            <div *ngIf="getBadgeContentForNavItem(subMenu)" class="sub-menu nav-item-notification-pill">
              {{getBadgeContentForNavItem(navItem)}}
            </div>
            <div *ngIf="navItem.locationPicker" class="sub-menu location-picker">
              <img alt="icon" class="nav-bar-icon" src="../../../../assets/icons/light/outline/selector.svg">
            </div>
          </li>
        </div>
      </div>

    <div *ngIf="getBadgeContentForNavItem(navItem)" class="nav-item-notification-pill">
      {{getBadgeContentForNavItem(navItem)}}
    </div>
    <div *ngIf="navItem.locationPicker" class="location-picker">
      <img alt="icon" class="nav-bar-icon" src="../../../../assets/icons/light/outline/selector.svg">
    </div>
    </li> -->
  </ng-container>
</ul>