import {ChallengeInterface} from './challenge.interface';
import {Observable, Subject} from "rxjs";
import {User} from "../../../../models/account/dto/user";
import {AuthChallengeType} from "../../auth-challenge-type";
import {Subscribable} from "../../../../models/base/subscribable";

export abstract class AbstractChallenge extends Subscribable implements ChallengeInterface {
    public user: Subject<User> = new Subject<User>();
    abstract challengeType: AuthChallengeType;
    handle(handleChallengeObject: User): Observable<User> {
        console.warn('Handle not implemented');
        return this.user;
    }
}
