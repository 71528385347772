import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {AssetUrl} from './asset-url';
import {MediaType} from '../../enum/dto/media-type.enum';
import {AssetSize} from '../../enum/dto/image-size.enum';
import {Cachable} from '../../protocols/cachable';
import {DateUtils} from '../../../utils/date-utils';
import {MediaUtils} from '../../../utils/media-utils';
import {CachePolicy} from '../../enum/shared/cachable-image-policy.enum';
import '../../../utils/array.extensions';
import '../../../utils/date-extensions';
import {BaseModel} from '../../account/dto/base-model';

export class Asset extends BaseModel implements Deserializable, Cachable {
  public id: string;
  public links: AssetUrl[];
  public name: string;
  public type: MediaType;
  public cachedTime: number;
  // Override Deserialize Sizes
  public sizeOverrides: AssetSize[];

  static buildCacheKey(id: string, size: AssetSize): string {
    return `Image-${id}-${size}`;
  }

  public isEmpty() {
    return this.id === '';
  }

  public onDeserialize() {
    if (this.sizeOverrides && this.sizeOverrides.length > 0) {
      const filteredUrls = this.links?.filter(u => {
        return this.sizeOverrides.contains(u.size);
      });
      this.links = DeserializeHelper.deserializeArray(AssetUrl, filteredUrls);
    } else {
      // Default Cache Sizes
      const filteredUrls = this.links?.filter(u => {
        if (MediaUtils.isImage(this.type)) {
          return u.size === AssetSize.Medium;
        } else if (MediaUtils.isVideo(this.type)) {
          return u.size === AssetSize.Original;
        }
      });
      this.links = DeserializeHelper.deserializeArray(AssetUrl, filteredUrls);
    }
  }

  public isImage(): boolean {
    return this.type.match(/image\/*/) != null;
  }

  public isVideo(): boolean {
    return this.type.match(/video\/*/) != null;
  }

  public getAssetUrl(size: AssetSize): AssetUrl {
    return this.links.find(u => u.size === size);
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneHour();
  }

  cacheKey(size: AssetSize): string {
    return Asset.buildCacheKey(this.id, size);
  }

  imageCachePolicy(): CachePolicy {
    return CachePolicy.Service;
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestamp() > expiresAt;
  }

}
