import { Component } from '@angular/core';
import {BaseIconComponent} from '../base-icon-component/base-icon-component';

@Component({
  selector: 's2d-icon-truck-solid',
  templateUrl: `./truck-solid.html`,
  styleUrls: ['./truck-solid.scss'],
})
export class TruckSolidIconComponent extends BaseIconComponent {
}
