import { Currency } from "../models/account/dto/currencyType";

export class NumberUtils {
  static formFloatCustomParser(numString: string): number {
    return numString ? parseFloat(this.removeNonNumericCharacters(numString)) : null;
  }

  static formIntegerCustomParser(numString: string): number {
    return numString ? parseInt(this.removeNonNumericCharacters(numString), null) : null;
  }

  static removeNonNumericCharacters(numString): string {
    return numString.replace(/(,|[^\d.-]+)+/g, '');
  }

  static removeNonDigitCharacters(numString): string {
    return numString.replace(/[^\d]/g, '');
  }

  static formatNumberAsPercentString(num: number): string {
    return `${num.toFixed(2)}%`;
  }

  static formatNumberAsCurrency(num: number): string {
    if (num == null) {
      return '';
    }
    if (num >= 0) {
      return `$${num.toFixed(2)}`;
    } else {
      return `-$${(num * -1).toFixed(2)}`;
    }
  }

  static formatNumberWithCurrencyCode(num: number,currencyCode): string {
    if (num == null) {
      return '';
    }
    if (num >= 0) {
      return `${currencyCode} ${num.toFixed(2)}`;
    } else {
      return `${currencyCode} -${(num * -1).toFixed(2)}`;
    }
  }

  static formatNumberWithCurrency(num: number,currency:Currency): string {
    if (num == null) {
      return '';
    }
    if (num >= 0) {
      return `${currency.currencyCode} ($) ${num.toFixed(2)}`;
    } else {
      return `${currency.currencyCode} -($) ${(num * -1).toFixed(2)}`;
    }
  }
}
