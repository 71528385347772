import { Component } from "@angular/core";
import { BaseIconComponent } from "../base-icon-component/base-icon-component";

@Component({
    selector: 's2d-icon-report-solid',
    templateUrl: `./report-solid.html`,
    styleUrls: ['./report-solid.scss'],
  })
  export class ReportSolidIconComponent extends BaseIconComponent {
  }
  