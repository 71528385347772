import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {CanMatchClient} from './portals/client-portal/can-match-client';
import {CanMatchDriver} from './portals/driver-portal/can-match-driver';
import {CanMatchAdmin} from './portals/admin-portal/can-match-admin';
import {LoginRedirectGuard} from './portals/guards/login-redirect.guard';
import {LoginContainerComponent} from './views/login/components/login-container/login-container.component';
import { EmailLogUnSubscribeComponent } from './views/company-email-logs-unsubscribe/company-email-logs-unsubscribe';
import { SMSLogUnSubscribeComponent } from './views/company-sms-logs-unsubscribe/company-sms-logs-unsubscribe';

const routes: Routes = [

  {
    path: '',
    canMatch: [CanMatchDriver],
    loadChildren: () => import('./portals/driver-portal/driver-portal.module').then(m => m.DriverPortalModule),
  },
  {
    path: '',
    canMatch: [CanMatchClient],
    loadChildren: () => import('./portals/client-portal/client-portal.module').then(m => m.ClientPortalModule),
  },
  {
    path: '',
    canMatch: [CanMatchAdmin],
    loadChildren: () => import('./portals/admin-portal/admin-portal.module').then(m => m.AdminPortalModule),
  },
  {
    path: '',
    loadChildren: () => import('./views/login/login.module').then(m => m.LoginModule),
  },
  {
    path: '',
    loadChildren: () => import('./views/sign-up/sign-up.module').then(m => m.SignUpModule),
  },
  {
    path: 'print',
    loadChildren: () => import('./views/printer/printer.module').then(m => m.PrinterModule),
  },
  {
     path: 'unsubscribe/:companyId/:locationId/:emailId', 
     component: EmailLogUnSubscribeComponent ,
     loadChildren: () => import('./views/company-email-logs-unsubscribe/company-email-logs-unsubscribe-routing.module').then(m => m.CompanyEmaillogsUnsubscribeRoutingModule),
  },
  {
    path: 'usms/:companyId/:locationId/:phone', 
    component: SMSLogUnSubscribeComponent ,
    loadChildren: () => import('./views/company-sms-logs-unsubscribe/company-sms-logs-unsubscribe-routing.module').then(m => m.CompanySMSlogsUnsubscribeRoutingModule),
  },
  {
    path: 'ordertracking/:trackingId', 
    loadChildren: () => import('./views/orders/tracking/recipient-tracking-order/recipient-tracking-order.module').then(m => m.RecipientTrackingOrderModule),
  },
  {
    path: 'ordertracking', 
    loadChildren: () => import('./views/orders/tracking/recipient-tracking-order/recipient-tracking-order.module').then(m => m.RecipientTrackingOrderModule),
  },
  {
    path: 'rate-and-tip/:orderId', 
    loadChildren: () => import('./views/redirect-to-tip/redirect-to-tip.module').then(m => m.RedirectToTipModule),
  },
  {
    path: 'login',
    canActivate: [LoginRedirectGuard],
    component: LoginContainerComponent
  },
  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy', onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
