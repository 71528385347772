import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {Session} from './session';

export class RefreshSessionResponse implements Deserializable {
  public refreshToken: string;
  public userSession: Session;

  onDeserialize() {
    this.userSession = DeserializeHelper.deserializeToInstance(Session, this.userSession);
  }
}

