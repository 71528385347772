import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { UserApi } from 'src/app/api/user-api';
import { UnsubscribeEmailRequest } from 'src/app/models/account/requests/UnsubscribeEmailRequest';
import { UnsubscribeLocation } from 'src/app/models/account/response/UnsubscribeLocation';
import { ToastService } from 'src/app/services/toast-service';

@Component({
  selector: 'app-company-email-logs-unsubscribe',
  templateUrl: './company-email-logs-unsubscribe.html',
  styleUrls: ['./compnay-email-logs-unsubscribe.scss']
})
export class EmailLogUnSubscribeComponent {
  confirmationMessage: string
  companyId: string;
  locationId: string;
  email: string;
  public unsubscribeLocation: UnsubscribeLocation
  public subscribeFlag: boolean = false

  constructor(private http: HttpClient,private activatedRoute: ActivatedRoute,
    private router: Router, private userApi: UserApi,private toaster: ToastService) {
    this.init();
  }

  init() {}

  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.companyId = params.companyId;
      this.locationId = params.locationId;
      this.email = params.emailId;
      this.userApi.getCompanyUnsubcribePageDetail(Number(this.companyId), Number(this.locationId))
        .subscribe(
          (res: UnsubscribeLocation) => {
            this.unsubscribeLocation = res
          },
          error => {
            console.error('Logo failed:', error);
            this.toaster.publishErrorMessage("Something went wrong please try again!");
          }
        );
    });
  }

  onSave() {
    this.confirmationMessage = "You have unsubscribed.";
    var request = new UnsubscribeEmailRequest();
    request.companyId = Number(this.companyId);
    request.locationId = Number(this.locationId);
    request.email = this.email;
    this.userApi.unsubcribeFromEmail(request).subscribe(
      (res: any) => {
        if (!res.success && res.error)
          this.confirmationMessage = res.error
        this.subscribeFlag = !this.subscribeFlag
      }, error => {
        console.log('unsubscription failed:', error);
        this.toaster.publishErrorMessage("Something went wrong please try again!");
      }
    )
  }
}
