import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {BaseModel} from './base-model';
import {DatatableData} from '../../protocols/datatable-data';
import {Address} from './address';
import {DateUtils} from '../../../utils/date-utils';
import { DateTimeFormat } from 'src/app/modules/date/date-time.format';

export class CompanyPreview extends BaseModel implements DatatableData, Deserializable {
    public id: number;
    public name: string;
    public enabled: boolean;
    public locationCount: number;
    public createdBy: string;
    public createdDate: Date = new Date();
    public updatedBy:string;
    public updatedDate:Date=new Date();

    constructor() {
        super();
    }

    public onDeserialize() {
        super.onDeserialize();
    }

    getChildrenUniqueIds(): string[] {
        return [];
    }

    getColor(): string {
        return '';
    }

    getTextColor(): string {
        return '';
    }
}

