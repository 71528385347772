/* tslint:disable:no-string-literal */
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CanDeactivateState } from './services/guards/can-deactivate.guard';
import { filter, tap } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountDomainModel } from './domainModels/account-domain-model';
import { SessionService } from './services/session-service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  currentURL = '';

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private domainModel: AccountDomainModel,
    private window: Window,
    private sessionService: SessionService
  ) {
    // Config for Firebase
    // const _ = firebase.initializeApp(environment.firebaseConfig);
    // const userProperties: { [key: string]: any; } = {};
    // userProperties['Platform'] = 'Dashboard';
    // firebase.analytics().setUserProperties(userProperties);
    // this.loggingService.SetFirebaseAppInstance(firebaseApp);

    // Config for Abandon changes on back button
    window.onpopstate = () =>
      (CanDeactivateState.defendAgainstBrowserBackButton = true);
    router.events
      .pipe(
        filter((e) => e instanceof NavigationEnd),
        tap(() => (CanDeactivateState.defendAgainstBrowserBackButton = false))
      )
      .subscribe();
  }

  ngOnInit() {
    const regex = new RegExp('track.');
    const ordertracking = new RegExp('/ordertracking/');
    this.currentURL = window.location.href;
    const isTrackingURL = regex.test(this.currentURL);

    const rateRegex = new RegExp('rate-and-tip.');
    const isRateAndTip = rateRegex.test(this.currentURL);

    const driverSingUpRegex = new RegExp('/sign-up');
    const isDriverSignUp = driverSingUpRegex.test(this.currentURL);
    
    if (isTrackingURL) {
      const isWithTrackId = ordertracking.test(this.currentURL);
      if (isWithTrackId) {
        const urlString = this.currentURL.substring(
          this.currentURL.lastIndexOf('ordertracking/') + 1
        );
        const trackingId = urlString.split('/').pop();
        this.router.navigate(['/ordertracking/' + trackingId]);
      } else {
        console.log("this.currentURL.includes('trackid=')",this.currentURL.includes('trackid='));
        if (this.currentURL.includes('trackid=')) {
          const params = this.currentURL.split('?')[1].split('&');
          const ParamtrackingId = params
            .find((param) => param.startsWith('trackid='))
            .split('=')[1];          
          if (ParamtrackingId.length > 6) {            
            this.router.navigate(['/ordertracking/' + ParamtrackingId]);
          } else {
            this.router.navigate(['/ordertracking']);
          }
        } else {
          this.router.navigate(['/ordertracking']);
        }
      }
    }
    else if(isRateAndTip) {
      const urlString = this.currentURL.substring(
        this.currentURL.lastIndexOf('rate-and-tip/') + 1
      );
      let orderId = urlString.split('/').pop();
      orderId = orderId.replaceAll('?','');
      orderId = orderId.replaceAll('orderid=','');
      orderId = orderId.replaceAll('trackid=','');
      this.router.navigate(['/rate-and-tip/' + orderId])
    }
    else if(isDriverSignUp){
      this.router.navigate(['/sign-up-v2'])
    }
    else {
      if (this.currentURL.includes('trackid=')) {
        const params2 = this.currentURL.split('?')[1].split('&');
        const ParamtrackingId2 = params2
          .find((param) => param.startsWith('trackid='))
          .split('=')[1];
          console.log("ParamtrackingId2",ParamtrackingId2);
        if (ParamtrackingId2.length > 6) {
          
          console.log('ParamtrackingId2', ParamtrackingId2);
          this.router.navigate(['/ordertracking/' + ParamtrackingId2]);
        } else {
          this.router.navigate(['/ordertracking']);
        }
      }
    }

    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.modalService.dismissAll();
      window.scrollTo(0, 0);
    });
  }
}
