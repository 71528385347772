import { Deserializable } from "../../protocols/deserializable";

export class ApiResponse<T> implements Deserializable{
    public success:boolean;
    public data:T
    public error:string;

    onDeserialize() {
        
    }
}
