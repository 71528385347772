import { Component } from '@angular/core';
import {BaseIconComponent} from '../base-icon-component/base-icon-component';

@Component({
  selector: 's2d-icon-gas-station-outline',
  templateUrl: `./gas-station-outline.html`,
  styleUrls: ['./gas-station-outline.scss'],
})
export class GasStationOutlineIconComponent extends BaseIconComponent {
}
