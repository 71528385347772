import {Injectable} from '@angular/core';
import {BaseViewModel} from '../../../models/base/base-view-model';
import {LoadingOptions} from '../../../models/shared/loading-options';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {AccountDomainModel} from '../../../domainModels/account-domain-model';
import {NavItem} from '../../../models/shared/nav-item';
import {map, switchMap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {SessionService} from '../../../services/session-service';

@Injectable()
export class SidebarNavViewModel extends BaseViewModel {

  loadingOpts: LoadingOptions = LoadingOptions.defaultLight(false, false);
  sessionContainer$ = this.domainModel.sessionContainer$;
  isStoreToDoorClient$ = this.domainModel.sessionContainer$.pipe(map(s => {
    return s.user.companyId !== environment.adminCompanyId && s.user.companyId !== environment.driverCompanyId;
  }));
  currentNavUrl: string;
  badgeContent = new Map<string, string>();
  navItems: NavItem[] = [
    NavItem.generateOrdersNavItem(),
    NavItem.generateAdminUsersNavItem(),
    NavItem.generateAdminCompaniesNavItem(),
    NavItem.generateDriversNavItem(),
    NavItem.generateTaxesNavItem(),
    NavItem.generateClientAlertsNavItem(),
    NavItem.generateFuelSurchargesNavItem(),

    NavItem.generateDeliveriesNavItem(),
    NavItem.generateCompensationNavItem(),

    NavItem.generateCreateOrdersNavItem(),
    NavItem.generateManageOrdersNavItem(),

    NavItem.generateEmployeesNavItem(),
    NavItem.generateBillingNavItem(),
    NavItem.generateAdminReportNavItem(),
    NavItem.generalEmailLogsNavItem(),
    //NavItem.generalSMSLogsNavItem(),
    NavItem.generateLocationsNavItem(),
    NavItem.generateRolesNavItem(),
    NavItem.generateProfileNavItem(),
    NavItem.generateSignOutNavItem(),
  ];
  companyLogo$ = this.sessionContainer$.pipe(switchMap(() => this.domainModel.getCompanyLogo()));

  constructor(private domainModel: AccountDomainModel,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private session: SessionService
  ) {
    super();
    this.init();
  }

  init() {
    super.init();
    this.loadingOpts.fullscreen = true;
    this.loadingOpts.zIndex = 100;
    this.bindUrlParams();
  }

  setupBindings() {
  }

  getDefaultRoute() {
    return this.navItems.find(i => this.session.hasPermission(i.permissionRule))?.routerPath ?? '/profile';
  }

  bindUrlParams() {
    this.currentNavUrl = this.router.url;
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.urlChanged(evt);
    });
  }

  private urlChanged(nav: NavigationEnd) {
    this.currentNavUrl = nav.url;
  }

  signOut() {
    const companyId = this.domainModel.getUser().companyId;
    const signInPortal = companyId === environment.adminCompanyId ? 'admin' : companyId === environment.driverCompanyId ? 'driver' : 'client';
    this.loadingOpts.addRequest($localize`Signing Out`);
    setTimeout(() => {
      this.domainModel.signOut();
      this.router.navigate(['/', signInPortal]);
    }, 1000);
  }
}
