/***
 * For info about date pipe or date formatting:
 * https://angular.io/api/common/DatePipe
 * https://angular.io/api/common/DatePipe#pre-defined-format-options
 * https://angular.io/api/common/DatePipe#custom-format-options
 */

export enum DateTimeFormat {
  HourMinutes_am_pm = 'h:mm a', // '1:00 am'
  HourMinutes_AM_PM = 'h:mm A', // '1:00 AM'
  TwentyFourHour = 'HH:mm:ss', // '01:00:00'
  YearMonthDay_hyphen = 'YYYY-MM-DD', // '2022-01-01'
  YearMonthDay_slash = 'YYYY/MM/DD', // '2022/01/01'
  ShortMonthDay = 'MMM D', // 'Jan 1'
  FullMonthDay = 'MMMM D', // 'January 1'
  ShortMonthDayYear = 'MMM D, YYYY', // 'Jan 1, 2022'
  FullMonthDayYear = 'MMMM D, YYYY', // 'January 1, 2022'
  ShortWeekdayMonthDayYear = 'ddd, MMM D YYYY', // 'Mon, Jan 1 2022'
  FullWeekdayMonthDayYear = 'dddd, MMMM D YYYY', // 'Monday, January 1 2022'
  ShortMonthDayTime_AM_PM = 'MMM D - h:mm a', // 'Jan 1 at 1:00 AM'
  ShortMonthDayYearTime_AM_PM = 'lll', // 'Jan 1, 2022 1:00 AM'
  FullMonthDayYearTime_AM_PM = 'LLL', // 'January 1, 2022 1:00 AM'
  FullWeekdayMonthDay_ordinals = 'dddd, MMMM Do', // 'Sunday, January 1st'
  ShortWeekdayMonthDayYearTime_AM_PM = 'llll', // 'Mon, Jan 1, 2022 1:00 AM'
  FullWeekdayMonthDayYearTime_AM_PM = 'LLLL', // 'Monday, January 1, 2022 1:00 AM'
  QueryParamString = 'YYYY-MM-DDTHH:mm:ssZZ', // '2022-01-01T01:00:00-0600'
}

export enum DateTimePipes {
  mediumDateWithAtTime = "MMMM d 'at' h:mm aaaaa'm'", // example: 'September 22 at 1:27 pm'
  YearMonthDay_hyphen = 'YYYY-MM-dd', // example: 'September 22 at 1:27 pm'
  timeLowerCase = "h:mmaaaaa'm'", // example: '1:27pm'
}
