import {QRCodeElementType, QRCodeErrorCorrectionLevel} from 'angularx-qrcode/lib/types';

export class QrCode {
    qrData: string;
    allowEmptyString: boolean = true;
    cssClass?: string;
    colorDark: string = '#111111';
    colorLight: string = '#FFFFFF';
    elementType: QRCodeElementType = 'canvas';
    errorCorrectionLevel: QRCodeErrorCorrectionLevel = 'low';
    margin: number = 0;
    scale: number = 1;
    title?: string;
    width: number = 200;
    size: number = 256;
    appName: string = 'StoreToDoor';

    constructor(qrData: string) {
        this.qrData = qrData;
    }

    get authenticatorUrl(): string {
        return `otpauth://totp/${this.appName}?secret=${this.qrData}`;
    }
}
