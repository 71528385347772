import {HttpErrorResponse} from '@angular/common/http';
import {StdError} from "../../std-forms/errors/std-error";

export class CustomError extends StdError {
  public code: number;
  public title: string;
  public message: string;
  public suggestion: string;

  constructor(err?: HttpErrorResponse, title?: string) {
    let message = 'Error';
    let code;

    if (err) {
      code = err?.status;
      title = `${title} Error`;
      if (typeof err?.error === 'string') {
        message = err?.error
      } else if (typeof err?.error?.Message === 'string') {
        message = err?.error?.Message;
      } else {
        message = $localize`An error has occurred. Please try again.`;
      }
    }
    super({title, message});
    this.code = code;
    this.title = title;
  }
}
