import { Component } from '@angular/core';
import {BaseIconComponent} from '../base-icon-component/base-icon-component';

@Component({
  selector: 's2d-icon-clipboard-list-solid',
  templateUrl: `./clipboard-list-solid.html`,
  styleUrls: ['./clipboard-list-solid.scss'],
})
export class ClipboardListSolidIconComponent extends BaseIconComponent {
}
