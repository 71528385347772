import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap/modal/modal-config';

export class ModalUtils {

  public static confirmationModalOptions(): NgbModalOptions {
    return ModalUtils.mediumStatic();
  }

  public static defaultModalOptions(): NgbModalOptions {
    return ModalUtils.mediumStatic();
  }

  public static defaultLargeModalOptions(): NgbModalOptions {
    return ModalUtils.largeStatic();
  }

  public static csvHelpModalOptions(): NgbModalOptions {
    return {
      size: 'xl',
      backdrop: 'static',
      centered: true,
      windowClass: 'csv-help-modal'
    };
  }

  public static insuranceHelpModalOptions(): NgbModalOptions {
    return {
      size: 'xl',
      backdrop: 'static',
      centered: true,
      windowClass: 'insurance-help-modal'
    };
  }

  static defaultMedium(): NgbModalOptions {
    return {
      size: 'md',
      centered: true,
    };
  }

  static mediumStatic(): NgbModalOptions {
    return {
      size: 'md',
      backdrop: 'static',
      centered: true,
    };
  }

  private static largeStatic(): NgbModalOptions {
    return {
      size: 'lg',
      backdrop: 'static',
      centered: true,
    };
  }

  static autoGrow(): NgbModalOptions {
    return {
      size: 'lg',
      backdrop: 'static',
      centered: true,
      windowClass: 'auto-grow-modal'
    };
  }


}
