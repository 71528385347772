import {Injectable} from '@angular/core';
import {CanMatch, Route, UrlSegment, UrlTree} from "@angular/router";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {SessionContainer} from "../../models/shared/session-container";
import {environment} from "../../../environments/environment";
import {SessionService} from "../../services/session-service";

@Injectable({
    providedIn: 'root'
})
export class CanMatchClient implements CanMatch {

    constructor(private session: SessionService) {
    }

    canMatch(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return this.session.sessionContainer.pipe(
            map((session: SessionContainer) => {
                if (!session) {
                    return false;
                }
                return session.user?.companyId !== environment.driverCompanyId && session.user?.companyId !== environment.adminCompanyId;
            })
        );
    }
}
