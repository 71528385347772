import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {BaseModel} from './base-model';
import {DatatableData} from '../../protocols/datatable-data';
import {Address} from './address';
import {DateUtils} from '../../../utils/date-utils';
import {DateTimeFormat} from "../../../modules/date";

export class Company extends BaseModel implements DatatableData, Deserializable {
  public id: number;
  public name: string = '';
  public onFleetApiKey: string = '';
  public fetchNoOfDaysRecords: any = 7;
  public enabled: boolean = true;
  public launchDate: Date = new Date();
  public launchDateString: string = DateUtils.formatDateAndTime(this.launchDate, DateTimeFormat.YearMonthDay_hyphen);
  public contactName: string = '';
  public contactPhone: string = '';
  public contactEmail: string = '';
  public billingEmails: string = null;
  public headquartersAddressId: string;
  public headquartersAddress: Address;
  public hubSpotId: string = '';
  public expectedDeliveriesPerWeek: number;
  public isActiveOnfleetCreateTaskTrigger: boolean;
  public organizationId: string = '';
  public etaTriggerTime: number = 0;
  public isSend_ETA_Email: boolean = false;
  public isSend_ETA_SMS: boolean = false;

  constructor() {
    super();
  }

  public onDeserialize() {
    super.onDeserialize();
    this.contactEmail = this.contactEmail ?? '';
    this.contactPhone = this.contactPhone ?? '';
    this.contactName = this.contactName ?? '';
    this.billingEmails = this.billingEmails ?? '';
    this.launchDate = DeserializeHelper.deserializeToDate(this.launchDate) ?? null;
    this.launchDateString = DateUtils.formatDateAndTime(this.launchDate, DateTimeFormat.YearMonthDay_hyphen) ?? null;
    this.headquartersAddress = DeserializeHelper.deserializeToInstance(Address, this.headquartersAddress ?? null);
    this.hubSpotId = this.hubSpotId ?? '';
    this.etaTriggerTime = this.etaTriggerTime ?? 0;
    this.organizationId = this.organizationId ?? '';
    this.isSend_ETA_Email = this.isSend_ETA_Email ?? false;
    this.isSend_ETA_SMS = this.isSend_ETA_SMS ?? false;
  }

  getChildrenUniqueIds(): string[] {
    return [];
  }

  getColor(): string {
    return '';
  }

  getTextColor(): string {
    return '';
  }
}

