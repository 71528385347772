import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {BaseModel} from './base-model';
import {FormListable} from '../../protocols/form-listable';
import {DateUtils} from '../../../utils/date-utils';

export class PickupTime extends BaseModel implements Deserializable, FormListable {
  public id: number;
  public startTime: string = null;
  public endTime: string = null;
  public cutOffTimeInMinutes: number = 60;
  public deliverBeforeTime: string = null;
  public daysOfWeek: DayOfWeek[] = [];

  itemChanged: boolean = false;
  itemDeleted: boolean = false;
  itemCreated: boolean = false;

  public onDeserialize() {
    super.onDeserialize();
    this.endTime = this.endTime ?? null;
    this.deliverBeforeTime = this.deliverBeforeTime ?? null;
    this.daysOfWeek = DeserializeHelper.deserializeArray(DayOfWeek, this.daysOfWeek);
  }

  getListItemTitle(): string {
    const deliverBefore = this.deliverBeforeTime == null ? '' : $localize` (Deliver before ${DateUtils.convert24HourTo12HourTimeString(this.deliverBeforeTime)}:deliverBeforeTime:)`;
    if (!this.endTime || this.endTime?.length === 0) {
      return $localize`${DateUtils.convert24HourTo12HourTimeString(this.startTime)}:startTime:${deliverBefore}:deliverBeforeTime:`;
    }
    return $localize`${DateUtils.convert24HourTo12HourTimeString(this.startTime)}:startTime: to ${DateUtils.convert24HourTo12HourTimeString(this.endTime)}:endTime:${deliverBefore}:deliverBeforeTime:`;
  }

  getListItemSubtitle(): string {
    return this.daysOfWeek?.map(d => DayOfWeek.getWeekDayShortName(d.dayOfWeekId))?.join(', ') ?? '';
  }

  getEnabledStatus(): boolean {
    return true;
  }

  getSelectionTitle(): string {
    return '';
  }

  getErrorState(): boolean {
    return false;
  }
}

export class DayOfWeek implements Deserializable {
  public dayOfWeekId: DayOfWeekId;
  public dayOfWeek: string;

  static allDayIds(): DayOfWeekId[] {
    return [DayOfWeekId.Sunday,
      DayOfWeekId.Monday,
      DayOfWeekId.Tuesday,
      DayOfWeekId.Wednesday,
      DayOfWeekId.Thursday,
      DayOfWeekId.Friday,
      DayOfWeekId.Saturday];
  }

  static getWeekDayName(dayOfWeekId: DayOfWeekId): string {
    switch (dayOfWeekId) {
      case DayOfWeekId.Sunday:
        return $localize`Sunday`;
      case DayOfWeekId.Monday:
        return $localize`Monday`;
      case DayOfWeekId.Tuesday:
        return $localize`Tuesday`;
      case DayOfWeekId.Wednesday:
        return $localize`Wednesday`;
      case DayOfWeekId.Thursday:
        return $localize`Thursday`;
      case DayOfWeekId.Friday:
        return $localize`Friday`;
      case DayOfWeekId.Saturday:
        return $localize`Saturday`;
    }
  }

  static getWeekDayShortName(dayOfWeekId: DayOfWeekId): string {
    switch (dayOfWeekId) {
      case DayOfWeekId.Sunday:
        return $localize`Sun`;
      case DayOfWeekId.Monday:
        return $localize`Mon`;
      case DayOfWeekId.Tuesday:
        return $localize`Tues`;
      case DayOfWeekId.Wednesday:
        return $localize`Wed`;
      case DayOfWeekId.Thursday:
        return $localize`Thurs`;
      case DayOfWeekId.Friday:
        return $localize`Fri`;
      case DayOfWeekId.Saturday:
        return $localize`Sat`;
    }
  }

  static getWeekDayShortenedName(dayOfWeekId: DayOfWeekId, length: number): string {
    return this.getWeekDayName(dayOfWeekId).substring(0, length);
  }

  onDeserialize() {
  }
}

export enum DayOfWeekId {
  Sunday,
  Monday,
  Tuesday,
  Wednesday,
  Thursday,
  Friday,
  Saturday
}
