/* tslint:disable:max-line-length */
import {HttpBackend, HttpClient, HttpParams} from '@angular/common/http';
import {EMPTY, Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {Deserializable, DeserializeHelper} from '../models/protocols/deserializable';
import {APIRequestType} from '../models/enum/shared/api-request-type.enum';
import {StringifyUtils} from '../utils/stringify-utils';
import {expand, map, reduce} from 'rxjs/operators';

export const DEFAULT_PAGINATION_TAKE = 50;

@Injectable({
    providedIn: 'root'
})
export class ApiClient {
    private backendHttp: HttpClient;

    constructor(
        private http: HttpClient,
        private handler: HttpBackend,
    ) {
        this.backendHttp = new HttpClient(handler);
    }

    public getStr(url: string, additionalHeaders: any = null): Observable<string> {
        return this.http.get<string>(url, {headers: additionalHeaders});
    }

    public getObj<T extends Deserializable>(respObjectType: new () => T, url: string, additionalHeaders: any = null): Observable<T> {
        return this.http.get<T>(url, {headers: additionalHeaders}).pipe(map(r => {
            return DeserializeHelper.deserializeToInstance(respObjectType, r);
        }));
    }

    public getArr<T extends Deserializable>(respObjectType: new () => T, url: string, additionalHeaders: any = null, params: HttpParams = null): Observable<T[]> {        
        return this.http.get<T[]>(url, {headers: additionalHeaders, params}).pipe(map(r => {
            return r?.map(rr => DeserializeHelper.deserializeToInstance(respObjectType, rr)) ?? [] as T[];
        }));
    }

    public recursiveGetArr<T extends Deserializable>(respObjectType: new () => T, route: string, additionalHeaders: any = null, params: HttpParams = new HttpParams(), skip = 0, take = DEFAULT_PAGINATION_TAKE): Observable<T[]> {
        let currentSkip = skip;
        let reqParams = params
            .set('Skip', skip.toString())
            .set('Take', take.toString());

        return this.getArr<T>(respObjectType, route, additionalHeaders, reqParams).pipe(
            expand((res) => {
                if (res && res.length === take) {
                    currentSkip += take;
                    reqParams = reqParams.set('Skip', currentSkip.toString());
                    return this.getArr<T>(respObjectType, route, additionalHeaders, reqParams);
                } else {
                    return EMPTY;
                }
            }),
            reduce((acc, val) => {
                acc.push(...val);
                return acc;
            })
        );
    }

    public getBlob<Blob>(url: string): Observable<Blob> {
        return this.backendHttp.get<Blob>(url, {
            responseType: 'blob' as 'json'
        });
    }

    public putObj<T extends Deserializable>(respObjectType: new () => T, url, payload, additionalHeaders: any = null,
                                            responseType: string = 'json'): Observable<T> {
        return this.http.put<T>(url, JSON.stringify(payload, StringifyUtils.replacer), {
            headers: additionalHeaders,
            responseType: responseType as 'json'
        }).pipe(map(r => {
            return DeserializeHelper.deserializeToInstance(respObjectType, r);
        }));
    }

    public postObj<T extends Deserializable>(respObjectType: new () => T, url, payload, additionalHeaders: any = null,
                                             responseType: string = 'json'): Observable<T> {
        return this.http.post<T>(url, JSON.stringify(payload, StringifyUtils.replacer), {
            headers: additionalHeaders,
            responseType: responseType as 'json'
        }).pipe(map(r => {
            return DeserializeHelper.deserializeToInstance(respObjectType, r);
        }));
    }

    public postArr<T extends Deserializable>(respObjectType: new () => T, url, payload, additionalHeaders: any = null,
                                             responseType: string = 'json'): Observable<T[]> {
        return this.http.post<T[]>(url, JSON.stringify(payload, StringifyUtils.replacer), {
            headers: additionalHeaders,
            responseType: responseType as 'json'
        }).pipe(map(r => {
            return r.map(rr => DeserializeHelper.deserializeToInstance(respObjectType, rr)) as T[];
        }));
    }

    public putArr<T extends Deserializable>(respObjectType: new () => T, url, payload, additionalHeaders: any = null,
                                            responseType: string = 'json'): Observable<T[]> {
        return this.http.put<T[]>(url, JSON.stringify(payload, StringifyUtils.replacer), {
            headers: additionalHeaders,
            responseType: responseType as 'json'
        }).pipe(map(r => {
            return r.map(rr => DeserializeHelper.deserializeToInstance(respObjectType, rr)) as T[];
        }));
    }

    public postMapArr<T extends Deserializable>(respObjectType: new () => T, url, payload, additionalHeaders: any = null,
                                                responseType: string = 'json'): Observable<Map<string, T[]>> {
        return this.http.post<Map<string, T[]>>(url, JSON.stringify(payload, StringifyUtils.replacer), {
            headers: additionalHeaders,
            responseType: responseType as 'json'
        }).pipe(map(r => {
            return DeserializeHelper.deserializeTypedArrayMap(respObjectType, r);
        }));
    }

    public deleteStr(url, payload, additionalHeaders: any = null, responseType: string = 'text'): Observable<string> {
        return this.http.request<string>(APIRequestType.DELETE, url, {
            headers: additionalHeaders,
            body: JSON.stringify(payload, StringifyUtils.replacer),
            responseType: responseType as 'json'
        });
    }

    public deleteObj<T extends Deserializable>(respObjectType: new () => T, url, payload, additionalHeaders: any = null,
                                               responseType: string = 'json'): Observable<T> {
        return this.http.request<T>(APIRequestType.DELETE, url, {
            headers: additionalHeaders,
            body: JSON.stringify(payload, StringifyUtils.replacer),
            responseType: responseType as 'json'
        }).pipe(map(r => {
            return DeserializeHelper.deserializeToInstance(respObjectType, r);
        }));
    }

    public deleteArr<T extends Deserializable>(respObjectType: new () => T, url, payload, additionalHeaders: any = null,
                                               responseType: string = 'json'): Observable<T[]> {
        return this.http.request<T[]>(APIRequestType.DELETE, url, {
            headers: additionalHeaders,
            body: JSON.stringify(payload, StringifyUtils.replacer),
            responseType: responseType as 'json'
        }).pipe(map(r => {
            return r.map(rr => DeserializeHelper.deserializeToInstance(respObjectType, rr)) as T[];
        }));
    }

}

