import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {BaseModel} from './base-model';
import {DatatableData} from '../../protocols/datatable-data';

export class OrderRequirements extends BaseModel implements DatatableData, Deserializable {
  public id: number;
  public requireSignature: boolean = false;
  public requirePhoto: boolean = false;
  public requireNotes: boolean = false;
  public minimumAge: number = null;

  public onDeserialize() {
    super.onDeserialize();
    this.minimumAge = this.minimumAge ?? null;
  }

  getChildrenUniqueIds(): string[] {
    return [];
  }

  getColor(): string {
    return '';
  }

  getTextColor(): string {
    return '';
  }
}
