<div class="modal-body">
    <div class="title-md" [hidden]="logoToUpload">{{titleText}}</div>
    <div class="title-md" [hidden]="!logoToUpload">{{editTitleText}}</div>
    <div class="mt-3 pt-1" [hidden]="logoToUpload">
        <app-upload-asset #uploadContainer
                          *ngIf="(!logoToUpload)"
                          [allowTypes]="['image']"
                          [maxAssets]="1"
                          [parentHandler]="this">
        </app-upload-asset>
    </div>
    <div class="mt-3 pt-1 pb-4" [hidden]="!logoToUpload">
        <image-cropper
                class="image-cropper"
                [imageURL]="logoToUpload"
                [maintainAspectRatio]="true"
                [aspectRatio]="1"
                format="png"
                (imageCropped)="imageCropped($event)"
                (imageLoaded)="imageLoaded($event)"
                (cropperReady)="cropperReady()"
                [roundCropper]="roundedOverlay"
                [hideResizeSquares]="false"
                [transform]="transform"
                (loadImageFailed)="loadImageFailed()">
        </image-cropper>
    </div>
    <div class="footer-button w-100">
        <button class="custom-button destructive-button mr-auto border-0" [hidden]="!logoToUpload" (click)="removeImage()" i18n>Remove Image</button>
        <button class="custom-button neutral-button ml-3" (click)="closeModal()" i18n>Cancel</button>
        <button class="custom-button preferred-button ml-3" [hidden]="!logoToUpload" (click)="apply()" i18n>Apply</button>
    </div>
</div>
