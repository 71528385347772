import { Component } from '@angular/core';
import {BaseIconComponent} from '../base-icon-component/base-icon-component';

@Component({
  selector: 's2d-icon-identification-solid',
  templateUrl: `./identification-solid.html`,
  styleUrls: ['./identification-solid.scss'],
})
export class IdentificationSolidIconComponent extends BaseIconComponent {
}
