import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from '../../../../../models/base/base-component';
import {CustomFile} from '../../../../../models/shared/custom-file';
import {UploadItemInterface} from './upload-item-interface';

@Component({
  selector: 'app-upload-item',
  templateUrl: './upload-item.component.html',
  styleUrls: ['./upload-item.component.scss']
})
export class UploadItemComponent extends BaseComponent
  implements OnInit, AfterViewInit {

  @Input() uploadItemInterface: UploadItemInterface;
  @Input() file: CustomFile;

  constructor(
  ) {
    super();
  }

  ngOnInit(): void {
    this.setupViews()
  }

  ngAfterViewInit(): void {
    this.setupBindings()
  }

  setupBindings() {
  }

  setupViews() {
  }

  removeMe(): void {
    this.uploadItemInterface.removeMe(this.file);
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

}
