import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Optional,
  Self,
  SimpleChanges,
} from '@angular/core';
import { NgControl, Validators } from '@angular/forms';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import { StdControlValueAccessor } from '../std-control-value-accessor';
import { MatFormFieldControl } from '@angular/material/form-field';

@Directive({
  host: {
    '(focusin)': 'onFocusIn($event)',
    '(focusout)': 'onFocusOut($event)',
    '(focus)': '_focusChanged(true)',
    '(blur)': '_onTouched(false)',
  },
})
export abstract class StdBaseFormFieldInput<T>
  implements
    MatFormFieldControl<T>,
    StdControlValueAccessor,
    OnDestroy,
    OnChanges
{
  public readonly destroy: Subject<void> = new Subject();
  @Input() public placeholder: string;
  public touched: boolean = false;
  public shouldLabelFloat: boolean;
  public stateChanges: Subject<void> = new Subject<void>();
  public userAriaDescribedBy: string;
  public autofilled: boolean;
  public abstract controlType: string;
  public empty: boolean;
  public errorState: boolean;
  public focused: boolean;
  public id: string;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    public _elementRef: ElementRef
  ) {
    // this prevents circular dependency errors
    const element = _elementRef.nativeElement as HTMLElement;
    element.classList.remove('form-control');
    element.classList.remove('custom-select');
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  private _value;

  get value(): T | null {
    return this._value;
  }

  set value(value: T | null) {
    this._value = value;
    this.stateChanges.next();
  }

  protected _disabled = false;

  get disabled(): boolean {
    return (this._disabled || this?.ngControl.disabled) ?? false;
  }

  @Input()
  set disabled(value: BooleanInput) {
    this._disabled = coerceBooleanProperty(value);
    // Browsers may not fire the blur event if the input is disabled too quickly.
    // Reset from here to ensure that the element doesn't become stuck.
    if (this.focused) {
      this.focused = false;
      this.stateChanges.next();
    }
  }

  _required: boolean;

  @Input()
  get required(): boolean {
    return (
      this._required ??
      this.ngControl?.control?.hasValidator(Validators.required) ??
      false
    );
  }

  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
  }

  _onTouched = () => {};

  _onChange = (_) => {};

  registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  change($event: Event) {
    this.value = ($event.target as HTMLInputElement).value as any;
    this._onChange(this.value);
    this.stateChanges.next();
  }

  writeValue(obj: any): void {
    this.value = obj;
  }

  markAsTouched() {
    this.touched = true;
    this._onTouched();
    this.stateChanges.next();
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.destroy.next();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.stateChanges.next();
  }

  onContainerClick(event: MouseEvent): void {}

  setDescribedByIds(ids: string[]): void {}

  onFocusIn(event: FocusEvent) {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (
      !this._elementRef.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.touched = true;
      this.focused = false;
      this._onTouched();
      this.stateChanges.next();
    }
  }
}
