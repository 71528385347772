import * as IMask from 'imask';

export class PhoneUtils {
  static validPhoneNumberRegex(): RegExp {
    return new RegExp('^((\\+?)[-. ]?([0-9]))?[-. ]?\\(?([0-9]{3})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$');
  }
  static generatePhoneNumberMask(): IMask.Masked<any> {
    return IMask.createMask({
      mask: '{1} (000) 000-0000',
    });
  }
  static formatPhoneNumber(phone: string): string {    
    if (!phone) { return '' }
    const phoneMask = this.generatePhoneNumberMask();
    phoneMask.unmaskedValue = phone.substr(1);
    return phoneMask.value ?? '';
  }
}
