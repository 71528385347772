<app-loading
        *ngIf="loadingOpts?.isLoading"
  [options]="loadingOpts">
</app-loading>
<div [hidden]="isHidden">
  <div (fileDropped)="onFileDropped($event)"
       [hidden]="!allowTypes"
       [ngClass]="{ 'animating' : animating }"
       appDnd
       class="custom-upload-area dark-animation">
    <form class="custom-upload-form">
      <img src="/assets/icons/dark/solid/manage.svg"
           class="custom-upload-icon"
           alt="manage">
      <span class="custom-upload-text">
        <label class="custom-upload-label" for="fileDropRef{{id}}">Upload a File</label> or Drag and Drop</span>
      <span class="custom-upload-text secondary-upload-text">
         {{viewModel.acceptTypesDescription}} up to 5MB
      </span>
      <input type="file" #fileDropRef
             class="custom-file-input"
             id="fileDropRef{{id}}"
             name="fileDropRefName{{id}}"
             multiple
             [accept]="viewModel.accept"
             (change)="viewModel.fileBrowseHandler($event.target)">
    </form>
  </div>
  <div [ngStyle]="{ 'padding' : displayList ? '1.25rem 0' : '1rem 0' }"
       class="custom-upload-list-container">
    <ul *ngIf="viewModel.files.length > 0 && displayList"
        class="custom-upload-list">
      <app-upload-item
        *ngFor="let item of viewModel.files"
        [uploadItemInterface]="viewModel"
        [file]="item">
      </app-upload-item>
    </ul>
  </div>
</div>
