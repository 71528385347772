import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

import {DefaultLayoutComponent} from "./default-layout.component"

@Injectable({
  providedIn: 'root',
})
export class DefaultLayoutService {
  public noPadding$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  public sidebarMinimized = false

  constructor() {}

  changeSidebarVisibility(sidebarMinimized = false){
    this.sidebarMinimized = sidebarMinimized
    // this.sidebarMinimized = sidebarMinimized
  }
}
