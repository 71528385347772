import {ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {TabBarRouterItem} from '../../../../../models/shared/stylesheet/tab-bar-router-item';
import {NavigationEnd, Router} from '@angular/router';
import {BaseComponent} from '../../../../../models/base/base-component';
import {MatTabNav} from "@angular/material/tabs";
import '../../../../../utils/subscription.extensions'

@Component({
    selector: 'app-tab-bar-routing',
    templateUrl: './tab-bar-routing.component.html',
    styleUrls: ['./tab-bar-routing.component.scss'],
})
export class TabBarRoutingComponent extends BaseComponent {
    @Input() public tabs: TabBarRouterItem[];
    @Input() public useRouter: boolean = false;
    @Input() public currentTab: TabBarRouterItem;
    @Output() public tabClicked: EventEmitter<TabBarRouterItem> = new EventEmitter<TabBarRouterItem>();
    @ViewChild('tabNav') tabNav: MatTabNav;
    constructor(protected cdr: ChangeDetectorRef,
                private router: Router,
    ) {
        super();
    }
    setupBindings() {
        // this is to fix the inkbar not showing on page loads
        this.tabNav._alignInkBarToSelectedTab();
        if(this.useRouter) {
            this.router.events.subscribe(e => {
                if (e instanceof NavigationEnd) {
                    this.currentTab = this.tabs.find(t => e.url.endsWith(t.routerLink));
                    this.cdr.detectChanges();

                }
            }).addTo(this.subscriptions);
        }
    }
    setupViews() {
        if(this.useRouter) {
            this.currentTab = this.tabs.find(t => this.router.url.endsWith(t.routerLink));
        }
    }
    clickTab(tab: TabBarRouterItem) {
        this.tabClicked.next(tab);
    }
}
