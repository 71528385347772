import {Deserializable} from '../../protocols/deserializable';
import {DateUtils} from '../../../utils/date-utils';
import {UserSession} from "../../../api/interfaces/user-session";

export class Session implements Deserializable, UserSession {
  public accessToken: string;
  public expiresIn: number;
  public idToken: string;
  public refreshToken: string;
  public tokenType: string;

  private expiresAt: number;

  public onDeserialize() {
    // Set expires at value
    this.expiresAt = DateUtils.currentTimestamp() + this.expiresIn;
  }

  public validSession(): boolean {
    return this.accessToken && this.expiresAt > DateUtils.currentTimestamp();
  }

  public sessionNeedsRefresh(): boolean {
    const refreshSessionBufferTime = 60 * 10; // 10 minutes
    return !this.accessToken || !((this.expiresAt - refreshSessionBufferTime) > DateUtils.currentTimestamp());
  }

}
