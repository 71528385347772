export enum RolePermission {
    ViewBillingTab                  = 1,
    ViewEmployeesTab                = 2,
    ViewLocationsTab                = 3,
    ViewRolesTab                    = 4,
    ViewAllLocations                = 5,
    ViewOrdersTab                   = 6,
    ViewAdminsTab                   = 7,
    ViewCompaniesTab                = 8,
    ViewDriversTab                  = 9,
    ViewTaxesTab                    = 10,
    ViewClientAlertsTab             = 11,
    ViewCreateOrdersTab             = 12,
    ViewManageOrdersTab             = 13,
    ViewDeliveriesTab               = 14,
    ViewCompensationTab             = 15,
    ViewFuelSurchargesTab           = 16,
    ViewEmailLogsTab                = 13,
    ViewAdminReport                 = 17,
    ViewAdminEmailLogsTab           = 18,
    ViewSMSLogsTab                  = 13,
    ViewAdminSMSLogsTab             = 18,
    ViewAdminDashboardTab           = 19,
}
