import { Component } from '@angular/core';
import {BaseIconComponent} from '../base-icon-component/base-icon-component';

@Component({
  selector: 's2d-icon-identification-outline',
  templateUrl: `./identification-outline.html`,
  styleUrls: ['./identification-outline.scss'],
})
export class IdentificationOutlineIconComponent extends BaseIconComponent {
}
