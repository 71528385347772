import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {BaseModel} from './base-model';
import {DatatableData} from '../../protocols/datatable-data';
import {Selectable} from '../../protocols/selectable';
import {AssignedPermission} from './assigned-permission';

export class Role extends BaseModel implements DatatableData, Deserializable, Selectable {
  public id: number;
  public companyId: number;
  public name: string = '';
  public permissions: AssignedPermission[] = [];
  public enabled: boolean = true;
  public administrator: boolean = false;

  public onDeserialize() {
    super.onDeserialize();
    this.permissions = DeserializeHelper.deserializeArray(AssignedPermission, this.permissions);
  }

  getChildrenUniqueIds(): string[] {
    return [];
  }

  getColor(): string {
    return '';
  }

  getTextColor(): string {
    return '';
  }

  getSelectionTitle(): string {
    return this.name;
  }

  getSelectionUniqueIdentifier(): any {
    return this.id;
  }

  getSelectionValue(): any {
    return this.id;
  }
}

