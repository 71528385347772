import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { BodyComponent } from './body/body.component';
import { FooterComponent } from './footer/footer.component';
import { StdPrefix } from './prefix/std-prefix.directive';
import { StdSuffix } from './suffix/std-suffix.directive';
import { SpacerComponent } from './spacer/spacer.component';
import { FormRowComponent } from './form-row/form-row.component';
import { PlaceholderComponent } from './placeholder/placeholder.component';
import { FormGroupComponent } from './form-group/form-group.component';
import { ModalComponent } from './modal/modal.component';
import { StdAppContainerComponent } from './app-container/std-app-container.component';

@NgModule({
  declarations: [
    HeaderComponent,
    BodyComponent,
    FooterComponent,
    StdSuffix,
    StdPrefix,
    SpacerComponent,
    FormRowComponent,
    PlaceholderComponent,
    FormGroupComponent,
    ModalComponent,
    StdAppContainerComponent,
  ],
  exports: [
    HeaderComponent,
    BodyComponent,
    FooterComponent,
    StdSuffix,
    StdPrefix,
    SpacerComponent,
    FormRowComponent,
    PlaceholderComponent,
    FormGroupComponent,
    ModalComponent,
    StdAppContainerComponent,
  ],
  imports: [CommonModule],
})
export class StdLayoutModule {}
