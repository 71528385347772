import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {DatatableData} from '../../protocols/datatable-data';
import {BaseModel} from './base-model';
import {environment} from '../../../../environments/environment';
import {Session} from "./session";
import {AuthChallengeType} from "../../../views/login/auth-challenge-type";
import { Destination } from './destination';
import { PhoneUtils } from 'src/app/utils/phone-utils';

export class CompanySMSLogs extends BaseModel implements DatatableData, Deserializable {
  public id: number;
  public companyId: number;
  public locationId: number;
  public orderId: string
  public order: any
  public address: any;
  public addressId:string;
  public recipientId: string;
  public recipient: any;
  public smsTemplateTypeId: number;
  public smsTemplateType:any;
  public content:string;
  public subjectLine:string;
  public fromPhone:string;
  public toPhone:string;
  public errorLog:string;
  public createdBy: string;
  public createdDate: Date;
  public updatedBy:string;
  public updatedDate:Date;
  public status:boolean
  public location:any;

  get recipientPhoneNo(): string {   
    if (!this.order?.recipient?.phone) {
      return null;
    }
    return PhoneUtils.formatPhoneNumber(this.order?.recipient?.phone);
  }

  get formattedFromPhoneNo(): string {
    if (!this.fromPhone) {
      return null;
    }
    return PhoneUtils.formatPhoneNumber(this.fromPhone);
  }

  get formattedToPhoneNo(): string {
    if (!this.toPhone) {
      return null;
    }
    return PhoneUtils.formatPhoneNumber(this.toPhone);
  }



  public onDeserialize() {
    super.onDeserialize();
   
  }

  public get isDriver(): boolean {
    return this.companyId === environment.driverCompanyId;
  }

  public get isAdmin(): boolean {
    return this.companyId === environment.adminCompanyId;
  }

  public get isClient(): boolean {
    return !this.isAdmin && !this.isDriver;
  }

 

  getChildrenUniqueIds(): string[] {
    return [];
  }

  getColor(): string {
    return '';
  }

  getTextColor(): string {
    return '';
  }
}

