import {Directive, ElementRef, InjectionToken} from '@angular/core';
export const STD_SUFFIX: InjectionToken<StdSuffix> = new InjectionToken<StdSuffix>('StdSuffix');
@Directive({
  selector: `
  [stdSuffix],
  [stdSuffixBtn]
  `,
  providers: [{provide: STD_SUFFIX, useExisting: StdSuffix}],
})
export class StdSuffix {
  public _isButton: boolean = false;
  constructor(elementRef: ElementRef) {
    this._isButton = elementRef.nativeElement.hasAttribute('stdSuffixBtn');
  }
}
