import { Component } from '@angular/core';
import {BaseIconComponent} from '../base-icon-component/base-icon-component';

@Component({
  selector: 's2d-icon-currency-dollar-solid',
  templateUrl: `./currency-dollar-solid.html`,
  styleUrls: ['./currency-dollar-solid.scss'],
})
export class CurrencyDollarSolidIconComponent extends BaseIconComponent {
}
