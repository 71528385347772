import { Component } from '@angular/core';
import {BaseIconComponent} from '../base-icon-component/base-icon-component';

@Component({
  selector: 's2d-icon-view-grid-solid',
  templateUrl: `./view-grid-solid.html`,
  styleUrls: ['./view-grid-solid.scss'],
})
export class ViewGridSolidIconComponent extends BaseIconComponent {
}
