import {Component, Input, OnInit} from '@angular/core';
import {NgxSpinnerService} from 'ngx-spinner';
import {LoadingOptions} from '../../../../models/shared/loading-options';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {

  @Input() options: LoadingOptions = LoadingOptions.default();

  constructor(
    private spinner: NgxSpinnerService
  ) {
  }

  ngOnInit(): void {
    if (!this.options.backgroundColor || this.options.backgroundColor === '') {
      this.options.backgroundColor = 'rgba(222, 222, 222, 0.9)';
    }
    if (!this.options.color || this.options.color === '') {
      this.options.color = '#2C4058';
    }
    if (!this.options.spinnerColor || this.options.spinnerColor === '') {
      this.options.spinnerColor = this.options.color;
    }
    this.spinner.show();
  }

}
