import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {AbstractChallenge} from './abstract.challenge';
import {Observable} from "rxjs";
import {AuthChallengeType} from "../../auth-challenge-type";
import {NewPasswordRequest} from "../../../../models/account/requests/new-password-request";
import {AccountAPI} from "../../../../api/account-api";
import {User} from "../../../../models/account/dto/user";
import {environment} from "../../../../../environments/environment";

@Injectable({
    providedIn: "root"
})
export class NewPasswordChallenge extends AbstractChallenge {
    public challengeType: AuthChallengeType = 'NEW_PASSWORD_REQUIRED';
    public newPasswordRequest: NewPasswordRequest;
    constructor(public router: Router,
                private accountApi: AccountAPI
    ) {
        super();
    }
    handle(user: User): Observable<User> {
        let portal = null;
        if(user.companyId ===  environment.adminCompanyId) {
            portal = "admin";
        }
        if(user.companyId ===  environment.driverCompanyId) {
            portal = "driver";
        }
        this.router.navigate(['/new-password'], {queryParams: {portal}});
        this.newPasswordRequest = {
            password: null,
            companyId: user.companyId,
            email: user.email,
            sessionId: user.sessionId,
            challengeName: this.challengeType
        };
        return this.user;
    }

    submit(req: NewPasswordRequest): Observable<User> {
        return this.accountApi.submitNewPassword(req);
    }
}
