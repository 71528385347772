import { Component } from '@angular/core';
import {BaseIconComponent} from '../base-icon-component/base-icon-component';

@Component({
  selector: 's2d-icon-sent-email-outline',
  templateUrl: `./sent-email-outline.html`,
  styleUrls: ['./sent-email-outline.scss'],
})
export class SentEmailOutLine extends BaseIconComponent {
}
