import {Component, ViewEncapsulation} from '@angular/core';


@Component({
    selector: 'std-required-marker',
    templateUrl: './required-marker.component.html',
    styleUrls: ['./required-marker.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class RequiredMarkerComponent {

}
