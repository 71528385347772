/* tslint:disable:no-string-literal */
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {SessionService} from '../services/session-service';
import {SignInRequest} from '../models/account/requests/sign-in-request';
import {AccountAPI} from '../api/account-api';
import {catchError, first, map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {SessionContainer} from '../models/shared/session-container';
import {CacheService} from '../services/cache-service';
import {DefaultCacheKey} from '../models/enum/shared/default-cache-key.enum';
import {BaseDomainModel} from '../models/base/base-domain-model';
import {Session} from '../models/account/dto/session';
import {ImageAPI} from '../api/image-api';
import {CachePolicy} from '../models/enum/shared/cachable-image-policy.enum';
import {ResetPasswordRequest} from '../models/account/requests/reset-password-request';
import {ResetPasswordResponse} from '../models/account/dto/reset-password-response';
import {UserApi} from '../api/user-api';
import {AssetSize} from '../models/enum/dto/image-size.enum';
import {SafeResourceUrl} from '@angular/platform-browser';
import {ZendeskToken} from '../models/account/dto/zendesk-token';
import {User} from "../models/account/dto/user";
import {Router} from "@angular/router";
import {MfaChallenge} from "../views/login/components/challenges/mfa-challenge";
import {NewPasswordChallenge} from "../views/login/components/challenges/new-password-challenge";
import {SetupMfaChallenge} from "../views/login/components/challenges/scan-qr-challenge.service";
import {
    UserAgreementModalComponent
} from "../views/login/components/user-agreement-modal/user-agreement-modal.component";
import {ModalUtils} from "../utils/modal-utils";
import {
    PrivacyPolicyModalComponent
} from "../views/login/components/privacy-policy-modal/privacy-policy-modal.component";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

export interface SignInEvent {
    user: User;
    rememberMe: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class AccountDomainModel extends BaseDomainModel {

    public refreshSessionResult: BehaviorSubject<Session> = new BehaviorSubject<Session>(null);
    public sessionContainer$ = this.session.sessionContainer;
    public signInEventSubject: Subject<SignInEvent> = new Subject<SignInEvent>();
    rememberMe: boolean = false;
    constructor(
      public session: SessionService,
      private accountApi: AccountAPI,
      private userApi: UserApi,
      private imageApi: ImageAPI,
      private cacheService: CacheService,
      private window: Window,
      private router: Router,
      private mfaChallenge: MfaChallenge,
      private newPasswordChallenge: NewPasswordChallenge,
      private setupMfaChallenge: SetupMfaChallenge,
      private modalService: NgbModal,
    ) {
        super();
        this.init();
    }

    public init() {
        this.setupBindings();
        this.session.getCachedSession()
    }

    public isAuthenticated(forceRefresh: boolean = false): Observable<Session> {
        // get session container
        let sess: SessionContainer = this.session.sessionContainer.getValue() as SessionContainer;
        if (sess === null || !sess) {
            sess = this.session.getCachedSession();
        }
        // Check session validity and refresh
        if (sess !== null && !sess?.sessionNeedsRefresh() && !this.session.refreshingSession.getValue() && !forceRefresh) {
            // Valid Session
            return of(sess.user.userSession);
        } else {
            // Attempt to refresh session
            return new Observable<Session>(observer => {
                if (this.session.refreshingSession.getValue()) {
                    this.refreshSessionResult.firstNotNull().subscribe(observer);
                } else {
                    this.session.refreshingSession.next(true);
                    const req = this.session.getRefreshSessionReq(sess);
                    if (req) {
                        const companyId =  this.session.getUser()?.companyId;
                        const email =  this.session.getUser()?.email;
                        this.accountApi.refreshSession(companyId, email, req).subscribe((refreshResponse) => {
                            const remember = this.session.sessionContainer.getValue()?.rememberSession;
                            const user = this.session.getUser();
                            const userRole = this.session.getRole();
                            user.userSession = refreshResponse.userSession;
                            this.session.setUser(user, userRole, true, remember);
                            setTimeout(() => {
                                // Add small delay so the session can set before continuing API calls.
                                this.session.refreshingSession.next(false);
                                this.refreshSessionResult.next(user.userSession);
                                observer.next(user.userSession);
                            }, 100);
                        }, () => {
                            this.session.refreshingSession.next(false);
                            this.refreshSessionResult.next(null);
                            this.session.destroySession.next(true);
                            observer.next(null);
                            this.router.navigate(['/'])
                        });
                    } else {
                        this.session.refreshingSession.next(false);
                        this.refreshSessionResult.next(null);
                        observer.next(null);
                    }
                }
            });
        }
    }

    // Auth Methods

    public signIn(companyId: number, email: string, rememberMe: boolean, req: SignInRequest): Observable<User> {
        return this.accountApi.signIn(companyId, email, req);
    }

    public signOut(){
        const user = this.session.getUser();
        this.accountApi.signOut(user?.companyId, user?.email).subscribe(() => {
        });
        // Dont wait for sign out response, just kill the session
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer);
        this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer, CachePolicy.Persistent);
        this.session.destroySession.next(true);
        if (user.isAdmin) {
            this.router.navigate(['./admin']);
        } else if (user.isDriver) {
            this.router.navigate(['./driver']);
        } else {
            this.router.navigate(['/']);
        }
    }

    public startSignInProcess(event: Observable<SignInEvent>) {
        let rememberMe = false;
        return event
    }

    private setupBindings() {
        // Bind to destroy session
        const destroySess = this.session.destroySession.notNull().subscribe((_) => {
            this.session.refreshingSession.next(false);
            this.refreshSessionResult.next(null);
        });
        this.pushSub(destroySess);
        this.signInEventSubject.pipe(
            takeUntil(this.onDestroy),
            catchError(e => {
                return of(null);
            }),
            switchMap(event => {
                const user = event.user;
                this.rememberMe = event.rememberMe;
                if(user?.challengeName === "NEW_PASSWORD_REQUIRED") {
                    return this.newPasswordChallenge.handle(user);
                }
                return of(user);
            }),
            switchMap(user => {
                if(user?.challengeName === "MFA_SETUP_REQUIRED") {
                    return this.setupMfaChallenge.handle(user);
                }
                return of(user);
            }),
            switchMap(user => {
                if(user?.challengeName === "SOFTWARE_TOKEN_MFA") {
                    return this.mfaChallenge.handle(user);
                }
                return of(user);
            }),
            switchMap(user => {
                if (!user || !user?.userSession) {
                    return of(null);
                }
                return this.userApi.getRoleForSignIn(user.companyId, user.roleId, user.userSession.accessToken)
                    .pipe(map(role => {
                        // Clear existing session from cache
                        this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer);
                        this.cacheService.removeCachedObject(DefaultCacheKey.SessionContainer, CachePolicy.Persistent);
                        // Set the new user in the session
                        this.session.setUser(user, role, true, this.rememberMe);
                        return user;
                    }))
            }),
            switchMap(user => {
                return this.showEula(user);
            }),
            switchMap((user) => {
                if (user && !user?.eulaConfirmation) {
                    user.eulaConfirmation = true;
                    return this.userApi.updateUser(user)
                        .pipe(map(response => user));
                }
                return of(user);
            })
        )
        .subscribe((user) => {
            if (user === null) {
                this.session.destroySession.next(true);
            }
            if (user.isAdmin) {
                this.router.navigate(['/search-orders'])
            } else {
                this.router.navigate(['/']);
            }
        });
    }

    public sendPasswordResetCode(companyId: number, email: string): Observable<string> {
        return this.accountApi.getPasswordResetCode(companyId, email);
    }

    public resetPassword(companyId: number, email: string, req: ResetPasswordRequest): Observable<ResetPasswordResponse> {
        return this.accountApi.resetPassword(companyId, email, req);
    }

    public getCompanyLogo(): Observable<string | SafeResourceUrl> {
        return this.session.sessionContainer.pipe(switchMap(s => {
            return this.imageApi.getCompanyImage(s.user.companyId, AssetSize.Medium,false);
        }), first());
    }

    getUser(): User {
        return this.session.getUser();
    }

    public showEula(user: User): Observable<User> {
        if (user.eulaConfirmation) {
            return of(user);
        }
        return this.modalService.open(UserAgreementModalComponent, ModalUtils.defaultModalOptions())
            .closed
            .pipe(switchMap(result => {
                if (!result) {
                    return of(null);
                }
                return this.modalService.open(PrivacyPolicyModalComponent, ModalUtils.defaultModalOptions())
                    .closed
                    .pipe(map(response => {
                        if (!response) {
                            return null;
                        }
                        return user;
                    }))
            }));
    }
}
