import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {BaseError} from '../models/shared/base-error';
import {StdError} from "../std-forms/errors/std-error";

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(
    private toastr: ToastrService
  ) {
  }

  publishError(err: BaseError | StdError) {
    if (err.message !== 'Invalid Token!') {
      this.toastr.error(err.message, err.title);
    }
  }

  publishErrorMessage(mess, title: string = null) {
    this.toastr.error(mess, title);
  }

  publishSuccessMessage(mess, title: string = null) {
    this.toastr.success(mess, title);
  }

  publishInfoMessage(mess, title: string = null) {
    this.toastr.info(mess, title);
  }

  publishWarningMessage(mess, title: string) {
    this.toastr.warning(mess, title);
  }
}
