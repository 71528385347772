import {Injectable} from '@angular/core';
import {LoggableAPI} from '../models/protocols/loggable-api';
import {ApiClient} from './api-client';
import {Observable} from 'rxjs';
import {Endpoints} from './endpoints';
import {User} from '../models/account/dto/user';
import {Role} from '../models/account/dto/role';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ChangePasswordRequest} from '../models/account/requests/change-password-request';
import {environment} from '../../environments/environment';
import { CompanyEmailLogs } from '../models/account/dto/company-email-logs';
import { CompanyUnsubscribedEmail } from '../models/account/dto/compnay-unsubscribed-email';
import { CompanyDetailsEmailLogs } from '../models/account/dto/company-details-email-logs';
import { CompanyUnsubscribedEmailDetails } from '../models/account/dto/company-unSubscribed-details';
import { unsubscribedEmailResponse } from '../models/account/response/unsubscribedEmailResponse';
import { ApiResponse } from '../models/account/response/ApiResponse';
import { UnsubscribeEmailRequest } from '../models/account/requests/UnsubscribeEmailRequest';
import { UnsubscribeLocation } from '../models/account/response/UnsubscribeLocation';
import { CompanyDetailsSMSLogs } from '../models/account/dto/company-details-sms-logs';
import { CompanySMSLogs } from '../models/account/dto/company-sms-log';
import { CompanyUnsubscribedSMSDetails } from '../models/account/dto/company-unsubscribed-sms-details';
import { CompanyUnsubscribedSMS } from '../models/account/dto/compnay-unsubscribed-sms';
import { UnsubscribeSMSRequest } from '../models/account/requests/unsubscribe-sms-request';
import { unsubscribedSMSResponse } from '../models/account/response/unsubscribedSMSResponse';

@Injectable({
  providedIn: 'root',
})
export class UserApi implements LoggableAPI {

  constructor(
    private apiClient: ApiClient,
    private http: HttpClient,
  ) {
  }

  // Variables
  public serviceName = 'Users';

  public getCompanyUsers(companyId: number): Observable<User[]> {
    const url = Endpoints.getCompanyUsers(companyId);
    return this.apiClient.getArr(User, url);
  }
  public getCompanyEmailLogs(companyId: number): Observable<CompanyEmailLogs[]> {
    const url = Endpoints.getCompanyEmailLogs(companyId);
    return this.apiClient.getArr(CompanyEmailLogs, url);
  }

  public getCompanySMSLogs(companyId: number): Observable<CompanySMSLogs[]> {
    const url = Endpoints.getCompanySMSLogs(companyId);
    return this.apiClient.getArr(CompanySMSLogs, url);
  }

  public getCompanyDetailsEmailLogs(companyId: number, locationId:number, emailLogsId:number): Observable<CompanyDetailsEmailLogs> {
    const url = Endpoints.getCompanyDetailsEmailLogs(companyId,locationId,emailLogsId);
    return this.apiClient.getObj(CompanyDetailsEmailLogs, url);
  }

  public getCompanyDetailsSMSLogs(companyId: number, locationId:number, smsLogsId:number): Observable<CompanyDetailsSMSLogs> {
    const url = Endpoints.getCompanyDetailsSMSLogs(companyId,locationId,smsLogsId);
    return this.apiClient.getObj(CompanyDetailsSMSLogs, url);
  }

  public getCompanyUnSubscribedEmail(companyId: number): Observable<CompanyUnsubscribedEmail[]> {
    const url = Endpoints.getCompanyUnSubscribedEmail(companyId);
    return this.apiClient.getArr(CompanyUnsubscribedEmail, url);
  }
  public getCompanyUnSubscribedSMS(companyId: number): Observable<CompanyUnsubscribedSMS[]> {
    const url = Endpoints.getCompanyUnSubscribedSMS(companyId);
    return this.apiClient.getArr(CompanyUnsubscribedSMS, url);
  }
  public getCompanyUnsubscribedDetails(companyId: number,  unSubscribeDetailsId:number): Observable<CompanyUnsubscribedEmailDetails> {
    const url = Endpoints.getCompanyUnsubscribedDetails(companyId,unSubscribeDetailsId);
    return this.apiClient.getObj(CompanyUnsubscribedEmailDetails, url);
  }
  public getCompanyUnsubscribedSMSDetails(companyId: number,  unSubscribeDetailsId:number): Observable<CompanyUnsubscribedSMSDetails> {
    const url = Endpoints.getCompanyUnsubscribedSMSDetails(companyId,unSubscribeDetailsId);
    return this.apiClient.getObj(CompanyUnsubscribedSMSDetails, url);
  }
  public resubscribedEmail(companyId: number, locationId:number, unSubscribeDetailsId:number): Observable<CompanyUnsubscribedEmailDetails> {
    const url = Endpoints.resubscribedEmail(companyId,locationId,unSubscribeDetailsId);
    return this.apiClient.deleteObj(CompanyUnsubscribedEmailDetails,url,"");
  }

  public resubscribedSMS(companyId: number, locationId:number, unSubscribeDetailsId:number): Observable<CompanyUnsubscribedSMSDetails> {
    const url = Endpoints.resubscribedSMS(companyId,locationId,unSubscribeDetailsId);
    return this.apiClient.deleteObj(CompanyUnsubscribedSMSDetails,url,"");
  }

  public getCompanyUnsubcribePageDetail(companyId: number,  locationId:number): Observable<UnsubscribeLocation> {
    const url = Endpoints.getCompanyUnsubcribePageDetail(companyId,locationId);
    return this.apiClient.getObj(UnsubscribeLocation, url);
  }

  public unsubcribeFromEmail(request:UnsubscribeEmailRequest): Observable<ApiResponse<any>> {
    const url = Endpoints.unsubcribeFromEmail(request.companyId,request.locationId);
    return this.apiClient.postObj(unsubscribedEmailResponse, url,request);
  }

  public unsubcribeFromSMS(request:UnsubscribeSMSRequest): Observable<ApiResponse<any>> {
    const url = Endpoints.unsubcribeFromSMS(request.companyId,request.locationId);
    return this.apiClient.postObj(unsubscribedSMSResponse, url,request);
  }


  public getUser(companyId: number, userId: number): Observable<User> {
    const url = Endpoints.getCompanyUser(companyId, userId);
    return this.apiClient.getObj(User, url);
  }

  public updateUser(user: User): Observable<User> {
    const url = user.companyId === environment.driverCompanyId ?
      Endpoints.updateDriver(user.id) :
      Endpoints.updateCompanyUser(user.companyId, user.id);
    return this.apiClient.putObj(User, url, user);
  }

  public getRoles(companyId: number): Observable<Role[]> {
    const url = Endpoints.getCompanyRoles(companyId);
    return this.apiClient.getArr(Role, url);
  }

  public getRoleForSignIn(companyId: number, roleId: number, authToken: string): Observable<Role> {
    const url = Endpoints.getRoleById(companyId, roleId);
    const headers = new HttpHeaders({authorization: `Bearer ${authToken}`});
    return this.http.get<Role>(url, {headers});
  }

  public createUser(user: User): Observable<User> {
    const url = Endpoints.createCompanyUser(user.companyId);
    return this.apiClient.postObj(User, url, user);
  }

  public updateRole(role: Role): Observable<Role> {
    const url = Endpoints.updateCompanyRole(role.companyId, role.id);
    return this.apiClient.putObj(Role, url, role);
  }

  public createRole(role: Role): Observable<Role> {
    const url = Endpoints.createCompanyRole(role.companyId);
    return this.apiClient.postObj(Role, url, role);
  }

  public changeUserPassword(user: User, changePasswordRequest: ChangePasswordRequest): Observable<ChangePasswordRequest> {
    const url = Endpoints.updateCompanyUserPassword(user.companyId, user.email);
    return this.apiClient.postObj(ChangePasswordRequest, url, changePasswordRequest);
  }
}
