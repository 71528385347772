import {BrowserModule} from '@angular/platform-browser';
import {InjectionToken, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {DefaultLayoutComponent} from './views/default/default-layout/default-layout.component';
import {AppAsideModule, AppSidebarModule} from '@coreui/angular';
import {SidebarNavComponent} from './views/default/sidebar-nav/sidebar-nav.component';
import {NgbActiveModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {ToastrModule} from 'ngx-toastr';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {AuthInterceptorInterceptor} from './services/interceptors/auth-interceptor.interceptor';
import {SharedModule} from './views/shared/shared.module';
import {RouteReuseStrategy} from '@angular/router';
import {CacheRouteReuseStrategy} from './services/strategy/cache-route-reuse.strategy';
import {NgxPopperModule} from 'ngx-popper';
import {SidebarNavViewModel} from './views/default/sidebar-nav/sidebar-nav-view-model';
import {SpinnerModule} from "./spinner/spinner.module";
import {MAT_AUTOCOMPLETE_DEFAULT_OPTIONS} from "@angular/material/autocomplete";
import {DEFAULT_DIALOG_CONFIG} from "@angular/cdk/dialog";
import {DefaultScrollIntoViewOptions, SCROLL_INTO_VIEW_OPTIONS} from "./std-components/std-components.module";
import { StoredataService } from './services/storeDataService.ts/storedata.service';
import { EmailLogUnSubscribeComponent } from './views/company-email-logs-unsubscribe/company-email-logs-unsubscribe';
import { SMSLogUnSubscribeComponent } from './views/company-sms-logs-unsubscribe/company-sms-logs-unsubscribe';

const APP_CONTAINERS = [
    DefaultLayoutComponent
];
export type Portals = "admin" | "client" | "driver"

export interface PortalConfiguration {
    portal: Portals;
}

export const PORTAL_CONFIG: InjectionToken<PortalConfiguration> = new InjectionToken<PortalConfiguration>('config')

@NgModule({
    declarations: [
        AppComponent,
        DefaultLayoutComponent,
        ...APP_CONTAINERS,
        SidebarNavComponent,
        EmailLogUnSubscribeComponent,
        SMSLogUnSubscribeComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        AppSidebarModule,
        AppAsideModule,
        HttpClientModule,
        BsDropdownModule.forRoot(),
        ToastrModule.forRoot({
            closeButton: true,
            timeOut: 5000,
            positionClass: 'toast-top-right',
            preventDuplicates: true,
            resetTimeoutOnDuplicate: true,
            maxOpened: 5,
            toastClass: 'custom-toast ngx-toastr'
        }),
        NgxPopperModule.forRoot({
            applyClass: 'custom-popper-content',
            applyArrowClass: 'd-none',
            hideOnClickOutside: true,
            hideOnScroll: true,
            preventOverflow: false,
            positionFixed: true,
            trigger: 'click',
            placement: 'bottom-end',
            styles: {
                'background-color': '#FFFFFF'
            },
            popperModifiers: {
                flip: {
                    behavior: ['right', 'bottom', 'top']
                }
            }
        }),
        SharedModule,
        NgbModule,
        SpinnerModule.forRoot({
            diameter: 50,
            stokeWidth: 2,
            hasBackdrop: false,
        }),
    ],
    providers: [
        StoredataService,
        NgbActiveModal,
        SidebarNavViewModel,
        {
            provide: LocationStrategy,
            useClass: HashLocationStrategy
        },
        {
            provide: RouteReuseStrategy,
            useClass: CacheRouteReuseStrategy
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorInterceptor,
            multi: true
        },
        {
            provide: Window, useValue: window
        },
        {
            provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS,
            useValue: {
                overlayPanelClass: ['std-autocomplete-panel']
            }
        },
        {
            provide: DEFAULT_DIALOG_CONFIG,
            useValue: {
                panelClass: 'std-dialog-panel',
                hasBackdrop: true,
                disableClose: true
            }
        },
        {
            provide: SCROLL_INTO_VIEW_OPTIONS,
            useValue: DefaultScrollIntoViewOptions
        }

    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
