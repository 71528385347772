import { Component } from '@angular/core';
import {BaseIconComponent} from '../base-icon-component/base-icon-component';

@Component({
  selector: 's2d-icon-sent-sms-outline',
  templateUrl: `./sent-sms-outline.html`,
  styleUrls: ['./sent-sms-outline.scss'],
})
export class SentSMSOutlineIconComponent extends BaseIconComponent {
}
