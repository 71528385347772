import { Component } from '@angular/core';
import {BaseIconComponent} from '../base-icon-component/base-icon-component';

@Component({
  selector: 's2d-icon-user-circle-solid',
  templateUrl: `./user-circle-solid.html`,
  styleUrls: ['./user-circle-solid.scss'],
})
export class UserCircleSolidIconComponent extends BaseIconComponent {
}
