import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';

export class BaseModel implements Deserializable {
  public updatedDate: Date;
  public updatedById: number;
  public updatedBy: string;
  public createdDate: Date;
  public createdById: number;
  public createdBy: string;

  onDeserialize() {
    this.updatedDate = DeserializeHelper.deserializeToDate(this.updatedDate);
    this.createdDate = DeserializeHelper.deserializeToDate(this.createdDate);
  }
}
