<ng-container *ngIf="tabs.length > 0">
    <div class="tacustom-style">
        <nav mat-tab-nav-bar #tabNav>
            <div *ngFor="let tab of tabs" matTabLink [routerLink]="tab.routerLink" [active]="currentTab === tab" (click)="clickTab(tab)">
                {{tab.title ?? "ss"}}
            </div>
        </nav>
    </div>
    <div class="std-tab-router-outlet">
        <router-outlet *ngIf="useRouter"></router-outlet>
    </div>
</ng-container>