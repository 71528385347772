import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {DatatableData} from '../../protocols/datatable-data';
import {BaseModel} from './base-model';
import {environment} from '../../../../environments/environment';
import {Session} from "./session";
import {AuthChallengeType} from "../../../views/login/auth-challenge-type";

export class User extends BaseModel implements DatatableData, Deserializable {
  public id: number;
  public roleId: number = null;
  public role: string;
  public companyId: number;
  public email: string = '';
  public firstName: string = '';
  public lastName: string = '';
  public passwordChangedDate: Date;
  public eulaConfirmation: boolean;
  public lastLoginDate: Date;
  public enabled: boolean;
  public assignedLocations: AssignedLocation[];

  public userSession: Session;
  public sessionId: string;
  public challengeParameters: Map<string, string>;
  public challengeName: AuthChallengeType;
  public mfaSecretCode: string;
  dateOfBirth: Date;
  public createdBy: string;
  public createdDate: Date;
  public updatedBy:string;
  public updatedDate:Date;
  public tempPassword: string;


  public onDeserialize() {
    super.onDeserialize();
    this.lastLoginDate = DeserializeHelper.deserializeToDate(this.lastLoginDate);
    this.passwordChangedDate = DeserializeHelper.deserializeToDate(this.passwordChangedDate);
    this.assignedLocations = DeserializeHelper.deserializeArray(AssignedLocation, this.assignedLocations);
    this.challengeParameters = DeserializeHelper.deserializeGenericMap(this.challengeParameters);
    this.userSession = DeserializeHelper.deserializeToInstance(Session, this.userSession);
  }

  public get isDriver(): boolean {
    return this.companyId === environment.driverCompanyId;
  }

  public get isAdmin(): boolean {
    return this.companyId === environment.adminCompanyId;
  }

  public get isClient(): boolean {
    return !this.isAdmin && !this.isDriver;
  }

  getFullName(): string {
    return `${this?.firstName} ${this?.lastName}`;
  }

  getChildrenUniqueIds(): string[] {
    return [];
  }

  getColor(): string {
    return '';
  }

  getTextColor(): string {
    return '';
  }
}

export class AssignedLocation implements Deserializable {

  public locationId: number;

  onDeserialize() {
  }
}
