import { Component } from '@angular/core';
import {BaseIconComponent} from '../base-icon-component/base-icon-component';

@Component({
  selector: 's2d-icon-view-grid-outline',
  templateUrl: `./view-grid-outline.html`,
  styleUrls: ['./view-grid-outline.scss'],
})
export class ViewGridOutlineIconComponent extends BaseIconComponent {
}
