import {AbstractChallenge} from './abstract.challenge';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable, of} from 'rxjs';
import {tap} from "rxjs/operators";
import {QrCode} from "../../../shared/components/qr-code/qr-code";
import {AuthChallengeType} from "../../auth-challenge-type";
import {AccountAPI} from "../../../../api/account-api";
import {User} from "../../../../models/account/dto/user";
import {UpdateQrCodeRequest} from "../../../../models/account/requests/update-qr-code-request";

@Injectable({
    providedIn: "root"
})
export class SetupMfaChallenge extends AbstractChallenge implements Resolve<QrCode>{
    public challengeType: AuthChallengeType = 'MFA_SETUP_REQUIRED';
    public qrCode: QrCode;
    public originalUser: User;
    public updateQrCodeRequest: UpdateQrCodeRequest;
    constructor(public router: Router, public accountApi: AccountAPI) {
        super();
    }
    handle(user: User): Observable<User> {
        this.qrCode = new QrCode(user.mfaSecretCode);
        this.updateQrCodeRequest = new UpdateQrCodeRequest();
        this.updateQrCodeRequest.companyId = user.companyId;
        this.updateQrCodeRequest.email = user.email;
        this.updateQrCodeRequest.accessToken = user.userSession.accessToken;
        this.router.navigate(['/mfa/setup']);
        this.originalUser = user;
        return this.user;
    }
    submit(value): Observable<User> {
        return this.accountApi.updateQrCode(value)
            .pipe(tap(user => {
                user.userSession = user?.userSession ?? this.originalUser.userSession;
                this.user.next(user)
            }));
    }
    goToLogin(): void {
        this.router.navigate(['/']);
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<QrCode>{
        if (!this.qrCode) {
            this.goToLogin();
            return;
        }
        return of(this.qrCode);
    }
}
