import { Component } from '@angular/core';
import {BaseIconComponent} from '../base-icon-component/base-icon-component';

@Component({
  selector: 's2d-icon-cash-solid',
  templateUrl: `./cash-solid.html`,
  styleUrls: ['./cash-solid.scss'],
})
export class CashSolidIconComponent extends BaseIconComponent {
}
