import {BaseModel} from './base-model';
import {Deserializable} from '../../protocols/deserializable';
import {DatatableData} from '../../protocols/datatable-data';
import * as moment from 'moment';
import {DAYS_OF_WEEK} from 'angular-calendar';

export class ExtraCharge extends BaseModel implements DatatableData, Deserializable {
  public id: string;
  public invoiceId: string;
  public locationId: number = null;
  public location: string;
  public companyId: number;
  public company: string;
  public title: string = '';
  public amount: number = null;
  public invoicePeriodStartDate: string;
  public invoicePeriodEndDate: string;
  public orderId: string;
  public onFleetShortId: string;
  public extraChargeTypeId: number = null;
  public applyTaxes: boolean = true;
  public invoiceCurrencyCode: string;
  public description: string;

  onDeserialize() {
    super.onDeserialize();
    this.invoiceId = this.invoiceId ?? null;
    this.orderId = this.orderId ?? null;
    this.onFleetShortId = this.onFleetShortId ?? null;
  }

  getChildrenUniqueIds(): string[] {
    return [];
  }

  getColor(): string {
    return '';
  }

  getTextColor(): string {
    return '';
  }

  getCorrectedEndDate(): Date {
    const end = moment(this.invoicePeriodEndDate).toDate();

    if (end.getDay() === DAYS_OF_WEEK.SUNDAY) {
      end.setDate(end.getDate() - 1);
    }

    return end;
  }
}
