import {Deserializable, DeserializeHelper} from '../../protocols/deserializable';
import {DatatableData} from '../../protocols/datatable-data';
import {BaseModel} from './base-model';
import {environment} from '../../../../environments/environment';
import {Session} from "./session";
import {AuthChallengeType} from "../../../views/login/auth-challenge-type";
import { Destination } from './destination';
import { PhoneUtils } from 'src/app/utils/phone-utils';

export class CompanyEmailLogs extends BaseModel implements DatatableData, Deserializable {
  public id: number;
  public companyId: number;
  public locationId: number;
  public orderId: string
  public order: any
  public address: any;
  public addressId:string;
  public recipientId: string;
  public recipient: any;
  public emailTemplateTypeId: number;
  public emailTemplateType:any;
  public content:string;
  public subjectLine:string;
  public fromEmail:string;
  public toEmail:string;
  public errorLog:string;
  public createdBy: string;
  public createdDate: Date;
  public updatedBy:string;
  public updatedDate:Date;
  public status:boolean
  public location:any;

  get recipientPhoneNo(): string {
    if (!this.recipient?.phone) {
      return null;
    }
    return PhoneUtils.formatPhoneNumber(this.recipient?.phone);
  }

  public onDeserialize() {
    super.onDeserialize();
    // this.lastLoginDate = DeserializeHelper.deserializeToDate(this.lastLoginDate);
    // this.passwordChangedDate = DeserializeHelper.deserializeToDate(this.passwordChangedDate);
    // this.assignedLocations = DeserializeHelper.deserializeArray(AssignedLocation, this.assignedLocations);
    // this.challengeParameters = DeserializeHelper.deserializeGenericMap(this.challengeParameters);
    // this.userSession = DeserializeHelper.deserializeToInstance(Session, this.userSession);
  }

  public get isDriver(): boolean {
    return this.companyId === environment.driverCompanyId;
  }

  public get isAdmin(): boolean {
    return this.companyId === environment.adminCompanyId;
  }

  public get isClient(): boolean {
    return !this.isAdmin && !this.isDriver;
  }

  // getFullName(): string {
  //   return `${this?.firstName} ${this?.lastName}`;
  // }

  getChildrenUniqueIds(): string[] {
    return [];
  }

  getColor(): string {
    return '';
  }

  getTextColor(): string {
    return '';
  }
}

export class AssignedLocation implements Deserializable {

  public locationId: number;

  onDeserialize() {
  }
}
