import { Component } from '@angular/core';
import {BaseIconComponent} from '../base-icon-component/base-icon-component';

@Component({
  selector: 's2d-icon-receipt-tax-solid',
  templateUrl: `./receipt-tax-solid.html`,
  styleUrls: ['./receipt-tax-solid.scss'],
})
export class ReceiptTaxSolidIconComponent extends BaseIconComponent {
}
