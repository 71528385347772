import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {SessionContainer} from '../models/shared/session-container';
import {RefreshSessionRequest} from '../models/account/requests/refresh-session-request';
import {CacheService} from './cache-service';
import {DefaultCacheKey} from '../models/enum/shared/default-cache-key.enum';
import {DateUtils} from '../utils/date-utils';
import {Role} from '../models/account/dto/role';
import {PermissionRule} from '../models/base/PermissionRule';
import {User} from "../models/account/dto/user";
import {Driver} from "../models/account/dto/driver";
import {map} from "rxjs/operators";
import {environment} from "../../environments/environment";
import "../utils/observable.extensions";

@Injectable({
  providedIn: 'root'
})

export class SessionService {

  // Behaviour Subjects
  public refreshingSession: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  public sessionContainer: BehaviorSubject<SessionContainer> = new BehaviorSubject<SessionContainer>(null);
  public currentUser$: Observable<User|Driver> = this.sessionContainer.asObservable().notNull()
      .pipe(map(container => container.user));
  public currentUserIsAdmin$: Observable<boolean> = this.currentUser$.pipe(map(user => this.isAdmin(user)));
  public currentUserIsDriver$: Observable<boolean> = this.currentUser$.pipe(map(user => this.isDriver(user)));
  public currentUserIsClient$: Observable<boolean> = this.currentUser$.pipe(map(user => this.isClient(user)));
  public destroySession: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private deadSession: boolean = true;


  constructor(
    private cacheService: CacheService,
  ) {
    this.setupBindings();
  }

  public get user() {
    return this.getUser();
  }
  public isAdmin(user: {companyId}) {
    return user.companyId === environment.adminCompanyId;
  }
  public isDriver(user: {companyId}) {
    return user.companyId === environment.driverCompanyId;
  }
  public isClient(user: {companyId}) {
    return !this.isDriver(user) && !this.isAdmin(user);
  }

  public get currentUserIsAdmin() {
    return this.isAdmin(this.user);
  }
  public get currentUserIsDriver() {
    return this.isDriver(this.user);
  }
  public get currentUserIsClient() {
    return this.user.companyId && !this.currentUserIsDriver && !this.currentUserIsAdmin;
  }

  public setupBindings() {
    this.sessionContainer.notNull().subscribe((sess) => {
      if (sess && this.sessionContainer.getValue().rememberSession) {
        // save session to persistent cache
        this.cacheService.cacheObject(DefaultCacheKey.SessionContainer, sess, true);
      }
      // save session to session cache
      this.cacheService.cacheObject(DefaultCacheKey.SessionContainer, sess);
    });

    this.destroySession.notNull().subscribe((shouldDestroy) => {
      if (shouldDestroy) {
        this.deadSession = true;
        this.cacheService.clearSessionCache();
        this.cacheService.clearPersistentCache();
        this.sessionContainer.next(null);
      }
    });
  }

  public getCachedSession(): SessionContainer {
    // Get user session from cache, checking session cache first
    let sess: SessionContainer;
    sess = this.cacheService.getCachedObject<SessionContainer>(SessionContainer, DefaultCacheKey.SessionContainer);
    if (!sess) {
      // Check the persistent cache for a session
      sess = this.cacheService.getCachedObject<SessionContainer>(SessionContainer, DefaultCacheKey.SessionContainer, true);
    }
    this.deadSession = false;
    this.sessionContainer.next(sess);
    return sess;
  }

  // Getters

  public liveSession(): boolean {
    return !this.deadSession;
  }

  public getUser(): User | Driver {
    if (this.sessionContainer.getValue()) {
      return this.sessionContainer.getValue().user;
    }
    return null;
  }

  public getRole(): Role {
    if (this.sessionContainer.getValue()) {
      return this.sessionContainer.getValue().userRole;
    }
    return null;
  }

  public getUserId(): number {
    if (this.sessionContainer.getValue()) {
      return this.sessionContainer.getValue().user?.id;
    }
    return null;
  }

  public getUserEmail(): string {
    if (this.sessionContainer.getValue()) {
      return this.sessionContainer.getValue().user?.email;
    }
    return '';
  }

  public getAuthToken(): string {
    if (this.sessionContainer.getValue()) {
      return this.sessionContainer.getValue().user?.userSession?.accessToken;
    }
    return '';
  }

  public getRefreshToken(): string {
    if (this.sessionContainer.getValue()) {
      return this.sessionContainer.getValue().user?.userSession?.refreshToken;
    }
    return '';
  }

  public getRefreshSessionReq(sess?: SessionContainer): RefreshSessionRequest {
    if (sess) {
      const refreshToken = sess.user?.userSession?.refreshToken;
      return new RefreshSessionRequest(refreshToken);
    } else if (this.sessionContainer.getValue()) {
      const refreshToken = this.getRefreshToken();
      return new RefreshSessionRequest(refreshToken);
    } else {
      return null;
    }
  }

  hasPermission(permission: PermissionRule): boolean {
    if (!permission?.permissions?.length) {
      return true;
    }
    const sessionContainer = this.sessionContainer.getValue();
    const userPermissions = sessionContainer?.userRole?.permissions?.map(p => p.permissionId) ?? [];
    return permission.check(userPermissions);
  }

  // Setters

  public setUser(u: User | Driver, role: Role, newSession: boolean, rememberSession: boolean = false) {
    const sessCopy = Object.assign(new SessionContainer(), this.sessionContainer.getValue());
    sessCopy.user = u;
    sessCopy.userRole = role;
    sessCopy.rememberSession = rememberSession;
    if (newSession) {
      sessCopy.sessionStartTime = DateUtils.currentTimestamp();
    }
    this.deadSession = false;
    this.sessionContainer.next(sessCopy);
  }
}
