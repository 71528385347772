import { Deserializable } from "../../protocols/deserializable";

export class unsubscribedSMSResponse implements Deserializable{
    
    public success:boolean;
    public data:unsubscribedSMSResponse
    public error:string;
    onDeserialize() {
        
    }
}