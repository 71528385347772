import {BaseModel} from './base-model';
import {Deserializable} from '../../protocols/deserializable';
import {Tax} from './tax';
import {FormListable} from '../../protocols/form-listable';
import {NumberUtils} from '../../../utils/number-utils';

export class LocationTax extends BaseModel implements Deserializable, FormListable {
  public taxId: number = null;
  public tax: Tax = null;
  itemChanged: boolean;
  itemCreated: boolean;
  itemDeleted: boolean;

  getEnabledStatus(): boolean {
    return true;
  }

  getListItemSubtitle(): string {
    return `${NumberUtils.formatNumberAsPercentString(this.tax?.value)}`;
  }

  getListItemTitle(): string {
    return this.tax?.name;
  }

  getErrorState(): boolean {
    return false;
  }
}
