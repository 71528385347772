import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AbandonFormComponent } from '../../views/shared/modals/abandon-form/abandon-form.component';
import { ComponentCanDeactivate } from '../../models/protocols/component-can-deactivate';
import { ModalUtils } from '../../utils/modal-utils';

export const CanDeactivateState = {
  defendAgainstBrowserBackButton: false,
};

@Injectable({
  providedIn: 'root',
})
export class CanDeactivateGuard
  implements CanDeactivate<ComponentCanDeactivate>
{
  constructor(private modalService: NgbModal) {}

  canDeactivate(component: ComponentCanDeactivate): boolean | Promise<any> {
    if (!component.canDeactivate()) {
      const modal = this.modalService.open(
        component.deactivateModalComponent ?? AbandonFormComponent,
        ModalUtils.defaultModalOptions()
      );
      return modal.result;
    }
    return true;
  }
}
