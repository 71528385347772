import {Component, Input, ViewEncapsulation} from '@angular/core';
import {FontSizes} from "../font-sizes";

export type ParagraphSizes = FontSizes;

@Component({
  selector: 'std-paragraph',
  templateUrl: './paragraph.component.html',
  styleUrls: ['./paragraph.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ParagraphComponent {
  @Input() size: ParagraphSizes = 'sm';
}
