import { Deserializable } from "../../protocols/deserializable";

export class UnsubscribeLocation implements Deserializable {
    public company: string;
    public address: string;
    public city: string;
    public state: string;
    public phone: string;
    public email: string;
    public imageUrl: string;
    public location: string;
  
    onDeserialize() {
    }
  }