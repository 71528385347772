import {Component, SecurityContext, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UploadAssetComponent} from '../upload-asset/upload-asset.component';
import {UploadImageInterface} from '../upload-asset/upload-image-interface';
import {CustomFile} from '../../../../models/shared/custom-file';
import {Subject} from 'rxjs';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {BaseModal} from "../../../../models/base/base-modal";
import {LoadedImage} from "ngx-image-cropper/lib/interfaces";

@Component({
    selector: 'app-upload-image-modal',
    templateUrl: './upload-image-modal.component.html',
    styleUrls: ['./upload-image-modal.component.scss']
})
export class UploadImageModalComponent extends BaseModal implements UploadImageInterface {


    @ViewChild('uploadContainer') uploadContainer: UploadAssetComponent;

    initImage: LoadedImage;
    titleText = '';
    editTitleText = '';
    croppedImage: any = '';
    roundedOverlay = true;
    loadingImage = false;
    transform = {
        scale: 0.75,
        rotate: 0,
        flipH: false,
        flipV: false,
    };

    logoToUpload: string | ArrayBuffer | SafeResourceUrl;
    clearImages: Subject<boolean> = new Subject<boolean>();

    constructor(private activeModal: NgbActiveModal, private sanitizer: DomSanitizer) {
        super();
    }

    setupViews() {
    }
    setupBindings() {
    }

    initWith(title: string, editTitle: string, existingImage: string | SafeResourceUrl) {
        this.titleText = title;
        this.editTitleText = editTitle;
        if (existingImage) {
            if (typeof existingImage === 'string') {
                this.logoToUpload = existingImage;
            } else {
                this.logoToUpload = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, existingImage);
            }
        }
    }

    closeModal() {
        this.activeModal.close(this.initImage.original.base64);
    }
    cancel() {
        this.closeModal();
    }
    removeImage() {
        this.activeModal.close();
    }

    apply() {
        this.activeModal.close(this.croppedImage);
    }

    imageCropped(event) {
        this.croppedImage = event.base64;
    }

    imageLoaded(event: LoadedImage) {
        this.initImage = event;
    }

    cropperReady() {
        // cropper ready
        this.loadingImage = false;
    }

    loadImageFailed() {
        // show message
    }

    fileList(f: CustomFile[], id: number) {
        if (f.length > 0) {
            this.logoToUpload = f[0].url;
        } else {
            this.logoToUpload = undefined;
        }
    }
}
