import {BehaviorSubject} from 'rxjs';
import {LoadingSpinnerSize} from '../enum/shared/loading-spinner-size.enum';
import {debounceTime} from 'rxjs/operators';

export class LoadingOptions {
  public isLoading: boolean = false;
  public fullscreen: boolean = false;
  public determinateLoading: boolean = false;
  public showLoadingText: boolean = false;
  public progress = 0;
  public loadingText: string;
  public backgroundColor: string;
  public spinnerColor: string;
  public color: string;
  public topMarginRem: number = 0;
  public cornerRadius: string;
  public spinnerSize: LoadingSpinnerSize = LoadingSpinnerSize.Default;
  public zIndex = 99;
  public debounceTime = 0;

  constructor(debounceTimeMillis: number = 0) {
    this.debounceTime = debounceTimeMillis;
  }

  private awaitingRequests: BehaviorSubject<string[]> = new BehaviorSubject([]);

  static default(determinate: boolean = false, fullscreen: boolean = false, debounceTimeMillis: number = 0): LoadingOptions {
    const opt = new LoadingOptions(debounceTimeMillis);
    opt.backgroundColor = '#C3C9D0';
    opt.spinnerColor = '#2c4058';
    opt.color = '#2c4058';
    opt.determinateLoading = determinate;
    opt.showLoadingText = true;
    opt.fullscreen = fullscreen;
    opt.init();
    return opt;
  }

  static defaultLight(determinate: boolean = false, fullscreen: boolean = false, debounceTimeMillis: number = 0): LoadingOptions {
    const opt = LoadingOptions.default(determinate, fullscreen, debounceTimeMillis);
    opt.backgroundColor = '#FFFFFF';
    opt.spinnerColor = '#4F4F4F';
    opt.color = '#4F4F4F';
    return opt;
  }

  static defaultInButton(): LoadingOptions {
    const opt = new LoadingOptions();
    opt.backgroundColor = 'transparent';
    opt.spinnerColor = '#FFF';
    opt.color = '#FFF';
    opt.spinnerSize = LoadingSpinnerSize.Small;
    opt.init();
    return opt;
  }

  init() {
    this.awaitingRequests.pipe(debounceTime(this.debounceTime)).subscribe((reqs) => {
      this.isLoading = reqs.length > 0;
      if (reqs.length > 0) {
        this.loadingText = reqs[reqs.length - 1];
      } else {
        this.loadingText = '';
      }
    });
  }

  containsRequest(mess: string): boolean {
    const currReqs = this.awaitingRequests.getValue();
    const existingIndex = currReqs.indexOf(mess);
    return existingIndex > -1;
  }

  addRequest(mess: string) {
    const currReqs = this.awaitingRequests.getValue();
    const existingIndex = currReqs.indexOf(mess);
    if (existingIndex === -1) {
      currReqs.push(mess);
      this.awaitingRequests.next(currReqs);
    }
  }

  removeRequest(mess: string) {
    const currReqs = this.awaitingRequests.getValue();
    const removeIndex = currReqs.indexOf(mess);
    if (removeIndex > -1) {
      currReqs.splice(removeIndex, 1);
    }
    this.awaitingRequests.next(currReqs);
  }

  connectToRequestQueue(): BehaviorSubject<string[]> {
    return this.awaitingRequests;
  }
}
