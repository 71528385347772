import { Component } from '@angular/core';
import {BaseIconComponent} from '../base-icon-component/base-icon-component';

@Component({
  selector: 's2d-icon-receipt-tax-outline',
  templateUrl: `./receipt-tax-outline.html`,
  styleUrls: ['./receipt-tax-outline.scss'],
})
export class ReceiptTaxOutlineIconComponent extends BaseIconComponent {
}
