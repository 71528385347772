import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { UserApi } from 'src/app/api/user-api';
import { UnsubscribeLocation } from 'src/app/models/account/response/UnsubscribeLocation';
import { ToastService } from 'src/app/services/toast-service';
import { UnsubscribeSMSRequest } from 'src/app/models/account/requests/unsubscribe-sms-request';

@Component({
  selector: 'app-company-sms-logs-unsubscribe',
  templateUrl: './company-sms-logs-unsubscribe.html',
  styleUrls: ['./compnay-sms-logs-unsubscribe.scss']
})
export class SMSLogUnSubscribeComponent {
  confirmationMessage: string
  companyId: string;
  locationId: string;
  phone: string;
  public unsubscribeLocation: UnsubscribeLocation
  public subscribeFlag: boolean = false

  constructor(private http: HttpClient, private activatedRoute: ActivatedRoute,
    private router: Router, private userApi: UserApi, private toaster: ToastService) {
  }


  ngOnInit() {
    this.activatedRoute.params.subscribe(params => {
      this.companyId = params.companyId;
      this.locationId = params.locationId;
      this.phone = params.phone;
      this.userApi.getCompanyUnsubcribePageDetail(Number(this.companyId), Number(this.locationId))
        .subscribe(
          (res: UnsubscribeLocation) => {
            this.unsubscribeLocation = res
          },
          error => {
            console.error('Logo failed:', error);
            this.toaster.publishErrorMessage("Something went wrong please try again!");
          }
        );
    });
  }

  onSave() {
    this.confirmationMessage = "You have unsubscribed.";
    var request = new UnsubscribeSMSRequest();
    request.companyId = Number(this.companyId);
    request.locationId = Number(this.locationId);
    request.phone = this.phone;
    this.userApi.unsubcribeFromSMS(request).subscribe(
      (res: any) => {
        if (!res.success && res.error)
          this.confirmationMessage = res.error
        this.subscribeFlag = !this.subscribeFlag
      }, error => {
        console.log('unsubscription failed:', error);
        this.toaster.publishErrorMessage("Something went wrong please try again!");
      }
    )
  }
}
