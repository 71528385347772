import {Address} from '../models/account/dto/address';

export default class AddressUtils {
    static getStoreToDoorAddress() {
        return {
            street: '2375 College Ave',
            city: 'Regina, SK',
            postalCode: 'S4P 0S8',
            website: 'storetodoorcanada.com',
            phone: '+1 (831) 318-5082'
        };
    }

    static singleLineFormat(address: Address): string {
        if (address == null) {
            return null;
        }

        const addressComponents: string[] = [];

        const streetNumberAddress = this.streetAddressString(address);
        if (streetNumberAddress?.length > 0) {
            addressComponents.push(streetNumberAddress);
        }
        if (address.city?.length > 0) {
            addressComponents.push(address.city);
        }
        if (address.state?.length > 0) {
            addressComponents.push(address.state);
        }
        if (address.country?.length > 0) {
            addressComponents.push(address.country);
        }
        if (address.postalCode?.length > 0) {
            addressComponents.push(address.postalCode);
        }
        return addressComponents.join(', ');
    }

    static streetAddressString(address: Address): string {
        if (address == null) {
            return null;
        }

        const streetNumberComponents: string[] = [];
        if (address.apartment?.length > 0) {
            streetNumberComponents.push(address.apartment);
        }
        if (address.number?.length > 0) {
            streetNumberComponents.push(address.number);
        }
        if (address.street?.length > 0) {
            streetNumberComponents.push(address.street);
        }

        return streetNumberComponents.join(' ');
    }

    static cityStateString(address: Address): string {
        if (address == null) {
            return null;
        }

        const cityState: string[] = [];
        if (address.city?.length > 0) {
            cityState.push(address.city);
        }
        if (address.state?.length > 0) {
            cityState.push(address.state);
        }

        return cityState.join(', ');
    }

    static cityStatePostalCodeString(address: Address): string {
        if (address == null) {
            return null;
        }

        const cityStatePostal: string[] = [];
        if (address.city?.length > 0) {
            cityStatePostal.push(address.city);
        }
        if (address.state?.length > 0) {
            cityStatePostal.push(address.state);
        }
        if (address.postalCode?.length > 0) {
            cityStatePostal.push(address.postalCode);
        }

        return cityStatePostal.join(', ');
    }
}
