import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TitleComponent } from './title/title.component';
import {LabelComponent} from "./label/label.component";
import { ParagraphComponent } from './paragraph/paragraph.component';



@NgModule({
    declarations: [
        TitleComponent,
        LabelComponent,
        ParagraphComponent
    ],
    exports: [
        TitleComponent,
        LabelComponent,
        ParagraphComponent
    ],
    imports: [
        CommonModule
    ]
})
export class StdTextModule { }
