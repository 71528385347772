import {MediaType} from '../models/enum/dto/media-type.enum';
import {environment} from '../../environments/environment';
import {OnFleetImageSizes} from "../models/enum/dto/on-fleet-image.sizes";
import {Attachment} from "../interfaces/attachments/attachment";

export const ASSET_RETRY_COUNT = 10;
export const ASSET_RETRY_DELAY = 10;
export const DELETE_ASSET_DELAY = 10;
export class MediaUtils {

  static getMediaType(name: string): MediaType {
    switch (name.split('.').pop().toLowerCase()) {
      case 'jpg':
        return MediaType.JPG;
      case 'jpeg':
        return MediaType.JPEG;
      case 'png':
        return MediaType.PNG;
      case 'gif':
        return MediaType.GIF;
      case 'mp4':
        return MediaType.MP4;
      case 'webm':
        return MediaType.WEBM;
      case 'ogg':
        return MediaType.OGV;
      case 'avi':
        return MediaType.AVI;
      case 'mpeg':
        return MediaType.MPEG;
      case 'csv':
        return MediaType.CSV;
      case 'pdf':
        return MediaType.PDF;
      default:
        return null;
    }
  }
  static getFileTypeId(attachment: Attachment | File): number {
    if (attachment.type.match(/image*/)) {
      return 1;
    }
    if (attachment.type.match(/application\/pdf/)) {
      return 2;
    }
  }
  static getFileTypeIdFromString(type: string): number {
    if (type.match(/image*/)) {
      return 1;
    }
    if (type.match(/application\/pdf/)) {
      return 2;
    }
  }
  static isImage(mediaType: MediaType): boolean {
    const imageTypes: MediaType[] = [MediaType.JPG, MediaType.JPEG, MediaType.PNG, MediaType.GIF];
    return imageTypes.includes(mediaType);
  }

  static isVideo(mediaType: MediaType): boolean {
    const videoTypes: MediaType[] = [MediaType.MP4, MediaType.WEBM, MediaType.OGV, MediaType.AVI, MediaType.MPEG];
    return videoTypes.includes(mediaType);
  }

  static getRefreshAssetLoadingMessage(remainingRetries: number) {
    switch (remainingRetries) {
      case 1:
        return 'Reloading Asset.';
      case 2:
        return 'Reloading Asset.';
      case 3:
        return 'Reloading Asset.';
      case 4:
        return 'Resizing Asset.';
      case 5:
        return 'Resizing Asset.';
      case 6:
        return 'Resizing Asset.';
      case 7:
        return 'Compressing Asset.';
      case 8:
        return 'Compressing Asset.';
      case 9:
        return 'Compressing Asset.';
      case 10:
        return 'Compressing Asset.';
      default:
        return `Loading Asset (${remainingRetries})`;
    }
  }

  static getRefreshMenuMediaAsset(remainingRetries: number) {
    switch (remainingRetries) {
      case 1:
        return 'Reloading Media.';
      case 2:
        return 'Reloading Media.';
      case 3:
        return 'Reloading Media.';
      case 4:
        return 'Resizing Media.';
      case 5:
        return 'Resizing Media.';
      case 6:
        return 'Resizing Media.';
      default:
        return `Compressing Media.`;
    }
  }

  static getDeliveryPhotoUrl(photoId: string, size: OnFleetImageSizes): string {
    return `${environment.onFleetAssetUrl}/${photoId}/${size}x.png`;
  }

}
