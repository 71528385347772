import {DateUtils} from '../../utils/date-utils';
import {Cachable} from '../protocols/cachable';
import {DefaultCacheKey} from '../enum/shared/default-cache-key.enum';
import {DeserializeHelper} from '../protocols/deserializable';
import {CachePolicy} from '../enum/shared/cachable-image-policy.enum';
import {Role} from '../account/dto/role';
import {environment} from '../../../environments/environment';
import {CompanyType} from '../enum/shared/company-type.enum';
import {User} from "../account/dto/user";
import {Driver} from "../account/dto/driver";
export class SessionContainer implements Cachable {
  // Shared properties
  public user: Driver | User;
  public userRole: Role;
  // Session Settings
  public sessionStartTime: number;
  public rememberSession: boolean;
  public cachedTime: number;

  constructor(user?: User | Driver) {
    this.user = user;
  }

  public get companyType(): CompanyType {
    if (this.isAdminPortal) {
      return CompanyType.admin;
    }
    if (this.isDriverPortal){
      return CompanyType.driver;
    }
    return CompanyType.client;
  }

  public get isAdminPortal(): boolean {
    return this.user.companyId === environment.adminCompanyId;
  }

  public get isDriverPortal(): boolean {
    return this.user.companyId === environment.driverCompanyId;
  }

  public get isClientPortal(): boolean {
    return !this.isAdminPortal && !this.isDriverPortal;
  }

  public validSession(): boolean {
    return this.user?.userSession?.validSession();
  }

  public sessionNeedsRefresh(): boolean {
    return this.user?.userSession?.sessionNeedsRefresh();
  }

  cacheExpirySeconds(): number {
    return DateUtils.unixOneWeek();
  }

  cacheKey(): string {
    return DefaultCacheKey.SessionContainer;
  }

  imageCachePolicy(): CachePolicy {
    return CachePolicy.Service;
  }

  isExpired(): boolean {
    const expiresAt = this.cachedTime + this.cacheExpirySeconds();
    return DateUtils.currentTimestamp() > expiresAt;
  }

  onDeserialize() {
    this.user = DeserializeHelper.deserializeToInstance(User, this.user);
    this.userRole = DeserializeHelper.deserializeToInstance(Role, this.userRole);
  }
}
