import {Directive, OnInit} from '@angular/core';
import {map, takeUntil} from "rxjs/operators";
import {Subscribable} from "../models/base/subscribable";
import {Observable} from "rxjs";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

@Directive({
    selector: '[stdChatWidgetHider]'
})
export class ChatWidgetHiderDirective extends Subscribable implements OnInit {

    hasActiveInstance$: Observable<boolean> = this.modalService.activeInstances.pipe(map(instances => instances.length > 0));

    constructor(public modalService: NgbModal) {
        super();
    }

    ngOnInit(): void {
        this.hasActiveInstance$
            .pipe(takeUntil(this.onDestroy))
            .subscribe(value => {
                const el = document.getElementById('hubspot-messages-iframe-container');
                if (!el) {
                    return;
                }

                if (value) {
                    el.classList.add('d-none')
                } else {
                    el.classList.remove('d-none')
                }
            });
    }
}