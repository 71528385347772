import { Deserializable } from '../../protocols/deserializable';
import { BaseModel } from './base-model';

export class CompanyIntegration extends BaseModel implements Deserializable {
  public id: number;
  public companyId: number;
  public locationId: number;
  public integrationTypeId: number = null;
  public integrationTypeName: string;
  public apiKey: string = null;
  public password: string = null;
  public baseUrl: string = null;
  public urlSuffix: string = null;
  public webhookSecret: string = null;
  public contactEmails: string = null;
  public slackWebhookUrl: string = null;
  public customerNotesField: string = null;
  public pickupTimeMatchRequired: boolean = false;
  public sendOrderCreatedMessage: boolean = false;
  public writePickupTimeAs24HourTime: boolean = false;
  public groupItemsInOnePackage: boolean = false;
  public fulfilOrderOnDelivery: boolean = false;

  public onDeserialize() {
    super.onDeserialize();
    this.id = this.id ?? null;
    this.companyId = this.companyId ?? null;
    this.locationId = this.locationId ?? null;
    this.contactEmails = this.contactEmails ?? null;
    this.slackWebhookUrl = this.slackWebhookUrl ?? null;
    this.customerNotesField = this.customerNotesField ?? null;
  }
}


