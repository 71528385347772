import {InjectionToken, ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Spinner} from './spinner/spinner.component';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";


export interface SpinnerConfig {
    stokeWidth?: number;
    diameter?: number;
    hasBackdrop?: boolean;
}

export const STD_SPINNER_CONFIG = new InjectionToken<SpinnerConfig>("STD_SPINNER_CONFIG")

@NgModule({
    declarations: [
        Spinner
    ],
    exports: [
        Spinner
    ],
    imports: [
        CommonModule,
        MatProgressSpinnerModule
    ],
})
export class SpinnerModule {
    static forRoot(config?: SpinnerConfig): ModuleWithProviders<SpinnerModule> {
        return {
            ngModule: SpinnerModule,
            providers: [{provide: STD_SPINNER_CONFIG, useValue: config}]
        }
    }
}
