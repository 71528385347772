import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SessionService } from '../../../services/session-service';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { BaseComponent } from '../../../models/base/base-component';
import { LoadingOptions } from '../../../models/shared/loading-options';
import { DefaultLayoutService } from './default-layout.service';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
})
export class DefaultLayoutComponent
  extends BaseComponent
  implements OnInit, AfterViewInit
{
  public loadingOpts: LoadingOptions = LoadingOptions.defaultLight(
    false,
    false
  );
  public sidebarMinimized = false;
  isLoading: boolean = false;
  @ViewChild('main') main: ElementRef;

  constructor(
    private sessionService: SessionService,
    public router: Router,
    public route: ActivatedRoute,
    public layoutService: DefaultLayoutService,    
  ) {
    super();
    setTimeout(()=>{
      this.sidebarMinimized = this.layoutService.sidebarMinimized 
    },0)
  }
  
  ngOnInit(): void {
    this.setupBindings();
    this.setupViews();
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
    this.layoutService.noPadding$$.takeUntil(this.onDestroy).subscribe((v) => {
      const classList = (this.main.nativeElement as HTMLElement).classList;
      v ? classList.add('no-padding') : classList.remove('no-padding');
    });
  }

  setupBindings() {
    this.router.events.subscribe((e) => {
      switch (e.constructor) {
        case NavigationStart:
          this.isLoading = true;
          break;
        case NavigationEnd:
        case NavigationCancel:
        case NavigationError:
          this.isLoading = false;
          break;
        default:
          break;
      }
    });

    this.sessionService.refreshingSession.notNull().subscribe((ref) => {
      const lm = $localize`Refreshing Session`;
      if (!this.loadingOpts.containsRequest(lm) && ref) {
        this.loadingOpts.addRequest(lm);
      } else if (this.loadingOpts.containsRequest(lm) && !ref) {
        this.loadingOpts.removeRequest(lm);
      }
    });
    this.sessionService.sessionContainer.subscribe((s) => {
      this.sidebarMinimized = s?.validSession() !== true;
    });
  }

  setupViews() {
    this.setupLoadingOpts();
  }

  setupLoadingOpts() {
    this.loadingOpts.zIndex = 100;
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
}
