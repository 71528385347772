import { Component } from '@angular/core';
import {BaseIconComponent} from '../base-icon-component/base-icon-component';

@Component({
  selector: 's2d-icon-user-circle-outline',
  templateUrl: `./user-circle-outline.html`,
  styleUrls: ['./user-circle-outline.scss'],
})
export class UserCircleOutlineIconComponent extends BaseIconComponent {
}
