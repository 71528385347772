import { NgModule } from '@angular/core';
import { BaseIconComponent } from './components/base-icon-component/base-icon-component';
import { CommonModule } from '@angular/common';
import { AcademicCapOutlineIconComponent } from './components/academic-cap-outline/academic-cap-outline.icon.component';
import { AdjustmentsOutlineIconComponent } from './components/adjustments-outline/adjustments-outline.icon.component';
import { AnnotationOutlineIconComponent } from './components/annotation-outline/annotation-outline.icon.component';
import { ArchiveOutlineIconComponent } from './components/archive-outline/archive-outline.icon.component';
import { ArrowCircleDownOutlineIconComponent } from './components/arrow-circle-down-outline/arrow-circle-down-outline.icon.component';
import { ArrowCircleLeftOutlineIconComponent } from './components/arrow-circle-left-outline/arrow-circle-left-outline.icon.component';
import { ArrowCircleRightOutlineIconComponent } from './components/arrow-circle-right-outline/arrow-circle-right-outline.icon.component';
import { ArrowCircleUpOutlineIconComponent } from './components/arrow-circle-up-outline/arrow-circle-up-outline.icon.component';
import { ArrowNarrowDownOutlineIconComponent } from './components/arrow-narrow-down-outline/arrow-narrow-down-outline.icon.component';
import { ArrowNarrowLeftOutlineIconComponent } from './components/arrow-narrow-left-outline/arrow-narrow-left-outline.icon.component';
import { ArrowNarrowRightOutlineIconComponent } from './components/arrow-narrow-right-outline/arrow-narrow-right-outline.icon.component';
import { ArrowNarrowUpOutlineIconComponent } from './components/arrow-narrow-up-outline/arrow-narrow-up-outline.icon.component';
import { ArrowUpOutlineIconComponent } from './components/arrow-up-outline/arrow-up-outline.icon.component';
import { ArrowsExpandOutlineIconComponent } from './components/arrows-expand-outline/arrows-expand-outline.icon.component';
import { AtSymbolOutlineIconComponent } from './components/at-symbol-outline/at-symbol-outline.icon.component';
import { BackspaceOutlineIconComponent } from './components/backspace-outline/backspace-outline.icon.component';
import { BadgeCheckOutlineIconComponent } from './components/badge-check-outline/badge-check-outline.icon.component';
import { BanOutlineIconComponent } from './components/ban-outline/ban-outline.icon.component';
import { BeakerOutlineIconComponent } from './components/beaker-outline/beaker-outline.icon.component';
import { BellOutlineIconComponent } from './components/bell-outline/bell-outline.icon.component';
import { BookOpenOutlineIconComponent } from './components/book-open-outline/book-open-outline.icon.component';
import { BookmarkAltOutlineIconComponent } from './components/bookmark-alt-outline/bookmark-alt-outline.icon.component';
import { BookmarkOutlineIconComponent } from './components/bookmark-outline/bookmark-outline.icon.component';
import { BriefcaseOutlineIconComponent } from './components/briefcase-outline/briefcase-outline.icon.component';
import { CakeOutlineIconComponent } from './components/cake-outline/cake-outline.icon.component';
import { CalculatorOutlineIconComponent } from './components/calculator-outline/calculator-outline.icon.component';
import { CalendarOutlineIconComponent } from './components/calendar-outline/calendar-outline.icon.component';
import { CameraOutlineIconComponent } from './components/camera-outline/camera-outline.icon.component';
import { CashOutlineIconComponent } from './components/cash-outline/cash-outline.icon.component';
import { ChartBarOutlineIconComponent } from './components/chart-bar-outline/chart-bar-outline.icon.component';
import { ChartPieOutlineIconComponent } from './components/chart-pie-outline/chart-pie-outline.icon.component';
import { ChartSquareBarOutlineIconComponent } from './components/chart-square-bar-outline/chart-square-bar-outline.icon.component';
import { ChatAlt2OutlineIconComponent } from './components/chat-alt-2-outline/chat-alt-2-outline.icon.component';
import { ChatAltOutlineIconComponent } from './components/chat-alt-outline/chat-alt-outline.icon.component';
import { ChatOutlineIconComponent } from './components/chat-outline/chat-outline.icon.component';
import { CheckCircleOutlineIconComponent } from './components/check-circle-outline/check-circle-outline.icon.component';
import { CheckOutlineIconComponent } from './components/check-outline/check-outline.icon.component';
import { ChevronDoubleDownOutlineIconComponent } from './components/chevron-double-down-outline/chevron-double-down-outline.icon.component';
import { ChevronDoubleLeftOutlineIconComponent } from './components/chevron-double-left-outline/chevron-double-left-outline.icon.component';
import { ChevronDoubleRightOutlineIconComponent } from './components/chevron-double-right-outline/chevron-double-right-outline.icon.component';
import { ChevronDoubleUpOutlineIconComponent } from './components/chevron-double-up-outline/chevron-double-up-outline.icon.component';
import { ChevronDownOutlineIconComponent } from './components/chevron-down-outline/chevron-down-outline.icon.component';
import { ChevronLeftOutlineIconComponent } from './components/chevron-left-outline/chevron-left-outline.icon.component';
import { ChevronRightOutlineIconComponent } from './components/chevron-right-outline/chevron-right-outline.icon.component';
import { ChevronUpOutlineIconComponent } from './components/chevron-up-outline/chevron-up-outline.icon.component';
import { ChipOutlineIconComponent } from './components/chip-outline/chip-outline.icon.component';
import { ClipboardCheckOutlineIconComponent } from './components/clipboard-check-outline/clipboard-check-outline.icon.component';
import { ClipboardCopyOutlineIconComponent } from './components/clipboard-copy-outline/clipboard-copy-outline.icon.component';
import { ClipboardListOutlineIconComponent } from './components/clipboard-list-outline/clipboard-list-outline.icon.component';
import { ClipboardOutlineIconComponent } from './components/clipboard-outline/clipboard-outline.icon.component';
import { ClockOutlineIconComponent } from './components/clock-outline/clock-outline.icon.component';
import { CloudDownloadOutlineIconComponent } from './components/cloud-download-outline/cloud-download-outline.icon.component';
import { CloudOutlineIconComponent } from './components/cloud-outline/cloud-outline.icon.component';
import { CloudUploadOutlineIconComponent } from './components/cloud-upload-outline/cloud-upload-outline.icon.component';
import { CodeOutlineIconComponent } from './components/code-outline/code-outline.icon.component';
import { CogOutlineIconComponent } from './components/cog-outline/cog-outline.icon.component';
import { CollectionOutlineIconComponent } from './components/collection-outline/collection-outline.icon.component';
import { ColorSwatchOutlineIconComponent } from './components/color-swatch-outline/color-swatch-outline.icon.component';
import { CreditCardOutlineIconComponent } from './components/credit-card-outline/credit-card-outline.icon.component';
import { CubeOutlineIconComponent } from './components/cube-outline/cube-outline.icon.component';
import { CubeTransparentOutlineIconComponent } from './components/cube-transparent-outline/cube-transparent-outline.icon.component';
import { CurrencyBangladeshiOutlineIconComponent } from './components/currency-bangladeshi-outline/currency-bangladeshi-outline.icon.component';
import { CurrencyDollarOutlineIconComponent } from './components/currency-dollar-outline/currency-dollar-outline.icon.component';
import { CurrencyEuroOutlineIconComponent } from './components/currency-euro-outline/currency-euro-outline.icon.component';
import { CurrencyPoundOutlineIconComponent } from './components/currency-pound-outline/currency-pound-outline.icon.component';
import { CurrencyRupeeOutlineIconComponent } from './components/currency-rupee-outline/currency-rupee-outline.icon.component';
import { CurrencyYenOutlineIconComponent } from './components/currency-yen-outline/currency-yen-outline.icon.component';
import { CursorClickOutlineIconComponent } from './components/cursor-click-outline/cursor-click-outline.icon.component';
import { DatabaseOutlineIconComponent } from './components/database-outline/database-outline.icon.component';
import { DesktopComputerOutlineIconComponent } from './components/desktop-computer-outline/desktop-computer-outline.icon.component';
import { DeviceMobileOutlineIconComponent } from './components/device-mobile-outline/device-mobile-outline.icon.component';
import { DeviceTabletOutlineIconComponent } from './components/device-tablet-outline/device-tablet-outline.icon.component';
import { DocumentAddOutlineIconComponent } from './components/document-add-outline/document-add-outline.icon.component';
import { DocumentDownloadOutlineIconComponent } from './components/document-download-outline/document-download-outline.icon.component';
import { DocumentDuplicateOutlineIconComponent } from './components/document-duplicate-outline/document-duplicate-outline.icon.component';
import { DocumentOutlineIconComponent } from './components/document-outline/document-outline.icon.component';
import { DocumentRemoveOutlineIconComponent } from './components/document-remove-outline/document-remove-outline.icon.component';
import { DocumentReportOutlineIconComponent } from './components/document-report-outline/document-report-outline.icon.component';
import { DocumentSearchOutlineIconComponent } from './components/document-search-outline/document-search-outline.icon.component';
import { DocumentTextOutlineIconComponent } from './components/document-text-outline/document-text-outline.icon.component';
import { DotsCircleHorizontalOutlineIconComponent } from './components/dots-circle-horizontal-outline/dots-circle-horizontal-outline.icon.component';
import { DotsHorizontalOutlineIconComponent } from './components/dots-horizontal-outline/dots-horizontal-outline.icon.component';
import { DotsVerticalOutlineIconComponent } from './components/dots-vertical-outline/dots-vertical-outline.icon.component';
import { DownloadOutlineIconComponent } from './components/download-outline/download-outline.icon.component';
import { DuplicateOutlineIconComponent } from './components/duplicate-outline/duplicate-outline.icon.component';
import { EmojiHappyOutlineIconComponent } from './components/emoji-happy-outline/emoji-happy-outline.icon.component';
import { EmojiSadOutlineIconComponent } from './components/emoji-sad-outline/emoji-sad-outline.icon.component';
import { ExclamationCircleOutlineIconComponent } from './components/exclamation-circle-outline/exclamation-circle-outline.icon.component';
import { ExclamationOutlineIconComponent } from './components/exclamation-outline/exclamation-outline.icon.component';
import { ExternalLinkOutlineIconComponent } from './components/external-link-outline/external-link-outline.icon.component';
import { EyeOffOutlineIconComponent } from './components/eye-off-outline/eye-off-outline.icon.component';
import { EyeOutlineIconComponent } from './components/eye-outline/eye-outline.icon.component';
import { FastForwardOutlineIconComponent } from './components/fast-forward-outline/fast-forward-outline.icon.component';
import { FilmOutlineIconComponent } from './components/film-outline/film-outline.icon.component';
import { FilterOutlineIconComponent } from './components/filter-outline/filter-outline.icon.component';
import { FingerPrintOutlineIconComponent } from './components/finger-print-outline/finger-print-outline.icon.component';
import { FireOutlineIconComponent } from './components/fire-outline/fire-outline.icon.component';
import { FlagOutlineIconComponent } from './components/flag-outline/flag-outline.icon.component';
import { FolderAddOutlineIconComponent } from './components/folder-add-outline/folder-add-outline.icon.component';
import { FolderDownloadOutlineIconComponent } from './components/folder-download-outline/folder-download-outline.icon.component';
import { FolderOpenOutlineIconComponent } from './components/folder-open-outline/folder-open-outline.icon.component';
import { FolderOutlineIconComponent } from './components/folder-outline/folder-outline.icon.component';
import { FolderRemoveOutlineIconComponent } from './components/folder-remove-outline/folder-remove-outline.icon.component';
import { GasStationOutlineIconComponent } from './components/gas-station-outline/gas-station-outline.icon.component';
import { GiftOutlineIconComponent } from './components/gift-outline/gift-outline.icon.component';
import { GlobeAltOutlineIconComponent } from './components/globe-alt-outline/globe-alt-outline.icon.component';
import { GlobeOutlineIconComponent } from './components/globe-outline/globe-outline.icon.component';
import { HandOutlineIconComponent } from './components/hand-outline/hand-outline.icon.component';
import { HashtagOutlineIconComponent } from './components/hashtag-outline/hashtag-outline.icon.component';
import { HeartOutlineIconComponent } from './components/heart-outline/heart-outline.icon.component';
import { HomeOutlineIconComponent } from './components/home-outline/home-outline.icon.component';
import { IdentificationOutlineIconComponent } from './components/identification-outline/identification-outline.icon.component';
import { InboxInOutlineIconComponent } from './components/inbox-in-outline/inbox-in-outline.icon.component';
import { InboxOutlineIconComponent } from './components/inbox-outline/inbox-outline.icon.component';
import { InformationCircleOutlineIconComponent } from './components/information-circle-outline/information-circle-outline.icon.component';
import { KeyOutlineIconComponent } from './components/key-outline/key-outline.icon.component';
import { LibraryOutlineIconComponent } from './components/library-outline/library-outline.icon.component';
import { LightBulbOutlineIconComponent } from './components/light-bulb-outline/light-bulb-outline.icon.component';
import { LightningBoltOutlineIconComponent } from './components/lightning-bolt-outline/lightning-bolt-outline.icon.component';
import { LinkOutlineIconComponent } from './components/link-outline/link-outline.icon.component';
import { LocationMarkerOutlineIconComponent } from './components/location-marker-outline/location-marker-outline.icon.component';
import { LockClosedOutlineIconComponent } from './components/lock-closed-outline/lock-closed-outline.icon.component';
import { LockOpenOutlineIconComponent } from './components/lock-open-outline/lock-open-outline.icon.component';
import { LoginOutlineIconComponent } from './components/login-outline/login-outline.icon.component';
import { LogoutOutlineIconComponent } from './components/logout-outline/logout-outline.icon.component';
import { MailOpenOutlineIconComponent } from './components/mail-open-outline/mail-open-outline.icon.component';
import { MailOutlineIconComponent } from './components/mail-outline/mail-outline.icon.component';
import { MapOutlineIconComponent } from './components/map-outline/map-outline.icon.component';
import { MenuAlt1OutlineIconComponent } from './components/menu-alt-1-outline/menu-alt-1-outline.icon.component';
import { MenuAlt2OutlineIconComponent } from './components/menu-alt-2-outline/menu-alt-2-outline.icon.component';
import { MenuAlt3OutlineIconComponent } from './components/menu-alt-3-outline/menu-alt-3-outline.icon.component';
import { MenuAlt4OutlineIconComponent } from './components/menu-alt-4-outline/menu-alt-4-outline.icon.component';
import { MenuOutlineIconComponent } from './components/menu-outline/menu-outline.icon.component';
import { MicrophoneOutlineIconComponent } from './components/microphone-outline/microphone-outline.icon.component';
import { MinusCircleOutlineIconComponent } from './components/minus-circle-outline/minus-circle-outline.icon.component';
import { MinusOutlineIconComponent } from './components/minus-outline/minus-outline.icon.component';
import { MinusSmOutlineIconComponent } from './components/minus-sm-outline/minus-sm-outline.icon.component';
import { MoonOutlineIconComponent } from './components/moon-outline/moon-outline.icon.component';
import { MusicNoteOutlineIconComponent } from './components/music-note-outline/music-note-outline.icon.component';
import { NewspaperOutlineIconComponent } from './components/newspaper-outline/newspaper-outline.icon.component';
import { OfficeBuildingOutlineIconComponent } from './components/office-building-outline/office-building-outline.icon.component';
import { PaperAirplaneOutlineIconComponent } from './components/paper-airplane-outline/paper-airplane-outline.icon.component';
import { PaperClipOutlineIconComponent } from './components/paper-clip-outline/paper-clip-outline.icon.component';
import { PauseOutlineIconComponent } from './components/pause-outline/pause-outline.icon.component';
import { PencilAltOutlineIconComponent } from './components/pencil-alt-outline/pencil-alt-outline.icon.component';
import { PencilOutlineIconComponent } from './components/pencil-outline/pencil-outline.icon.component';
import { PhoneIncomingOutlineIconComponent } from './components/phone-incoming-outline/phone-incoming-outline.icon.component';
import { PhoneMissedCallOutlineIconComponent } from './components/phone-missed-call-outline/phone-missed-call-outline.icon.component';
import { PhoneOutgoingOutlineIconComponent } from './components/phone-outgoing-outline/phone-outgoing-outline.icon.component';
import { PhoneOutlineIconComponent } from './components/phone-outline/phone-outline.icon.component';
import { PhotographOutlineIconComponent } from './components/photograph-outline/photograph-outline.icon.component';
import { PlayOutlineIconComponent } from './components/play-outline/play-outline.icon.component';
import { PlusCircleOutlineIconComponent } from './components/plus-circle-outline/plus-circle-outline.icon.component';
import { PlusOutlineIconComponent } from './components/plus-outline/plus-outline.icon.component';
import { PlusSmOutlineIconComponent } from './components/plus-sm-outline/plus-sm-outline.icon.component';
import { PresentationChartBarOutlineIconComponent } from './components/presentation-chart-bar-outline/presentation-chart-bar-outline.icon.component';
import { PresentationChartLineOutlineIconComponent } from './components/presentation-chart-line-outline/presentation-chart-line-outline.icon.component';
import { PrinterOutlineIconComponent } from './components/printer-outline/printer-outline.icon.component';
import { PuzzleOutlineIconComponent } from './components/puzzle-outline/puzzle-outline.icon.component';
import { QrcodeOutlineIconComponent } from './components/qrcode-outline/qrcode-outline.icon.component';
import { QuestionMarkCircleOutlineIconComponent } from './components/question-mark-circle-outline/question-mark-circle-outline.icon.component';
import { ReceiptRefundOutlineIconComponent } from './components/receipt-refund-outline/receipt-refund-outline.icon.component';
import { ReceiptTaxOutlineIconComponent } from './components/receipt-tax-outline/receipt-tax-outline.icon.component';
import { RefreshOutlineIconComponent } from './components/refresh-outline/refresh-outline.icon.component';
import { ReplyOutlineIconComponent } from './components/reply-outline/reply-outline.icon.component';
import { RewindOutlineIconComponent } from './components/rewind-outline/rewind-outline.icon.component';
import { RssOutlineIconComponent } from './components/rss-outline/rss-outline.icon.component';
import { SaveAsOutlineIconComponent } from './components/save-as-outline/save-as-outline.icon.component';
import { SaveOutlineIconComponent } from './components/save-outline/save-outline.icon.component';
import { ScaleOutlineIconComponent } from './components/scale-outline/scale-outline.icon.component';
import { ScissorsOutlineIconComponent } from './components/scissors-outline/scissors-outline.icon.component';
import { SearchOutlineIconComponent } from './components/search-outline/search-outline.icon.component';
import { SelectorOutlineIconComponent } from './components/selector-outline/selector-outline.icon.component';
import { ServerOutlineIconComponent } from './components/server-outline/server-outline.icon.component';
import { ShareOutlineIconComponent } from './components/share-outline/share-outline.icon.component';
import { ShieldCheckOutlineIconComponent } from './components/shield-check-outline/shield-check-outline.icon.component';
import { ShieldExclamationOutlineIconComponent } from './components/shield-exclamation-outline/shield-exclamation-outline.icon.component';
import { ShoppingBagOutlineIconComponent } from './components/shopping-bag-outline/shopping-bag-outline.icon.component';
import { ShoppingCartOutlineIconComponent } from './components/shopping-cart-outline/shopping-cart-outline.icon.component';
import { SortAscendingOutlineIconComponent } from './components/sort-ascending-outline/sort-ascending-outline.icon.component';
import { SortDescendingOutlineIconComponent } from './components/sort-descending-outline/sort-descending-outline.icon.component';
import { SparklesOutlineIconComponent } from './components/sparkles-outline/sparkles-outline.icon.component';
import { SpeakerphoneOutlineIconComponent } from './components/speakerphone-outline/speakerphone-outline.icon.component';
import { StarOutlineIconComponent } from './components/star-outline/star-outline.icon.component';
import { StatusOfflineOutlineIconComponent } from './components/status-offline-outline/status-offline-outline.icon.component';
import { StopOutlineIconComponent } from './components/stop-outline/stop-outline.icon.component';
import { SunOutlineIconComponent } from './components/sun-outline/sun-outline.icon.component';
import { SupportOutlineIconComponent } from './components/support-outline/support-outline.icon.component';
import { SwitchHorizontalOutlineIconComponent } from './components/switch-horizontal-outline/switch-horizontal-outline.icon.component';
import { SwitchVerticalOutlineIconComponent } from './components/switch-vertical-outline/switch-vertical-outline.icon.component';
import { TableOutlineIconComponent } from './components/table-outline/table-outline.icon.component';
import { TagOutlineIconComponent } from './components/tag-outline/tag-outline.icon.component';
import { TemplateOutlineIconComponent } from './components/template-outline/template-outline.icon.component';
import { TerminalOutlineIconComponent } from './components/terminal-outline/terminal-outline.icon.component';
import { ThumbDownOutlineIconComponent } from './components/thumb-down-outline/thumb-down-outline.icon.component';
import { ThumbUpOutlineIconComponent } from './components/thumb-up-outline/thumb-up-outline.icon.component';
import { TicketOutlineIconComponent } from './components/ticket-outline/ticket-outline.icon.component';
import { TranslateOutlineIconComponent } from './components/translate-outline/translate-outline.icon.component';
import { TrashOutlineIconComponent } from './components/trash-outline/trash-outline.icon.component';
import { TrendingDownOutlineIconComponent } from './components/trending-down-outline/trending-down-outline.icon.component';
import { TrendingUpOutlineIconComponent } from './components/trending-up-outline/trending-up-outline.icon.component';
import { TruckOutlineIconComponent } from './components/truck-outline/truck-outline.icon.component';
import { UploadOutlineIconComponent } from './components/upload-outline/upload-outline.icon.component';
import { UserAddOutlineIconComponent } from './components/user-add-outline/user-add-outline.icon.component';
import { UserCircleOutlineIconComponent } from './components/user-circle-outline/user-circle-outline.icon.component';
import { UserGroupOutlineIconComponent } from './components/user-group-outline/user-group-outline.icon.component';
import { UserOutlineIconComponent } from './components/user-outline/user-outline.icon.component';
import { UserRemoveOutlineIconComponent } from './components/user-remove-outline/user-remove-outline.icon.component';
import { UsersOutlineIconComponent } from './components/users-outline/users-outline.icon.component';
import { VariableOutlineIconComponent } from './components/variable-outline/variable-outline.icon.component';
import { VideoCameraOutlineIconComponent } from './components/video-camera-outline/video-camera-outline.icon.component';
import { ViewBoardsOutlineIconComponent } from './components/view-boards-outline/view-boards-outline.icon.component';
import { ViewGridAddOutlineIconComponent } from './components/view-grid-add-outline/view-grid-add-outline.icon.component';
import { ViewGridOutlineIconComponent } from './components/view-grid-outline/view-grid-outline.icon.component';
import { ViewListOutlineIconComponent } from './components/view-list-outline/view-list-outline.icon.component';
import { VolumeOffOutlineIconComponent } from './components/volume-off-outline/volume-off-outline.icon.component';
import { VolumeUpOutlineIconComponent } from './components/volume-up-outline/volume-up-outline.icon.component';
import { WifiOutlineIconComponent } from './components/wifi-outline/wifi-outline.icon.component';
import { XCircleOutlineIconComponent } from './components/x-circle-outline/x-circle-outline.icon.component';
import { XOutlineIconComponent } from './components/x-outline/x-outline.icon.component';
import { ZoomInOutlineIconComponent } from './components/zoom-in-outline/zoom-in-outline.icon.component';
import { ZoomOutOutlineIconComponent } from './components/zoom-out-outline/zoom-out-outline.icon.component';
import { ArrowDownOutlineIconComponent } from './components/arrow-down-outline/arrow-down-outline.icon.component';
import { ArrowLeftOutlineIconComponent } from './components/arrow-left-outline/arrow-left-outline.icon.component';
import { ArrowRightOutlineIconComponent } from './components/arrow-right-outline/arrow-right-outline.icon.component';
import { FilterAltGreyOutlineIconComponent } from './components/filter-alt-grey-outline/filter-alt-grey-outline.icon.component';
import { FilterAltOutlineIconComponent } from './components/filter-alt-outline/filter-alt-outline.icon.component';
import { SearchCircleOutlineIconComponent } from './components/search-circle-outline/search-circle-outline.icon.component';
import { StatusOnlineOutlineIconComponent } from './components/status-online-outline/status-online-outline.icon.component';
import { WebOutlineIconComponent } from './components/web-outline/web-outline.icon.component';
import { AdjustmentsSolidIconComponent } from './components/adjustments-solid/adjustments-solid.icon.component';
import { AnnotationSolidIconComponent } from './components/annotation-solid/annotation-solid.icon.component';
import { ArchiveSolidIconComponent } from './components/archive-solid/archive-solid.icon.component';
import { ArrowCircleDownSolidIconComponent } from './components/arrow-circle-down-solid/arrow-circle-down-solid.icon.component';
import { ArrowCircleLeftSolidIconComponent } from './components/arrow-circle-left-solid/arrow-circle-left-solid.icon.component';
import { ArrowCircleRightSolidIconComponent } from './components/arrow-circle-right-solid/arrow-circle-right-solid.icon.component';
import { ArrowCircleUpSolidIconComponent } from './components/arrow-circle-up-solid/arrow-circle-up-solid.icon.component';
import { ArrowDownSolidIconComponent } from './components/arrow-down-solid/arrow-down-solid.icon.component';
import { ArrowLeftSolidIconComponent } from './components/arrow-left-solid/arrow-left-solid.icon.component';
import { ArrowNarrowDownSolidIconComponent } from './components/arrow-narrow-down-solid/arrow-narrow-down-solid.icon.component';
import { ArrowNarrowLeftSolidIconComponent } from './components/arrow-narrow-left-solid/arrow-narrow-left-solid.icon.component';
import { ArrowNarrowRightSolidIconComponent } from './components/arrow-narrow-right-solid/arrow-narrow-right-solid.icon.component';
import { ArrowNarrowUpSolidIconComponent } from './components/arrow-narrow-up-solid/arrow-narrow-up-solid.icon.component';
import { ArrowRightSolidIconComponent } from './components/arrow-right-solid/arrow-right-solid.icon.component';
import { ArrowUpSolidIconComponent } from './components/arrow-up-solid/arrow-up-solid.icon.component';
import { AtSymbolSolidIconComponent } from './components/at-symbol-solid/at-symbol-solid.icon.component';
import { BellSolidIconComponent } from './components/bell-solid/bell-solid.icon.component';
import { BookOpenSolidIconComponent } from './components/book-open-solid/book-open-solid.icon.component';
import { BookmarkSolidIconComponent } from './components/bookmark-solid/bookmark-solid.icon.component';
import { BriefcaseSolidIconComponent } from './components/briefcase-solid/briefcase-solid.icon.component';
import { CalendarSolidIconComponent } from './components/calendar-solid/calendar-solid.icon.component';
import { CameraSolidIconComponent } from './components/camera-solid/camera-solid.icon.component';
import { CashSolidIconComponent } from './components/cash-solid/cash-solid.icon.component';
import { ChartPieSolidIconComponent } from './components/chart-pie-solid/chart-pie-solid.icon.component';
import { ChatSolidIconComponent } from './components/chat-solid/chat-solid.icon.component';
import { CheckCircleSolidIconComponent } from './components/check-circle-solid/check-circle-solid.icon.component';
import { CheckSolidIconComponent } from './components/check-solid/check-solid.icon.component';
import { ChevronDownSolidIconComponent } from './components/chevron-down-solid/chevron-down-solid.icon.component';
import { ChevronLeftSolidIconComponent } from './components/chevron-left-solid/chevron-left-solid.icon.component';
import { ChevronRightSolidIconComponent } from './components/chevron-right-solid/chevron-right-solid.icon.component';
import { ChevronUpSolidIconComponent } from './components/chevron-up-solid/chevron-up-solid.icon.component';
import { ClipboardCheckSolidIconComponent } from './components/clipboard-check-solid/clipboard-check-solid.icon.component';
import { ClipboardCopySolidIconComponent } from './components/clipboard-copy-solid/clipboard-copy-solid.icon.component';
import { ClipboardListSolidIconComponent } from './components/clipboard-list-solid/clipboard-list-solid.icon.component';
import { ClipboardSolidIconComponent } from './components/clipboard-solid/clipboard-solid.icon.component';
import { ClockSolidIconComponent } from './components/clock-solid/clock-solid.icon.component';
import { CodeSolidIconComponent } from './components/code-solid/code-solid.icon.component';
import { CogSolidIconComponent } from './components/cog-solid/cog-solid.icon.component';
import { CollectionSolidIconComponent } from './components/collection-solid/collection-solid.icon.component';
import { ColorSwatchSolidIconComponent } from './components/color-swatch-solid/color-swatch-solid.icon.component';
import { CreditCardSolidIconComponent } from './components/credit-card-solid/credit-card-solid.icon.component';
import { CurrencyDollarSolidIconComponent } from './components/currency-dollar-solid/currency-dollar-solid.icon.component';
import { CurrencyEuroSolidIconComponent } from './components/currency-euro-solid/currency-euro-solid.icon.component';
import { CurrencyPoundSolidIconComponent } from './components/currency-pound-solid/currency-pound-solid.icon.component';
import { CurrencyRupeeSolidIconComponent } from './components/currency-rupee-solid/currency-rupee-solid.icon.component';
import { CurrencyYenSolidIconComponent } from './components/currency-yen-solid/currency-yen-solid.icon.component';
import { DocumentAddSolidIconComponent } from './components/document-add-solid/document-add-solid.icon.component';
import { DocumentDownloadSolidIconComponent } from './components/document-download-solid/document-download-solid.icon.component';
import { DocumentDuplicateSolidIconComponent } from './components/document-duplicate-solid/document-duplicate-solid.icon.component';
import { DocumentRemoveSolidIconComponent } from './components/document-remove-solid/document-remove-solid.icon.component';
import { DocumentSolidIconComponent } from './components/document-solid/document-solid.icon.component';
import { DotsCircleHorizontalSolidIconComponent } from './components/dots-circle-horizontal-solid/dots-circle-horizontal-solid.icon.component';
import { DotsHorizontalSolidIconComponent } from './components/dots-horizontal-solid/dots-horizontal-solid.icon.component';
import { DotsVerticalSolidIconComponent } from './components/dots-vertical-solid/dots-vertical-solid.icon.component';
import { DownloadSolidIconComponent } from './components/download-solid/download-solid.icon.component';
import { DuplicateSolidIconComponent } from './components/duplicate-solid/duplicate-solid.icon.component';
import { EmojiHappySolidIconComponent } from './components/emoji-happy-solid/emoji-happy-solid.icon.component';
import { EmojiSadSolidIconComponent } from './components/emoji-sad-solid/emoji-sad-solid.icon.component';
import { ExclamationCircleSolidIconComponent } from './components/exclamation-circle-solid/exclamation-circle-solid.icon.component';
import { ExclamationSolidIconComponent } from './components/exclamation-solid/exclamation-solid.icon.component';
import { ExternalLinkSolidIconComponent } from './components/external-link-solid/external-link-solid.icon.component';
import { EyeSolidIconComponent } from './components/eye-solid/eye-solid.icon.component';
import { FilterSolidIconComponent } from './components/filter-solid/filter-solid.icon.component';
import { FlagSolidIconComponent } from './components/flag-solid/flag-solid.icon.component';
import { FolderSolidIconComponent } from './components/folder-solid/folder-solid.icon.component';
import { GasStationSolidIconComponent } from './components/gas-station-solid/gas-station-solid.icon.component';
import { GlobeAltSolidIconComponent } from './components/globe-alt-solid/globe-alt-solid.icon.component';
import { GlobeSolidIconComponent } from './components/globe-solid/globe-solid.icon.component';
import { HashtagSolidIconComponent } from './components/hashtag-solid/hashtag-solid.icon.component';
import { HeartSolidIconComponent } from './components/heart-solid/heart-solid.icon.component';
import { HomeSolidIconComponent } from './components/home-solid/home-solid.icon.component';
import { InboxInSolidIconComponent } from './components/inbox-in-solid/inbox-in-solid.icon.component';
import { InboxSolidIconComponent } from './components/inbox-solid/inbox-solid.icon.component';
import { InformationCircleSolidIconComponent } from './components/information-circle-solid/information-circle-solid.icon.component';
import { LightBulbSolidIconComponent } from './components/light-bulb-solid/light-bulb-solid.icon.component';
import { LightningBoltSolidIconComponent } from './components/lightning-bolt-solid/lightning-bolt-solid.icon.component';
import { LinkSolidIconComponent } from './components/link-solid/link-solid.icon.component';
import { LocationMarkerSolidIconComponent } from './components/location-marker-solid/location-marker-solid.icon.component';
import { LockClosedSolidIconComponent } from './components/lock-closed-solid/lock-closed-solid.icon.component';
import { LockOpenSolidIconComponent } from './components/lock-open-solid/lock-open-solid.icon.component';
import { MailOpenSolidIconComponent } from './components/mail-open-solid/mail-open-solid.icon.component';
import { MailSolidIconComponent } from './components/mail-solid/mail-solid.icon.component';
import { MenuAlt1SolidIconComponent } from './components/menu-alt-1-solid/menu-alt-1-solid.icon.component';
import { MenuAlt2SolidIconComponent } from './components/menu-alt-2-solid/menu-alt-2-solid.icon.component';
import { MenuAlt3SolidIconComponent } from './components/menu-alt-3-solid/menu-alt-3-solid.icon.component';
import { MenuAlt4SolidIconComponent } from './components/menu-alt-4-solid/menu-alt-4-solid.icon.component';
import { MenuSolidIconComponent } from './components/menu-solid/menu-solid.icon.component';
import { MinusCircleSolidIconComponent } from './components/minus-circle-solid/minus-circle-solid.icon.component';
import { MoonSolidIconComponent } from './components/moon-solid/moon-solid.icon.component';
import { OfficeBuildingSolidIconComponent } from './components/office-building-solid/office-building-solid.icon.component';
import { PaperClipSolidIconComponent } from './components/paper-clip-solid/paper-clip-solid.icon.component';
import { PencilAltSolidIconComponent } from './components/pencil-alt-solid/pencil-alt-solid.icon.component';
import { PencilSolidIconComponent } from './components/pencil-solid/pencil-solid.icon.component';
import { PhoneIncomingSolidIconComponent } from './components/phone-incoming-solid/phone-incoming-solid.icon.component';
import { PhoneOutgoingSolidIconComponent } from './components/phone-outgoing-solid/phone-outgoing-solid.icon.component';
import { PhoneSolidIconComponent } from './components/phone-solid/phone-solid.icon.component';
import { PhotographSolidIconComponent } from './components/photograph-solid/photograph-solid.icon.component';
import { PlusCircleSolidIconComponent } from './components/plus-circle-solid/plus-circle-solid.icon.component';
import { PrinterSolidIconComponent } from './components/printer-solid/printer-solid.icon.component';
import { QrcodeSolidIconComponent } from './components/qrcode-solid/qrcode-solid.icon.component';
import { QuestionMarkCircleSolidIconComponent } from './components/question-mark-circle-solid/question-mark-circle-solid.icon.component';
import { ReceiptRefundSolidIconComponent } from './components/receipt-refund-solid/receipt-refund-solid.icon.component';
import { RefreshSolidIconComponent } from './components/refresh-solid/refresh-solid.icon.component';
import { ReplySolidIconComponent } from './components/reply-solid/reply-solid.icon.component';
import { ScaleSolidIconComponent } from './components/scale-solid/scale-solid.icon.component';
import { SearchSolidIconComponent } from './components/search-solid/search-solid.icon.component';
import { SelectorSolidIconComponent } from './components/selector-solid/selector-solid.icon.component';
import { ShareSolidIconComponent } from './components/share-solid/share-solid.icon.component';
import { ShieldCheckSolidIconComponent } from './components/shield-check-solid/shield-check-solid.icon.component';
import { ShieldExclamationSolidIconComponent } from './components/shield-exclamation-solid/shield-exclamation-solid.icon.component';
import { SortAscendingSolidIconComponent } from './components/sort-ascending-solid/sort-ascending-solid.icon.component';
import { SortDescendingSolidIconComponent } from './components/sort-descending-solid/sort-descending-solid.icon.component';
import { SparklesSolidIconComponent } from './components/sparkles-solid/sparkles-solid.icon.component';
import { SunSolidIconComponent } from './components/sun-solid/sun-solid.icon.component';
import { SwitchHorizontalSolidIconComponent } from './components/switch-horizontal-solid/switch-horizontal-solid.icon.component';
import { SwitchVerticalSolidIconComponent } from './components/switch-vertical-solid/switch-vertical-solid.icon.component';
import { TagSolidIconComponent } from './components/tag-solid/tag-solid.icon.component';
import { TemplateSolidIconComponent } from './components/template-solid/template-solid.icon.component';
import { TicketSolidIconComponent } from './components/ticket-solid/ticket-solid.icon.component';
import { TranslateSolidIconComponent } from './components/translate-solid/translate-solid.icon.component';
import { TrashSolidIconComponent } from './components/trash-solid/trash-solid.icon.component';
import { TrendingDownSolidIconComponent } from './components/trending-down-solid/trending-down-solid.icon.component';
import { TrendingUpSolidIconComponent } from './components/trending-up-solid/trending-up-solid.icon.component';
import { TruckSolidIconComponent } from './components/truck-solid/truck-solid.icon.component';
import { UploadSolidIconComponent } from './components/upload-solid/upload-solid.icon.component';
import { UserAddSolidIconComponent } from './components/user-add-solid/user-add-solid.icon.component';
import { UserCircleSolidIconComponent } from './components/user-circle-solid/user-circle-solid.icon.component';
import { UserGroupSolidIconComponent } from './components/user-group-solid/user-group-solid.icon.component';
import { UserRemoveSolidIconComponent } from './components/user-remove-solid/user-remove-solid.icon.component';
import { UserSolidIconComponent } from './components/user-solid/user-solid.icon.component';
import { UsersSolidIconComponent } from './components/users-solid/users-solid.icon.component';
import { ViewBoardsSolidIconComponent } from './components/view-boards-solid/view-boards-solid.icon.component';
import { ViewListSolidIconComponent } from './components/view-list-solid/view-list-solid.icon.component';
import { VolumeOffSolidIconComponent } from './components/volume-off-solid/volume-off-solid.icon.component';
import { VolumeUpSolidIconComponent } from './components/volume-up-solid/volume-up-solid.icon.component';
import { XCircleSolidIconComponent } from './components/x-circle-solid/x-circle-solid.icon.component';
import { XSolidIconComponent } from './components/x-solid/x-solid.icon.component';
import { ZoomInSolidIconComponent } from './components/zoom-in-solid/zoom-in-solid.icon.component';
import { ZoomOutSolidIconComponent } from './components/zoom-out-solid/zoom-out-solid.icon.component';
import { AcademicCapSolidIconComponent } from './components/academic-cap-solid/academic-cap-solid.icon.component';
import { BackspaceSolidIconComponent } from './components/backspace-solid/backspace-solid.icon.component';
import { BeakerSolidIconComponent } from './components/beaker-solid/beaker-solid.icon.component';
import { CakeSolidIconComponent } from './components/cake-solid/cake-solid.icon.component';
import { CalculatorSolidIconComponent } from './components/calculator-solid/calculator-solid.icon.component';
import { ChevronDoubleDownSolidIconComponent } from './components/chevron-double-down-solid/chevron-double-down-solid.icon.component';
import { ChevronDoubleLeftSolidIconComponent } from './components/chevron-double-left-solid/chevron-double-left-solid.icon.component';
import { ChevronDoubleRightSolidIconComponent } from './components/chevron-double-right-solid/chevron-double-right-solid.icon.component';
import { ChevronDoubleUpSolidIconComponent } from './components/chevron-double-up-solid/chevron-double-up-solid.icon.component';
import { ChipSolidIconComponent } from './components/chip-solid/chip-solid.icon.component';
import { CloudDownloadSolidIconComponent } from './components/cloud-download-solid/cloud-download-solid.icon.component';
import { CloudSolidIconComponent } from './components/cloud-solid/cloud-solid.icon.component';
import { CloudUploadSolidIconComponent } from './components/cloud-upload-solid/cloud-upload-solid.icon.component';
import { CubeSolidIconComponent } from './components/cube-solid/cube-solid.icon.component';
import { CubeTransparentSolidIconComponent } from './components/cube-transparent-solid/cube-transparent-solid.icon.component';
import { CurrencyBangladeshiSolidIconComponent } from './components/currency-bangladeshi-solid/currency-bangladeshi-solid.icon.component';
import { DatabaseSolidIconComponent } from './components/database-solid/database-solid.icon.component';
import { DeviceMobileSolidIconComponent } from './components/device-mobile-solid/device-mobile-solid.icon.component';
import { DeviceTabletSolidIconComponent } from './components/device-tablet-solid/device-tablet-solid.icon.component';
import { DocumentSearchSolidIconComponent } from './components/document-search-solid/document-search-solid.icon.component';
import { DocumentTextSolidIconComponent } from './components/document-text-solid/document-text-solid.icon.component';
import { FastForwardSolidIconComponent } from './components/fast-forward-solid/fast-forward-solid.icon.component';
import { FilmSolidIconComponent } from './components/film-solid/film-solid.icon.component';
import { FingerPrintSolidIconComponent } from './components/finger-print-solid/finger-print-solid.icon.component';
import { FireSolidIconComponent } from './components/fire-solid/fire-solid.icon.component';
import { FolderOpenSolidIconComponent } from './components/folder-open-solid/folder-open-solid.icon.component';
import { GiftSolidIconComponent } from './components/gift-solid/gift-solid.icon.component';
import { HandSolidIconComponent } from './components/hand-solid/hand-solid.icon.component';
import { IdentificationSolidIconComponent } from './components/identification-solid/identification-solid.icon.component';
import { LoginSolidIconComponent } from './components/login-solid/login-solid.icon.component';
import { LogoutSolidIconComponent } from './components/logout-solid/logout-solid.icon.component';
import { MapSolidIconComponent } from './components/map-solid/map-solid.icon.component';
import { MinusSmSolidIconComponent } from './components/minus-sm-solid/minus-sm-solid.icon.component';
import { MinusSolidIconComponent } from './components/minus-solid/minus-solid.icon.component';
import { MusicNoteSolidIconComponent } from './components/music-note-solid/music-note-solid.icon.component';
import { PaperAirplaneSolidIconComponent } from './components/paper-airplane-solid/paper-airplane-solid.icon.component';
import { PhoneMissedCallSolidIconComponent } from './components/phone-missed-call-solid/phone-missed-call-solid.icon.component';
import { PlusSmSolidIconComponent } from './components/plus-sm-solid/plus-sm-solid.icon.component';
import { PlusSolidIconComponent } from './components/plus-solid/plus-solid.icon.component';
import { PresentationChartBarSolidIconComponent } from './components/presentation-chart-bar-solid/presentation-chart-bar-solid.icon.component';
import { PresentationChartLineSolidIconComponent } from './components/presentation-chart-line-solid/presentation-chart-line-solid.icon.component';
import { PuzzleSolidIconComponent } from './components/puzzle-solid/puzzle-solid.icon.component';
import { ReceiptTaxSolidIconComponent } from './components/receipt-tax-solid/receipt-tax-solid.icon.component';
import { RewindSolidIconComponent } from './components/rewind-solid/rewind-solid.icon.component';
import { RssSolidIconComponent } from './components/rss-solid/rss-solid.icon.component';
import { SaveAsSolidIconComponent } from './components/save-as-solid/save-as-solid.icon.component';
import { SaveSolidIconComponent } from './components/save-solid/save-solid.icon.component';
import { ScissorsSolidIconComponent } from './components/scissors-solid/scissors-solid.icon.component';
import { SearchCircleSolidIconComponent } from './components/search-circle-solid/search-circle-solid.icon.component';
import { ServerSolidIconComponent } from './components/server-solid/server-solid.icon.component';
import { ShoppingBagSolidIconComponent } from './components/shopping-bag-solid/shopping-bag-solid.icon.component';
import { StatusOfflineSolidIconComponent } from './components/status-offline-solid/status-offline-solid.icon.component';
import { StatusOnlineSolidIconComponent } from './components/status-online-solid/status-online-solid.icon.component';
import { TableSolidIconComponent } from './components/table-solid/table-solid.icon.component';
import { ThumbDownSolidIconComponent } from './components/thumb-down-solid/thumb-down-solid.icon.component';
import { ThumbUpSolidIconComponent } from './components/thumb-up-solid/thumb-up-solid.icon.component';
import { VariableSolidIconComponent } from './components/variable-solid/variable-solid.icon.component';
import { VideoCameraSolidIconComponent } from './components/video-camera-solid/video-camera-solid.icon.component';
import { ViewGridSolidIconComponent } from './components/view-grid-solid/view-grid-solid.icon.component';
import { WifiSolidIconComponent } from './components/wifi-solid/wifi-solid.icon.component';
import { BadgeCheckSolidIconComponent } from './components/badge-check-solid/badge-check-solid.icon.component';
import { BanSolidIconComponent } from './components/ban-solid/ban-solid.icon.component';
import { BookmarkAltSolidIconComponent } from './components/bookmark-alt-solid/bookmark-alt-solid.icon.component';
import { ChartBarSolidIconComponent } from './components/chart-bar-solid/chart-bar-solid.icon.component';
import { ChartSquareBarSolidIconComponent } from './components/chart-square-bar-solid/chart-square-bar-solid.icon.component';
import { ChatAlt2SolidIconComponent } from './components/chat-alt-2-solid/chat-alt-2-solid.icon.component';
import { ChatAltSolidIconComponent } from './components/chat-alt-solid/chat-alt-solid.icon.component';
import { CursorClickSolidIconComponent } from './components/cursor-click-solid/cursor-click-solid.icon.component';
import { DesktopComputerSolidIconComponent } from './components/desktop-computer-solid/desktop-computer-solid.icon.component';
import { DocumentReportSolidIconComponent } from './components/document-report-solid/document-report-solid.icon.component';
import { EyeOffSolidIconComponent } from './components/eye-off-solid/eye-off-solid.icon.component';
import { KeySolidIconComponent } from './components/key-solid/key-solid.icon.component';
import { LibrarySolidIconComponent } from './components/library-solid/library-solid.icon.component';
import { MicrophoneSolidIconComponent } from './components/microphone-solid/microphone-solid.icon.component';
import { NewspaperSolidIconComponent } from './components/newspaper-solid/newspaper-solid.icon.component';
import { PauseSolidIconComponent } from './components/pause-solid/pause-solid.icon.component';
import { PlaySolidIconComponent } from './components/play-solid/play-solid.icon.component';
import { ShoppingCartSolidIconComponent } from './components/shopping-cart-solid/shopping-cart-solid.icon.component';
import { SpeakerphoneSolidIconComponent } from './components/speakerphone-solid/speakerphone-solid.icon.component';
import { StarSolidIconComponent } from './components/star-solid/star-solid.icon.component';
import { StopSolidIconComponent } from './components/stop-solid/stop-solid.icon.component';
import { SupportSolidIconComponent } from './components/support-solid/support-solid.icon.component';
import { TerminalSolidIconComponent } from './components/terminal-solid/terminal-solid.icon.component';
import { ViewGridAddSolidIconComponent } from './components/view-grid-add-solid/view-grid-add-solid.icon.component';
import { ArrowsExpandSolidIconComponent } from './components/arrows-expand-solid/arrows-expand-solid.icon.component';
import { FolderAddSolidIconComponent } from './components/folder-add-solid/folder-add-solid.icon.component';
import { FolderDownloadSolidIconComponent } from './components/folder-download-solid/folder-download-solid.icon.component';
import { FolderRemoveSolidIconComponent } from './components/folder-remove-solid/folder-remove-solid.icon.component';
import { ManageSolidIconComponent } from './components/manage-solid/manage-solid.icon.component';
import { NotesFilledSolidIconComponent } from './components/notes-filled-solid/notes-filled-solid.icon.component';
import { NotesSolidIconComponent } from './components/notes-solid/notes-solid.icon.component';
import { RemoveSolidIconComponent } from './components/remove-solid/remove-solid.icon.component';
import { RetrySolidIconComponent } from './components/retry-solid/retry-solid.icon.component';
import { DollarComponent } from './components/dollar/dollar.component';
import { EyeOffComponent } from './components/eye-off/eye-off.component';
import { EyeOnComponent } from './components/eye-on/eye-on.component';
import { TriangleDownComponent } from './components/triangle-down/triangle-down.component';

@NgModule({
  declarations: [
    BaseIconComponent,
    AcademicCapOutlineIconComponent,
    AdjustmentsOutlineIconComponent,
    AnnotationOutlineIconComponent,
    ArchiveOutlineIconComponent,
    ArrowCircleDownOutlineIconComponent,
    ArrowCircleLeftOutlineIconComponent,
    ArrowCircleRightOutlineIconComponent,
    ArrowCircleUpOutlineIconComponent,
    ArrowNarrowDownOutlineIconComponent,
    ArrowNarrowLeftOutlineIconComponent,
    ArrowNarrowRightOutlineIconComponent,
    ArrowNarrowUpOutlineIconComponent,
    ArrowUpOutlineIconComponent,
    ArrowsExpandOutlineIconComponent,
    AtSymbolOutlineIconComponent,
    BackspaceOutlineIconComponent,
    BadgeCheckOutlineIconComponent,
    BanOutlineIconComponent,
    BeakerOutlineIconComponent,
    BellOutlineIconComponent,
    BookOpenOutlineIconComponent,
    BookmarkAltOutlineIconComponent,
    BookmarkOutlineIconComponent,
    BriefcaseOutlineIconComponent,
    CakeOutlineIconComponent,
    CalculatorOutlineIconComponent,
    CalendarOutlineIconComponent,
    CameraOutlineIconComponent,
    CashOutlineIconComponent,
    ChartBarOutlineIconComponent,
    ChartPieOutlineIconComponent,
    ChartSquareBarOutlineIconComponent,
    ChatAlt2OutlineIconComponent,
    ChatAltOutlineIconComponent,
    ChatOutlineIconComponent,
    CheckCircleOutlineIconComponent,
    CheckOutlineIconComponent,
    ChevronDoubleDownOutlineIconComponent,
    ChevronDoubleLeftOutlineIconComponent,
    ChevronDoubleRightOutlineIconComponent,
    ChevronDoubleUpOutlineIconComponent,
    ChevronDownOutlineIconComponent,
    ChevronLeftOutlineIconComponent,
    ChevronRightOutlineIconComponent,
    ChevronUpOutlineIconComponent,
    ChipOutlineIconComponent,
    ClipboardCheckOutlineIconComponent,
    ClipboardCopyOutlineIconComponent,
    ClipboardListOutlineIconComponent,
    ClipboardOutlineIconComponent,
    ClockOutlineIconComponent,
    CloudDownloadOutlineIconComponent,
    CloudOutlineIconComponent,
    CloudUploadOutlineIconComponent,
    CodeOutlineIconComponent,
    CogOutlineIconComponent,
    CollectionOutlineIconComponent,
    ColorSwatchOutlineIconComponent,
    CreditCardOutlineIconComponent,
    CubeOutlineIconComponent,
    CubeTransparentOutlineIconComponent,
    CurrencyBangladeshiOutlineIconComponent,
    CurrencyDollarOutlineIconComponent,
    CurrencyEuroOutlineIconComponent,
    CurrencyPoundOutlineIconComponent,
    CurrencyRupeeOutlineIconComponent,
    CurrencyYenOutlineIconComponent,
    CursorClickOutlineIconComponent,
    DatabaseOutlineIconComponent,
    DesktopComputerOutlineIconComponent,
    DeviceMobileOutlineIconComponent,
    DeviceTabletOutlineIconComponent,
    DocumentAddOutlineIconComponent,
    DocumentDownloadOutlineIconComponent,
    DocumentDuplicateOutlineIconComponent,
    DocumentOutlineIconComponent,
    DocumentRemoveOutlineIconComponent,
    DocumentReportOutlineIconComponent,
    DocumentSearchOutlineIconComponent,
    DocumentTextOutlineIconComponent,
    DotsCircleHorizontalOutlineIconComponent,
    DotsHorizontalOutlineIconComponent,
    DotsVerticalOutlineIconComponent,
    DownloadOutlineIconComponent,
    DuplicateOutlineIconComponent,
    EmojiHappyOutlineIconComponent,
    EmojiSadOutlineIconComponent,
    ExclamationCircleOutlineIconComponent,
    ExclamationOutlineIconComponent,
    ExternalLinkOutlineIconComponent,
    EyeOffOutlineIconComponent,
    EyeOutlineIconComponent,
    FastForwardOutlineIconComponent,
    FilmOutlineIconComponent,
    FilterOutlineIconComponent,
    FingerPrintOutlineIconComponent,
    FireOutlineIconComponent,
    FlagOutlineIconComponent,
    FolderAddOutlineIconComponent,
    FolderDownloadOutlineIconComponent,
    FolderOpenOutlineIconComponent,
    FolderOutlineIconComponent,
    FolderRemoveOutlineIconComponent,
    GiftOutlineIconComponent,
    GlobeAltOutlineIconComponent,
    GlobeOutlineIconComponent,
    HandOutlineIconComponent,
    HashtagOutlineIconComponent,
    HeartOutlineIconComponent,
    HomeOutlineIconComponent,
    IdentificationOutlineIconComponent,
    InboxInOutlineIconComponent,
    InboxOutlineIconComponent,
    InformationCircleOutlineIconComponent,
    KeyOutlineIconComponent,
    LibraryOutlineIconComponent,
    LightBulbOutlineIconComponent,
    LightningBoltOutlineIconComponent,
    LinkOutlineIconComponent,
    LocationMarkerOutlineIconComponent,
    LockClosedOutlineIconComponent,
    LockOpenOutlineIconComponent,
    LoginOutlineIconComponent,
    LogoutOutlineIconComponent,
    MailOpenOutlineIconComponent,
    MailOutlineIconComponent,
    MapOutlineIconComponent,
    MenuAlt1OutlineIconComponent,
    MenuAlt2OutlineIconComponent,
    MenuAlt3OutlineIconComponent,
    MenuAlt4OutlineIconComponent,
    MenuOutlineIconComponent,
    MicrophoneOutlineIconComponent,
    MinusCircleOutlineIconComponent,
    MinusOutlineIconComponent,
    MinusSmOutlineIconComponent,
    MoonOutlineIconComponent,
    MusicNoteOutlineIconComponent,
    NewspaperOutlineIconComponent,
    OfficeBuildingOutlineIconComponent,
    PaperAirplaneOutlineIconComponent,
    PaperClipOutlineIconComponent,
    PauseOutlineIconComponent,
    PencilAltOutlineIconComponent,
    PencilOutlineIconComponent,
    PhoneIncomingOutlineIconComponent,
    PhoneMissedCallOutlineIconComponent,
    PhoneOutgoingOutlineIconComponent,
    PhoneOutlineIconComponent,
    PhotographOutlineIconComponent,
    PlayOutlineIconComponent,
    PlusCircleOutlineIconComponent,
    PlusOutlineIconComponent,
    PlusSmOutlineIconComponent,
    PresentationChartBarOutlineIconComponent,
    PresentationChartLineOutlineIconComponent,
    PrinterOutlineIconComponent,
    PuzzleOutlineIconComponent,
    QrcodeOutlineIconComponent,
    QuestionMarkCircleOutlineIconComponent,
    ReceiptRefundOutlineIconComponent,
    ReceiptTaxOutlineIconComponent,
    RefreshOutlineIconComponent,
    ReplyOutlineIconComponent,
    RewindOutlineIconComponent,
    RssOutlineIconComponent,
    SaveAsOutlineIconComponent,
    SaveOutlineIconComponent,
    ScaleOutlineIconComponent,
    ScissorsOutlineIconComponent,
    SearchOutlineIconComponent,
    SelectorOutlineIconComponent,
    ServerOutlineIconComponent,
    ShareOutlineIconComponent,
    ShieldCheckOutlineIconComponent,
    ShieldExclamationOutlineIconComponent,
    ShoppingBagOutlineIconComponent,
    ShoppingCartOutlineIconComponent,
    SortAscendingOutlineIconComponent,
    SortDescendingOutlineIconComponent,
    SparklesOutlineIconComponent,
    SpeakerphoneOutlineIconComponent,
    StarOutlineIconComponent,
    StatusOfflineOutlineIconComponent,
    StopOutlineIconComponent,
    SunOutlineIconComponent,
    SupportOutlineIconComponent,
    SwitchHorizontalOutlineIconComponent,
    SwitchVerticalOutlineIconComponent,
    TableOutlineIconComponent,
    TagOutlineIconComponent,
    TemplateOutlineIconComponent,
    TerminalOutlineIconComponent,
    ThumbDownOutlineIconComponent,
    ThumbUpOutlineIconComponent,
    TicketOutlineIconComponent,
    TranslateOutlineIconComponent,
    TrashOutlineIconComponent,
    TrendingDownOutlineIconComponent,
    TrendingUpOutlineIconComponent,
    TruckOutlineIconComponent,
    UploadOutlineIconComponent,
    UserAddOutlineIconComponent,
    UserCircleOutlineIconComponent,
    UserGroupOutlineIconComponent,
    UserOutlineIconComponent,
    UserRemoveOutlineIconComponent,
    UsersOutlineIconComponent,
    VariableOutlineIconComponent,
    VideoCameraOutlineIconComponent,
    ViewBoardsOutlineIconComponent,
    ViewGridAddOutlineIconComponent,
    ViewGridOutlineIconComponent,
    ViewListOutlineIconComponent,
    VolumeOffOutlineIconComponent,
    VolumeUpOutlineIconComponent,
    WifiOutlineIconComponent,
    XCircleOutlineIconComponent,
    XOutlineIconComponent,
    ZoomInOutlineIconComponent,
    ZoomOutOutlineIconComponent,
    ArrowDownOutlineIconComponent,
    ArrowLeftOutlineIconComponent,
    ArrowRightOutlineIconComponent,
    FilterAltGreyOutlineIconComponent,
    FilterAltOutlineIconComponent,
    SearchCircleOutlineIconComponent,
    StatusOnlineOutlineIconComponent,
    WebOutlineIconComponent,
    AdjustmentsSolidIconComponent,
    AnnotationSolidIconComponent,
    ArchiveSolidIconComponent,
    ArrowCircleDownSolidIconComponent,
    ArrowCircleLeftSolidIconComponent,
    ArrowCircleRightSolidIconComponent,
    ArrowCircleUpSolidIconComponent,
    ArrowDownSolidIconComponent,
    ArrowLeftSolidIconComponent,
    ArrowNarrowDownSolidIconComponent,
    ArrowNarrowLeftSolidIconComponent,
    ArrowNarrowRightSolidIconComponent,
    ArrowNarrowUpSolidIconComponent,
    ArrowRightSolidIconComponent,
    ArrowUpSolidIconComponent,
    AtSymbolSolidIconComponent,
    BellSolidIconComponent,
    BookOpenSolidIconComponent,
    BookmarkSolidIconComponent,
    BriefcaseSolidIconComponent,
    CalendarSolidIconComponent,
    CameraSolidIconComponent,
    CashSolidIconComponent,
    ChartPieSolidIconComponent,
    ChatSolidIconComponent,
    CheckCircleSolidIconComponent,
    CheckSolidIconComponent,
    ChevronDownSolidIconComponent,
    ChevronLeftSolidIconComponent,
    ChevronRightSolidIconComponent,
    ChevronUpSolidIconComponent,
    ClipboardCheckSolidIconComponent,
    ClipboardCopySolidIconComponent,
    ClipboardListSolidIconComponent,
    ClipboardSolidIconComponent,
    ClockSolidIconComponent,
    CodeSolidIconComponent,
    CogSolidIconComponent,
    CollectionSolidIconComponent,
    ColorSwatchSolidIconComponent,
    CreditCardSolidIconComponent,
    CurrencyDollarSolidIconComponent,
    CurrencyEuroSolidIconComponent,
    CurrencyPoundSolidIconComponent,
    CurrencyRupeeSolidIconComponent,
    CurrencyYenSolidIconComponent,
    DocumentAddSolidIconComponent,
    DocumentDownloadSolidIconComponent,
    DocumentDuplicateSolidIconComponent,
    DocumentRemoveSolidIconComponent,
    DocumentSolidIconComponent,
    DotsCircleHorizontalSolidIconComponent,
    DotsHorizontalSolidIconComponent,
    DotsVerticalSolidIconComponent,
    DownloadSolidIconComponent,
    DuplicateSolidIconComponent,
    EmojiHappySolidIconComponent,
    EmojiSadSolidIconComponent,
    ExclamationCircleSolidIconComponent,
    ExclamationSolidIconComponent,
    ExternalLinkSolidIconComponent,
    EyeSolidIconComponent,
    FilterSolidIconComponent,
    FlagSolidIconComponent,
    FolderSolidIconComponent,
    GasStationSolidIconComponent,
    GasStationOutlineIconComponent,
    GlobeAltSolidIconComponent,
    GlobeSolidIconComponent,
    HashtagSolidIconComponent,
    HeartSolidIconComponent,
    HomeSolidIconComponent,
    InboxInSolidIconComponent,
    InboxSolidIconComponent,
    InformationCircleSolidIconComponent,
    LightBulbSolidIconComponent,
    LightningBoltSolidIconComponent,
    LinkSolidIconComponent,
    LocationMarkerSolidIconComponent,
    LockClosedSolidIconComponent,
    LockOpenSolidIconComponent,
    MailOpenSolidIconComponent,
    MailSolidIconComponent,
    MenuAlt1SolidIconComponent,
    MenuAlt2SolidIconComponent,
    MenuAlt3SolidIconComponent,
    MenuAlt4SolidIconComponent,
    MenuSolidIconComponent,
    MinusCircleSolidIconComponent,
    MoonSolidIconComponent,
    OfficeBuildingSolidIconComponent,
    PaperClipSolidIconComponent,
    PencilAltSolidIconComponent,
    PencilSolidIconComponent,
    PhoneIncomingSolidIconComponent,
    PhoneOutgoingSolidIconComponent,
    PhoneSolidIconComponent,
    PhotographSolidIconComponent,
    PlusCircleSolidIconComponent,
    PrinterSolidIconComponent,
    QrcodeSolidIconComponent,
    QuestionMarkCircleSolidIconComponent,
    ReceiptRefundSolidIconComponent,
    RefreshSolidIconComponent,
    ReplySolidIconComponent,
    ScaleSolidIconComponent,
    SearchSolidIconComponent,
    SelectorSolidIconComponent,
    ShareSolidIconComponent,
    ShieldCheckSolidIconComponent,
    ShieldExclamationSolidIconComponent,
    SortAscendingSolidIconComponent,
    SortDescendingSolidIconComponent,
    SparklesSolidIconComponent,
    SunSolidIconComponent,
    SwitchHorizontalSolidIconComponent,
    SwitchVerticalSolidIconComponent,
    TagSolidIconComponent,
    TemplateSolidIconComponent,
    TicketSolidIconComponent,
    TranslateSolidIconComponent,
    TrashSolidIconComponent,
    TrendingDownSolidIconComponent,
    TrendingUpSolidIconComponent,
    TruckSolidIconComponent,
    UploadSolidIconComponent,
    UserAddSolidIconComponent,
    UserCircleSolidIconComponent,
    UserGroupSolidIconComponent,
    UserRemoveSolidIconComponent,
    UserSolidIconComponent,
    UsersSolidIconComponent,
    ViewBoardsSolidIconComponent,
    ViewListSolidIconComponent,
    VolumeOffSolidIconComponent,
    VolumeUpSolidIconComponent,
    XCircleSolidIconComponent,
    XSolidIconComponent,
    ZoomInSolidIconComponent,
    ZoomOutSolidIconComponent,
    AcademicCapSolidIconComponent,
    BackspaceSolidIconComponent,
    BeakerSolidIconComponent,
    CakeSolidIconComponent,
    CalculatorSolidIconComponent,
    ChevronDoubleDownSolidIconComponent,
    ChevronDoubleLeftSolidIconComponent,
    ChevronDoubleRightSolidIconComponent,
    ChevronDoubleUpSolidIconComponent,
    ChipSolidIconComponent,
    CloudDownloadSolidIconComponent,
    CloudSolidIconComponent,
    CloudUploadSolidIconComponent,
    CubeSolidIconComponent,
    CubeTransparentSolidIconComponent,
    CurrencyBangladeshiSolidIconComponent,
    DatabaseSolidIconComponent,
    DeviceMobileSolidIconComponent,
    DeviceTabletSolidIconComponent,
    DocumentSearchSolidIconComponent,
    DocumentTextSolidIconComponent,
    FastForwardSolidIconComponent,
    FilmSolidIconComponent,
    FingerPrintSolidIconComponent,
    FireSolidIconComponent,
    FolderOpenSolidIconComponent,
    GiftSolidIconComponent,
    HandSolidIconComponent,
    IdentificationSolidIconComponent,
    LoginSolidIconComponent,
    LogoutSolidIconComponent,
    MapSolidIconComponent,
    MinusSmSolidIconComponent,
    MinusSolidIconComponent,
    MusicNoteSolidIconComponent,
    PaperAirplaneSolidIconComponent,
    PhoneMissedCallSolidIconComponent,
    PlusSmSolidIconComponent,
    PlusSolidIconComponent,
    PresentationChartBarSolidIconComponent,
    PresentationChartLineSolidIconComponent,
    PuzzleSolidIconComponent,
    ReceiptTaxSolidIconComponent,
    RewindSolidIconComponent,
    RssSolidIconComponent,
    SaveAsSolidIconComponent,
    SaveSolidIconComponent,
    ScissorsSolidIconComponent,
    SearchCircleSolidIconComponent,
    ServerSolidIconComponent,
    ShoppingBagSolidIconComponent,
    StatusOfflineSolidIconComponent,
    StatusOnlineSolidIconComponent,
    TableSolidIconComponent,
    ThumbDownSolidIconComponent,
    ThumbUpSolidIconComponent,
    VariableSolidIconComponent,
    VideoCameraSolidIconComponent,
    ViewGridSolidIconComponent,
    WifiSolidIconComponent,
    BadgeCheckSolidIconComponent,
    BanSolidIconComponent,
    BookmarkAltSolidIconComponent,
    ChartBarSolidIconComponent,
    ChartSquareBarSolidIconComponent,
    ChatAlt2SolidIconComponent,
    ChatAltSolidIconComponent,
    CursorClickSolidIconComponent,
    DesktopComputerSolidIconComponent,
    DocumentReportSolidIconComponent,
    EyeOffSolidIconComponent,
    KeySolidIconComponent,
    LibrarySolidIconComponent,
    MicrophoneSolidIconComponent,
    NewspaperSolidIconComponent,
    PauseSolidIconComponent,
    PlaySolidIconComponent,
    ShoppingCartSolidIconComponent,
    SpeakerphoneSolidIconComponent,
    StarSolidIconComponent,
    StopSolidIconComponent,
    SupportSolidIconComponent,
    TerminalSolidIconComponent,
    ViewGridAddSolidIconComponent,
    ArrowsExpandSolidIconComponent,
    FolderAddSolidIconComponent,
    FolderDownloadSolidIconComponent,
    FolderRemoveSolidIconComponent,
    ManageSolidIconComponent,
    NotesFilledSolidIconComponent,
    NotesSolidIconComponent,
    RemoveSolidIconComponent,
    RetrySolidIconComponent,
    DollarComponent,
    EyeOffComponent,
    EyeOnComponent,
    TriangleDownComponent,
  ],
  imports: [CommonModule],
  exports: [
    AcademicCapOutlineIconComponent,
    AdjustmentsOutlineIconComponent,
    AnnotationOutlineIconComponent,
    ArchiveOutlineIconComponent,
    ArrowCircleDownOutlineIconComponent,
    ArrowCircleLeftOutlineIconComponent,
    ArrowCircleRightOutlineIconComponent,
    ArrowCircleUpOutlineIconComponent,
    ArrowNarrowDownOutlineIconComponent,
    ArrowNarrowLeftOutlineIconComponent,
    ArrowNarrowRightOutlineIconComponent,
    ArrowNarrowUpOutlineIconComponent,
    ArrowUpOutlineIconComponent,
    ArrowsExpandOutlineIconComponent,
    AtSymbolOutlineIconComponent,
    BackspaceOutlineIconComponent,
    BadgeCheckOutlineIconComponent,
    BanOutlineIconComponent,
    BeakerOutlineIconComponent,
    BellOutlineIconComponent,
    BookOpenOutlineIconComponent,
    BookmarkAltOutlineIconComponent,
    BookmarkOutlineIconComponent,
    BriefcaseOutlineIconComponent,
    CakeOutlineIconComponent,
    CalculatorOutlineIconComponent,
    CalendarOutlineIconComponent,
    CameraOutlineIconComponent,
    CashOutlineIconComponent,
    ChartBarOutlineIconComponent,
    ChartPieOutlineIconComponent,
    ChartSquareBarOutlineIconComponent,
    ChatAlt2OutlineIconComponent,
    ChatAltOutlineIconComponent,
    ChatOutlineIconComponent,
    CheckCircleOutlineIconComponent,
    CheckOutlineIconComponent,
    ChevronDoubleDownOutlineIconComponent,
    ChevronDoubleLeftOutlineIconComponent,
    ChevronDoubleRightOutlineIconComponent,
    ChevronDoubleUpOutlineIconComponent,
    ChevronDownOutlineIconComponent,
    ChevronLeftOutlineIconComponent,
    ChevronRightOutlineIconComponent,
    ChevronUpOutlineIconComponent,
    ChipOutlineIconComponent,
    ClipboardCheckOutlineIconComponent,
    ClipboardCopyOutlineIconComponent,
    ClipboardListOutlineIconComponent,
    ClipboardOutlineIconComponent,
    ClockOutlineIconComponent,
    CloudDownloadOutlineIconComponent,
    CloudOutlineIconComponent,
    CloudUploadOutlineIconComponent,
    CodeOutlineIconComponent,
    CogOutlineIconComponent,
    CollectionOutlineIconComponent,
    ColorSwatchOutlineIconComponent,
    CreditCardOutlineIconComponent,
    CubeOutlineIconComponent,
    CubeTransparentOutlineIconComponent,
    CurrencyBangladeshiOutlineIconComponent,
    CurrencyDollarOutlineIconComponent,
    CurrencyEuroOutlineIconComponent,
    CurrencyPoundOutlineIconComponent,
    CurrencyRupeeOutlineIconComponent,
    CurrencyYenOutlineIconComponent,
    CursorClickOutlineIconComponent,
    DatabaseOutlineIconComponent,
    DesktopComputerOutlineIconComponent,
    DeviceMobileOutlineIconComponent,
    DeviceTabletOutlineIconComponent,
    DocumentAddOutlineIconComponent,
    DocumentDownloadOutlineIconComponent,
    DocumentDuplicateOutlineIconComponent,
    DocumentOutlineIconComponent,
    DocumentRemoveOutlineIconComponent,
    DocumentReportOutlineIconComponent,
    DocumentSearchOutlineIconComponent,
    DocumentTextOutlineIconComponent,
    DotsCircleHorizontalOutlineIconComponent,
    DotsHorizontalOutlineIconComponent,
    DotsVerticalOutlineIconComponent,
    DownloadOutlineIconComponent,
    DuplicateOutlineIconComponent,
    EmojiHappyOutlineIconComponent,
    EmojiSadOutlineIconComponent,
    ExclamationCircleOutlineIconComponent,
    ExclamationOutlineIconComponent,
    ExternalLinkOutlineIconComponent,
    EyeOffOutlineIconComponent,
    EyeOutlineIconComponent,
    FastForwardOutlineIconComponent,
    FilmOutlineIconComponent,
    FilterOutlineIconComponent,
    FingerPrintOutlineIconComponent,
    FireOutlineIconComponent,
    FlagOutlineIconComponent,
    FolderAddOutlineIconComponent,
    FolderDownloadOutlineIconComponent,
    FolderOpenOutlineIconComponent,
    FolderOutlineIconComponent,
    FolderRemoveOutlineIconComponent,
    GasStationOutlineIconComponent,
       GiftOutlineIconComponent,
       GlobeAltOutlineIconComponent,
       GlobeOutlineIconComponent,
       HandOutlineIconComponent,
       HashtagOutlineIconComponent,
       HeartOutlineIconComponent,
       HomeOutlineIconComponent,
       IdentificationOutlineIconComponent,
       InboxInOutlineIconComponent,
       InboxOutlineIconComponent,
       InformationCircleOutlineIconComponent,
       KeyOutlineIconComponent,
       LibraryOutlineIconComponent,
       LightBulbOutlineIconComponent,
       LightningBoltOutlineIconComponent,
       LinkOutlineIconComponent,
       LocationMarkerOutlineIconComponent,
       LockClosedOutlineIconComponent,
       LockOpenOutlineIconComponent,
       LoginOutlineIconComponent,
       LogoutOutlineIconComponent,
       MailOpenOutlineIconComponent,
       MailOutlineIconComponent,
       MapOutlineIconComponent,
       MenuAlt1OutlineIconComponent,
       MenuAlt2OutlineIconComponent,
       MenuAlt3OutlineIconComponent,
       MenuAlt4OutlineIconComponent,
       MenuOutlineIconComponent,
       MicrophoneOutlineIconComponent,
       MinusCircleOutlineIconComponent,
       MinusOutlineIconComponent,
       MinusSmOutlineIconComponent,
       MoonOutlineIconComponent,
       MusicNoteOutlineIconComponent,
       NewspaperOutlineIconComponent,
       OfficeBuildingOutlineIconComponent,
       PaperAirplaneOutlineIconComponent,
       PaperClipOutlineIconComponent,
       PauseOutlineIconComponent,
       PencilAltOutlineIconComponent,
       PencilOutlineIconComponent,
       PhoneIncomingOutlineIconComponent,
       PhoneMissedCallOutlineIconComponent,
       PhoneOutgoingOutlineIconComponent,
       PhoneOutlineIconComponent,
       PhotographOutlineIconComponent,
       PlayOutlineIconComponent,
       PlusCircleOutlineIconComponent,
       PlusOutlineIconComponent,
       PlusSmOutlineIconComponent,
       PresentationChartBarOutlineIconComponent,
       PresentationChartLineOutlineIconComponent,
       PrinterOutlineIconComponent,
       PuzzleOutlineIconComponent,
       QrcodeOutlineIconComponent,
       QuestionMarkCircleOutlineIconComponent,
       ReceiptRefundOutlineIconComponent,
       ReceiptTaxOutlineIconComponent,
       RefreshOutlineIconComponent,
       ReplyOutlineIconComponent,
       RewindOutlineIconComponent,
       RssOutlineIconComponent,
       SaveAsOutlineIconComponent,
       SaveOutlineIconComponent,
       ScaleOutlineIconComponent,
       ScissorsOutlineIconComponent,
       SearchOutlineIconComponent,
       SelectorOutlineIconComponent,
       ServerOutlineIconComponent,
       ShareOutlineIconComponent,
       ShieldCheckOutlineIconComponent,
       ShieldExclamationOutlineIconComponent,
       ShoppingBagOutlineIconComponent,
       ShoppingCartOutlineIconComponent,
       SortAscendingOutlineIconComponent,
       SortDescendingOutlineIconComponent,
       SparklesOutlineIconComponent,
       SpeakerphoneOutlineIconComponent,
       StarOutlineIconComponent,
       StatusOfflineOutlineIconComponent,
       StopOutlineIconComponent,
       SunOutlineIconComponent,
       SupportOutlineIconComponent,
       SwitchHorizontalOutlineIconComponent,
       SwitchVerticalOutlineIconComponent,
       TableOutlineIconComponent,
       TagOutlineIconComponent,
       TemplateOutlineIconComponent,
       TerminalOutlineIconComponent,
       ThumbDownOutlineIconComponent,
       ThumbUpOutlineIconComponent,
       TicketOutlineIconComponent,
       TranslateOutlineIconComponent,
       TrashOutlineIconComponent,
       TrendingDownOutlineIconComponent,
       TrendingUpOutlineIconComponent,
       TruckOutlineIconComponent,
       UploadOutlineIconComponent,
       UserAddOutlineIconComponent,
       UserCircleOutlineIconComponent,
       UserGroupOutlineIconComponent,
       UserOutlineIconComponent,
       UserRemoveOutlineIconComponent,
       UsersOutlineIconComponent,
       VariableOutlineIconComponent,
       VideoCameraOutlineIconComponent,
       ViewBoardsOutlineIconComponent,
       ViewGridAddOutlineIconComponent,
       ViewGridOutlineIconComponent,
       ViewListOutlineIconComponent,
       VolumeOffOutlineIconComponent,
       VolumeUpOutlineIconComponent,
       WifiOutlineIconComponent,
       XCircleOutlineIconComponent,
       XOutlineIconComponent,
       ZoomInOutlineIconComponent,
       ZoomOutOutlineIconComponent,
       ArrowDownOutlineIconComponent,
       ArrowLeftOutlineIconComponent,
       ArrowRightOutlineIconComponent,
       FilterAltGreyOutlineIconComponent,
       FilterAltOutlineIconComponent,
       SearchCircleOutlineIconComponent,
       StatusOnlineOutlineIconComponent,
       WebOutlineIconComponent,
       AdjustmentsSolidIconComponent,
       AnnotationSolidIconComponent,
       ArchiveSolidIconComponent,
       ArrowCircleDownSolidIconComponent,
       ArrowCircleLeftSolidIconComponent,
       ArrowCircleRightSolidIconComponent,
       ArrowCircleUpSolidIconComponent,
       ArrowDownSolidIconComponent,
       ArrowLeftSolidIconComponent,
       ArrowNarrowDownSolidIconComponent,
       ArrowNarrowLeftSolidIconComponent,
       ArrowNarrowRightSolidIconComponent,
       ArrowNarrowUpSolidIconComponent,
       ArrowRightSolidIconComponent,
       ArrowUpSolidIconComponent,
       AtSymbolSolidIconComponent,
       BellSolidIconComponent,
       BookOpenSolidIconComponent,
       BookmarkSolidIconComponent,
       BriefcaseSolidIconComponent,
       CalendarSolidIconComponent,
       CameraSolidIconComponent,
       CashSolidIconComponent,
       ChartPieSolidIconComponent,
       ChatSolidIconComponent,
       CheckCircleSolidIconComponent,
       CheckSolidIconComponent,
       ChevronDownSolidIconComponent,
       ChevronLeftSolidIconComponent,
       ChevronRightSolidIconComponent,
       ChevronUpSolidIconComponent,
       ClipboardCheckSolidIconComponent,
       ClipboardCopySolidIconComponent,
       ClipboardListSolidIconComponent,
       ClipboardSolidIconComponent,
       ClockSolidIconComponent,
       CodeSolidIconComponent,
       CogSolidIconComponent,
       CollectionSolidIconComponent,
       ColorSwatchSolidIconComponent,
       CreditCardSolidIconComponent,
       CurrencyDollarSolidIconComponent,
       CurrencyEuroSolidIconComponent,
       CurrencyPoundSolidIconComponent,
       CurrencyRupeeSolidIconComponent,
       CurrencyYenSolidIconComponent,
       DocumentAddSolidIconComponent,
       DocumentDownloadSolidIconComponent,
       DocumentDuplicateSolidIconComponent,
       DocumentRemoveSolidIconComponent,
       DocumentSolidIconComponent,
       DotsCircleHorizontalSolidIconComponent,
       DotsHorizontalSolidIconComponent,
       DotsVerticalSolidIconComponent,
       DownloadSolidIconComponent,
       DuplicateSolidIconComponent,
       EmojiHappySolidIconComponent,
       EmojiSadSolidIconComponent,
       ExclamationCircleSolidIconComponent,
       ExclamationSolidIconComponent,
       ExternalLinkSolidIconComponent,
       EyeSolidIconComponent,
       FilterSolidIconComponent,
       FlagSolidIconComponent,
       FolderSolidIconComponent,
       GasStationSolidIconComponent,
    GlobeAltSolidIconComponent,
    GlobeSolidIconComponent,
    HashtagSolidIconComponent,
    HeartSolidIconComponent,
    HomeSolidIconComponent,
    InboxInSolidIconComponent,
    InboxSolidIconComponent,
    InformationCircleSolidIconComponent,
    LightBulbSolidIconComponent,
    LightningBoltSolidIconComponent,
    LinkSolidIconComponent,
    LocationMarkerSolidIconComponent,
    LockClosedSolidIconComponent,
    LockOpenSolidIconComponent,
    MailOpenSolidIconComponent,
    MailSolidIconComponent,
    MenuAlt1SolidIconComponent,
    MenuAlt2SolidIconComponent,
    MenuAlt3SolidIconComponent,
    MenuAlt4SolidIconComponent,
    MenuSolidIconComponent,
    MinusCircleSolidIconComponent,
    MoonSolidIconComponent,
    OfficeBuildingSolidIconComponent,
    PaperClipSolidIconComponent,
    PencilAltSolidIconComponent,
    PencilSolidIconComponent,
    PhoneIncomingSolidIconComponent,
    PhoneOutgoingSolidIconComponent,
    PhoneSolidIconComponent,
    PhotographSolidIconComponent,
    PlusCircleSolidIconComponent,
    PrinterSolidIconComponent,
    QrcodeSolidIconComponent,
    QuestionMarkCircleSolidIconComponent,
    ReceiptRefundSolidIconComponent,
    RefreshSolidIconComponent,
    ReplySolidIconComponent,
    ScaleSolidIconComponent,
    SearchSolidIconComponent,
    SelectorSolidIconComponent,
    ShareSolidIconComponent,
    ShieldCheckSolidIconComponent,
    ShieldExclamationSolidIconComponent,
    SortAscendingSolidIconComponent,
    SortDescendingSolidIconComponent,
    SparklesSolidIconComponent,
    SunSolidIconComponent,
    SwitchHorizontalSolidIconComponent,
    SwitchVerticalSolidIconComponent,
    TagSolidIconComponent,
    TemplateSolidIconComponent,
    TicketSolidIconComponent,
    TranslateSolidIconComponent,
    TrashSolidIconComponent,
    TrendingDownSolidIconComponent,
    TrendingUpSolidIconComponent,
    TruckSolidIconComponent,
    UploadSolidIconComponent,
    UserAddSolidIconComponent,
    UserCircleSolidIconComponent,
    UserGroupSolidIconComponent,
    UserRemoveSolidIconComponent,
    UserSolidIconComponent,
    UsersSolidIconComponent,
    ViewBoardsSolidIconComponent,
    ViewListSolidIconComponent,
    VolumeOffSolidIconComponent,
    VolumeUpSolidIconComponent,
    XCircleSolidIconComponent,
    XSolidIconComponent,
    ZoomInSolidIconComponent,
    ZoomOutSolidIconComponent,
    AcademicCapSolidIconComponent,
    BackspaceSolidIconComponent,
    BeakerSolidIconComponent,
    CakeSolidIconComponent,
    CalculatorSolidIconComponent,
    ChevronDoubleDownSolidIconComponent,
    ChevronDoubleLeftSolidIconComponent,
    ChevronDoubleRightSolidIconComponent,
    ChevronDoubleUpSolidIconComponent,
    ChipSolidIconComponent,
    CloudDownloadSolidIconComponent,
    CloudSolidIconComponent,
    CloudUploadSolidIconComponent,
    CubeSolidIconComponent,
    CubeTransparentSolidIconComponent,
    CurrencyBangladeshiSolidIconComponent,
    DatabaseSolidIconComponent,
    DeviceMobileSolidIconComponent,
    DeviceTabletSolidIconComponent,
    DocumentSearchSolidIconComponent,
    DocumentTextSolidIconComponent,
    FastForwardSolidIconComponent,
    FilmSolidIconComponent,
    FingerPrintSolidIconComponent,
    FireSolidIconComponent,
    FolderOpenSolidIconComponent,
    GiftSolidIconComponent,
    HandSolidIconComponent,
    IdentificationSolidIconComponent,
    LoginSolidIconComponent,
    LogoutSolidIconComponent,
    MapSolidIconComponent,
    MinusSmSolidIconComponent,
    MinusSolidIconComponent,
    MusicNoteSolidIconComponent,
    PaperAirplaneSolidIconComponent,
    PhoneMissedCallSolidIconComponent,
    PlusSmSolidIconComponent,
    PlusSolidIconComponent,
    PresentationChartBarSolidIconComponent,
    PresentationChartLineSolidIconComponent,
    PuzzleSolidIconComponent,
    ReceiptTaxSolidIconComponent,
    RewindSolidIconComponent,
    RssSolidIconComponent,
    SaveAsSolidIconComponent,
    SaveSolidIconComponent,
    ScissorsSolidIconComponent,
    SearchCircleSolidIconComponent,
    ServerSolidIconComponent,
    ShoppingBagSolidIconComponent,
    StatusOfflineSolidIconComponent,
    StatusOnlineSolidIconComponent,
    TableSolidIconComponent,
    ThumbDownSolidIconComponent,
    ThumbUpSolidIconComponent,
    VariableSolidIconComponent,
    VideoCameraSolidIconComponent,
    ViewGridSolidIconComponent,
    WifiSolidIconComponent,
    BadgeCheckSolidIconComponent,
    BanSolidIconComponent,
    BookmarkAltSolidIconComponent,
    ChartBarSolidIconComponent,
    ChartSquareBarSolidIconComponent,
    ChatAlt2SolidIconComponent,
    ChatAltSolidIconComponent,
    CursorClickSolidIconComponent,
    DesktopComputerSolidIconComponent,
    DocumentReportSolidIconComponent,
    EyeOffSolidIconComponent,
    KeySolidIconComponent,
    LibrarySolidIconComponent,
    MicrophoneSolidIconComponent,
    NewspaperSolidIconComponent,
    PauseSolidIconComponent,
    PlaySolidIconComponent,
    ShoppingCartSolidIconComponent,
    SpeakerphoneSolidIconComponent,
    StarSolidIconComponent,
    StopSolidIconComponent,
    SupportSolidIconComponent,
    TerminalSolidIconComponent,
    ViewGridAddSolidIconComponent,
    ArrowsExpandSolidIconComponent,
    FolderAddSolidIconComponent,
    FolderDownloadSolidIconComponent,
    FolderRemoveSolidIconComponent,
    ManageSolidIconComponent,
    NotesFilledSolidIconComponent,
    NotesSolidIconComponent,
    RemoveSolidIconComponent,
    RetrySolidIconComponent,
    DollarComponent,
    EyeOffComponent,
    EyeOnComponent,
    TriangleDownComponent,
  ],
})
export class S2dIconsModule {}
