import { Component } from '@angular/core';
import {BaseIconComponent} from '../base-icon-component/base-icon-component';

@Component({
  selector: 's2d-icon-sub-menu-arrow-rights-solid',
  templateUrl: `./sub-menu-arrow-rights-solid.html`,
  styleUrls: ['./sub-menu-arrow-rights-solid.scss'],
})
export class SubMenuArrowRightsComponent extends BaseIconComponent {
}
