import {Component} from '@angular/core';
import {BaseModal} from '../../../../models/base/base-modal';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import AddressUtils from '../../../../utils/address-utils';

@Component({
  selector: 'app-privacy-policy-modal',
  templateUrl: './privacy-policy-modal.component.html',
  styleUrls: ['./privacy-policy-modal.component.scss']
})
export class PrivacyPolicyModalComponent extends BaseModal {

  constructor(
    private activeModal: NgbActiveModal,
  ) {
    super();
  }

  cancel() {
    this.activeModal.close(false);
  }

  setupBindings() {
  }

  setupViews() {
  }

  continue() {
    this.activeModal.close(true);
  }


  getAddress() {
    const address = AddressUtils.getStoreToDoorAddress();
    return `${address.street}, ${address.city}, ${address.postalCode}`;
  }
}
