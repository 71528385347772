import {MediaType} from '../enum/dto/media-type.enum';
import {MediaUtils} from '../../utils/media-utils';
import {CreateVehicleAttachmentRequest} from "../../api/vehicle/requests/create-vehicle-attachment-request";
import {Attachment, AttachmentTypeName} from "../../interfaces/attachments/attachment";
import { AttachmentLink } from 'src/app/interfaces/attachments/attachment-link';

export class CustomFile implements Attachment {
  id: string;
  attachmentType: AttachmentTypeName;
  links: AttachmentLink[];
  updatedDate: Date;
  updatedById: number;
  updatedBy: string;
  createdDate: Date;
  createdById: number;
  createdBy: string;
  public name: string;
  public type: MediaType | string;
  public url: string | ArrayBuffer;
  public size: number;
  public progress: number;
  public success: boolean;
  public failed: boolean;
  public failureError: string;
  public hasPreview: boolean;
  public attachmentTypeId: number;

  isImage(): boolean {
    return this.type.match(/image\/*/) != null;
  }

  isVideo(): boolean {
    return this.type.match(/video\/*/) != null;
  }
  getFileTypeId(): number {
    if (this.type.match(/image*/)) {
      return 1;
    }
    if (this.type.match(/application\/pdf/)) {
      return 2;
    }
  }

  getFileRequest(): CreateVehicleAttachmentRequest {
    return {
      name: this.name,
      attachmentTypeId: this.attachmentTypeId,
      type: this.type
    }
  }

  getMediaType(): MediaType {
    return MediaUtils.getMediaType(this.name.split('.').pop().toLowerCase());
  }

  getUploadDelay(): number {
    let delayInSec: number;
    if (this.size < 1000000) {
      // Files under 1MB
      delayInSec = 5;
    } else if (this.size < 3000000) {
      // Files under 3MB
      delayInSec = 8;
    } else if (this.size < 7000000) {
      // Files under 7MB
      delayInSec = 10;
    } else {
      delayInSec = 12;
    }
    return delayInSec * 1000;
  }
}
