import {Deserializable} from '../../protocols/deserializable';
import {BaseModel} from './base-model';
import {DatatableData} from '../../protocols/datatable-data';
import AddressUtils from '../../../utils/address-utils';
import {  } from '@angular/google-maps';
import {AddressInterface} from "../../../modules/address";
import { OrderDeliveryModalViewModel } from 'src/app/views/create-orders/components/create-orders/order-delivery-modal/order-delivery-modal-view-model';
export class Address extends BaseModel implements DatatableData, Deserializable, AddressInterface {
 
  public id: string;
  public onFleetDestinationId: string = null;
  public apartment: string = null;
  public number: string = null;
  public street: string = null;
  public city: string = null;
  public state: string = null;
  public country: string = null;
  public postalCode: string = null;
  public notes: string = null;
  public timeZoneId: string = null;
  public latitude: number = null;
  public longitude: number = null;
  public static fromPlaceResult(place: google.maps.places.PlaceResult): Address {
     

    if (place == null) {
      return;
    }
    const address = new Address();
    const streetNumber = place.address_components.find(c => c.types.includes('street_number'))?.long_name;
    const route = place.address_components.find(c => c.types.includes('route'))?.long_name;
    address.number = streetNumber;
    address.street = route;
    address.city = place.address_components.find(c => c.types.includes('locality'))?.long_name;
    address.postalCode = place.address_components.find(c => c.types.includes('postal_code'))?.long_name;
    const state = place.address_components.find(c => c.types.includes('administrative_area_level_1'));
    address.state = state?.short_name;
    const country = place.address_components.find(c => c.types.includes('country'));
    address.country = country?.short_name;
    address.latitude = place.geometry.location.lat();
    address.longitude = place.geometry.location.lng();
    return address;
    
  }

  public onDeserialize() {
    super.onDeserialize();
  }

  get cityStateString(): string {
    return AddressUtils.cityStateString(this);
  }
  get cityStatePostalCodeString(): string {
    return AddressUtils.cityStatePostalCodeString(this);
  }
  get streetAddressString(): string {
    return AddressUtils.streetAddressString(this);
  }

  getChildrenUniqueIds(): string[] {
    return [];
  }

  getColor(): string {
    return '';
  }

  getTextColor(): string {
    return '';
  }
}
