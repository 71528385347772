// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  hmr: false,
  adminCompanyId: 1,
  driverCompanyId: 9,
  mainServiceUrl: 'https://dev.api.storetodoorcanada.com',
  //mainServiceUrl: 'https://localhost:44309',
  trackinglinkurl: 'https://track.dev.app.storetodoorcanada.com/?trackid=',
  rateandtipsurl: 'https://dev.app.storetodoorcanada.com/#/rate-and-tip/',
  rateUrlContent: 'Rate your experience',
  onFleetAssetUrl: 'https://d15p8tr8p0vffz.cloudfront.net',
  googleApiKey: 'AIzaSyAHExzpsgRc9Kj606Z5lc3oBwFcjItgLI8',
  csvUploadTemplate: 'https://drive.google.com/file/d/1bYc-sTuJ1xmswdKnKGoKL55gs2hJCibE/view',
  csvUploadWithPackageTemplate: 'https://drive.google.com/file/d/1svYvi0v7BtgCuhd95YnqCpr-zIboje16/view',
  cognitoClientSecret: '',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: ''
  },
  publicAPI : '0c708de9-6dd0-453a-8070-f8ac585f16ea'
};


