<div class="form-group">
    <div class="std-form-field-header">
        <ng-content select="std-label"></ng-content>
        <std-required-marker *ngIf="showMarker()"></std-required-marker>
    </div>
    <div class="input-group">

        <div class="input-group-prepend" *ngIf="hasPrefix">
            <div class="input-group-text" *ngIf="_hasIconPrefix">
                <ng-content select="[stdPrefix]"></ng-content>
            </div>
            <ng-container *ngIf="_hasIconSuffix">
                <ng-content  select="[stdPrefixBtn]"></ng-content>
            </ng-container>
        </div>

        <div class="form-control" (click)="_control.onContainerClick($event)" [ngClass]="{hasSuffix}">
            <div class="std-form-field-input">
                <ng-container *ngIf="showPlaceholder">
                    <std-paragraph [size]="'md'" class="std-form-field-placeholder">
                        <ng-container>{{ placeholder }}</ng-container>
                        <ng-content select="std-placeholder"></ng-content>
                    </std-paragraph>
                </ng-container>
                <ng-content></ng-content>
            </div>
        </div>

        <div class="input-group-append" *ngIf="hasSuffix">
            <div class="input-group-text" *ngIf="_hasIconSuffix">
                <ng-content select="[stdSuffix]"></ng-content>
            </div>
            <ng-container *ngIf="_hasButtonSuffix">
                <ng-content  select="[stdSuffixBtn]"></ng-content>
            </ng-container>
        </div>

    </div>
    <div class="std-form-field-footer">
        <div>
            <std-label [size]="'hint'" *ngIf="hint && !showError()">{{hint}}</std-label>
            <div class="std-label-subtitle">
                <span *ngIf="showError()">{{getErrorMessage()}}</span>
            </div>
        </div>
    </div>
</div>
