import {AbstractChallenge} from './abstract.challenge';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthChallengeType} from "../../auth-challenge-type";
import {OneTimePasswordRequest} from "../../../../models/account/requests/one-time-password-request";
import {AccountAPI} from "../../../../api/account-api";
import {User} from "../../../../models/account/dto/user";
import {tap} from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class MfaChallenge extends AbstractChallenge {
    public challengeType: AuthChallengeType = 'SOFTWARE_TOKEN_MFA';
    public oneTimePasswordRequest: OneTimePasswordRequest;
    constructor(public router: Router,
                private accountApi: AccountAPI) {
        super();
    }
    handle(user: User): Observable<User> {
        this.oneTimePasswordRequest = {
            companyId: user.companyId,
            email: user.email,
            sessionId: user.sessionId
        };
        this.router.navigate(['/mfa']);
        return this.user;
    }
    submit(req: OneTimePasswordRequest): Observable<User>{
        return this.accountApi.submitOneTimePassword(req).pipe(tap(response => this.user.next(response)));
    }
}


