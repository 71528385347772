import {animate, group, query, style, transition, trigger} from '@angular/animations';

export const fadeRouteAnimations =
    trigger('fadeRouteAnimations', [
        transition('* <=> *', [
            query(':enter', [
                style({
                    opacity: 0,
                })
            ], {optional: true}),
            query(':leave', [
                style({
                    display: 'none'
                })
            ], {optional: true}),
            group([
                query(':leave', [
                    animate(250, style({opacity: 0}))
                ], {optional: true}),
                query(':enter', [
                    animate(250, style({opacity: 1}))
                ], {optional: true}),
            ]),
        ]),
    ]);
