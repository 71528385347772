import {Deserializable} from '../../protocols/deserializable';
import {AssetSize} from '../../enum/dto/image-size.enum';
import {ReplaySubject} from 'rxjs';
import {SafeResourceUrl} from '@angular/platform-browser';

export class AssetUrl implements Deserializable {
  public size: AssetSize;
  public presignedUrl: string;
  // not from API
  public srcUrl: ReplaySubject<string | SafeResourceUrl>;
  public loading: ReplaySubject<boolean>;

  public onDeserialize() {
    this.srcUrl = new ReplaySubject<string | SafeResourceUrl>(1);
    this.loading = new ReplaySubject<boolean>(1);
  }

}
