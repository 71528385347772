import { CategoryInterface } from "src/app/modules/category/category-interface";
import { Deserializable } from "../../protocols/deserializable";
import { BaseModel } from "./base-model";
import { DatatableData } from "../../protocols/datatable-data";

export class Category extends BaseModel implements DatatableData, Deserializable, CategoryInterface 
{
  id: number=null;
  primaryCategoryTypeId: number=null;
  primaryCategoryType: string=null;
  locationId: number=null;
  companyId: number=null;
  secondaryCategories: SecondaryCategory[]=[];

  public onDeserialize() {
    super.onDeserialize();
  }

  getChildrenUniqueIds(): string[] {
    return [];
  }

  getColor(): string {
    return '';
  }

  getTextColor(): string {
    return '';
  }
}

export class SecondaryCategory {
  id: number=null;
  locationId: number=null;
  companyId: number=null;
  locationPrimaryCategoryId?: number=null;
  secondaryCategoryTypeId: number=null;
  secondaryCategoryType: string=null;
}
