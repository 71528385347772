import {DateUtils} from './date-utils';
import {DurationInputArg2, unitOfTime} from 'moment';
export {};
declare global {
    interface Date {
        addTime(amount: number, unit: DurationInputArg2): Date;
        subtractTime(amount: number, unit: DurationInputArg2): Date;
        startOf(unit?: unitOfTime.StartOf): Date;
        endOf(unit?: unitOfTime.StartOf): Date;
    }
}
Date.prototype.addTime = function(amount: number, unit: DurationInputArg2): Date {
    return DateUtils.addTime(this, amount, unit);
};
Date.prototype.subtractTime = function(amount: number, unit: DurationInputArg2): Date {
    return DateUtils.subtractTime(this, amount, unit);
};
Date.prototype.startOf = function(unit?: unitOfTime.StartOf): Date {
    return DateUtils.startOf(this, unit);
};
Date.prototype.endOf = function(unit?: unitOfTime.StartOf): Date {
    return DateUtils.endOf(this, unit);
};
