import { Injectable } from '@angular/core';
import { SessionService } from '../../services/session-service';
import { CanMatch, Route, UrlSegment } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SessionContainer } from '../../models/shared/session-container';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CanMatchAdmin implements CanMatch {
  constructor(private session: SessionService) {}

  canMatch(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.session.sessionContainer.pipe(
      map((session: SessionContainer) => {
        return session?.user?.companyId === environment.adminCompanyId;
      })
    );
  }
}
