import {AuthChallengeType} from "../../../views/login/auth-challenge-type";

export class UpdateQrCodeRequest {
    timeBasedOneTimePassword: string;
    password: string;
    email: string;
    companyId: number;
    accessToken: string;
    challengeName: AuthChallengeType;
}
