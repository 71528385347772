import { Component } from '@angular/core';
import {BaseIconComponent} from '../base-icon-component/base-icon-component';

@Component({
  selector: 's2d-icon-mailbox-unsubscribe-outline',
  templateUrl: `./mailbox-unsubscribe-outline.html`,
  styleUrls: ['./mailbox-unsubscribe-outline.scss'],
})
export class MailBoxOutlineIconComponent extends BaseIconComponent {
}
