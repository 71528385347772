import { InjectionToken, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button/button.component';
import { MatIconModule } from '@angular/material/icon';
import { RequiredMarkerComponent } from './required-marker/required-marker.component';
import { ErrorComponent } from './error/error.component';
import {
  MatPseudoCheckboxModule,
  MatRippleModule,
} from '@angular/material/core';
import { TitleBackButtonComponent } from './title-back-button/title-back-button.component';
import { OptionComponent } from './option/option.component';
import { FirstKeyValuePipe } from './pipes/first-key.pipe';
import { TextLinkDirective } from './button/text-link.directive';
import { ListActionItemComponent } from './list-action-item/list-action-item.component';
import { StdLayoutModule } from '../layout/std-layout.module';
import { ChatWidgetHiderDirective } from './chat-widget-hider.directive';
import { StdTextModule } from '../std-text/std-text.module';
import { DangerDialogComponent } from './danger-dialog/danger-dialog.component';

export const SCROLL_INTO_VIEW_OPTIONS =
  new InjectionToken<ScrollIntoViewOptions>('ScrollIntoViewOptions');

export const DefaultScrollIntoViewOptions: ScrollIntoViewOptions = {
  behavior: 'smooth',
  block: 'nearest',
  inline: 'nearest',
};

@NgModule({
  declarations: [
    ButtonComponent,
    RequiredMarkerComponent,
    ErrorComponent,
    FirstKeyValuePipe,
    TitleBackButtonComponent,
    OptionComponent,
    TextLinkDirective,
    ListActionItemComponent,
    ChatWidgetHiderDirective,
    DangerDialogComponent,
  ],
  exports: [
    ButtonComponent,
    RequiredMarkerComponent,
    ErrorComponent,
    FirstKeyValuePipe,
    ChatWidgetHiderDirective,
    TitleBackButtonComponent,
    OptionComponent,
    TextLinkDirective,
    ListActionItemComponent,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatPseudoCheckboxModule,
    MatRippleModule,
    StdLayoutModule,
    StdTextModule,
  ],
})
export class StdComponentsModule {}
