import { Component } from '@angular/core';
import {BaseIconComponent} from '../base-icon-component/base-icon-component';

@Component({
  selector: 's2d-icon-currency-dollar-outline',
  templateUrl: `./currency-dollar-outline.html`,
  styleUrls: ['./currency-dollar-outline.scss'],
})
export class CurrencyDollarOutlineIconComponent extends BaseIconComponent {
}
