<div class="modal-body sticky" stdChatWidgetHider>
    <div class="sticky-header-container">
        <div class="modal-header">
            <div class="modal-title" i18n>
                Privacy Policy
            </div>
        </div>
    </div>
    <div class="sticky-body-container">
        <!-- Modal Body -->
        <div class="legal-body" i18n>
            <input type="text" autofocus="autofocus" style="display:none" />
            <span class="legal-subtitle">1. Our Approach To Privacy</span>
            <br>
            1. StoreToDoor Technologies Inc. (“we“, “our”, or “us”) is committed to protecting your privacy. This
            privacy policy sets out how we collect, store, process, transfer, share and use data that identifies or is
            associated with you (“personal information”) and information regarding our use of cookies and similar technologies.
            <br>
            2. We are a courier service available via our website (our “Website”) located at
            https://www.app.storetodoorcanada.com (the “Service”).
            <br>
            3. This privacy policy applies to the Service.
            <br>
            4. Before accessing or using the Service, please ensure that you have read and understood our collection,
            storage, use and disclosure of your personal information as described in this privacy policy. By accessing
            or using the Service, you are accepting and consenting to the practices described in this privacy policy.
            <br><br>
            <span class="legal-subtitle">2. Summary</span> <br>
            1. We collect information that you provide to us or voluntarily share with other users, in addition to
            general technical information that is automatically collected by our systems, including IP addresses,
            browser information and cookies to provide a better user experience and generally improve the Service.
            <br>
            2. Your personal information may be gathered from subscribers of the Service, websites you use that we
            operate, and third parties. Technical information about how you access and use the service, as well as
            information about your device, is automatically collected and used for Service operation, optimization,
            analytics, content promotion and enhancement of user experience. In accordance with applicable local law,
            we may use your information to contact you with relevant information about the Service and our other products
            and services.
            <br>
            3. We do not disclose your personal information to others except as described in this Policy or in connection
            with the Service. Information that you choose to publish on our Website or social media accounts (blogs,
            testimonials, videos, text, music, reviews, deliveries) may be read, collected and used by others who access
            them.
            <br>
            4. All of the information that you provide to us is stored on secure servers in a controlled environment with
            limited access. The personal information we collect may be transferred to and stored in countries outside of
            the jurisdiction you are in. These international transfers are made pursuant to appropriate safeguards to
            protect your personal information.
            <br>
            5. You may exercise your rights in respect of your personal information that we hold by contacting us at
            support@storetodoorcanada.com. Additional information regarding the rights applicable to you are in the
            detailed terms of the Policy below.
            <br>
            6. The above-noted summary contains highlights of our policy. We encourage you to read more about the
            information we collect and how we use it in the detailed terms of our Policy below.
            <br><br>
            <span class="legal-subtitle">3. Detailed Terms</span>
            <br>
            Personal information we collect about you and how we use it
            <br><br>
            Information you give to us
            <br>
            1. We collect personal information about you when you voluntarily submit information directly to us by
            filling in forms on our Website or by corresponding with us by phone, email or otherwise. This includes
            information you provide when you register to use our Website, subscribe to the Service, participate in any
            discussion boards, forums or other social media functions on our site or enter a competition, promotion or
            survey, and when you report a problem with our Website, or use some other feature of the Service as available
            from time to time.
            <br>
            2. If you choose not to provide personal information, we may not be able to provide the Service to you or
            respond to your other requests.
            <br><br>
            Information we receive from others
            <br>
            1. We may receive personal information about you from individuals or corporate entities which are subscribers
            to the Service (“Subscribers”) where you are to be designated a user of the Service. We may receive personal
            information about you if you use any of the other websites we operate or the other services we provide from
            time to time. We also work closely with third parties (including, for example, subcontractors in technical,
            payment and delivery services, advertising networks, analytics providers, search information providers) and
            may receive information about you from them, subject to your agreements with them.
            <br>
            2. The following table (“Table 1”) sets out the categories of personal information you provide to us and that
            we receive from other sources, how we use that information, and the legal basis which we rely upon to process
            the information and determine applicable retention periods:
            <br><br>
            Personal information we collect
            <table class="policy-table">
                <tr>
                    <th>Category of Personal Information</th>
                    <th>How we use it</th>
                    <th>Legal Basis for the Processing</th>
                </tr>
                <tr>
                    <td>Contact information and basic personal details. Your name, phone number, address, location, IP
                        address, and e-mail address.</td>
                    <td>We use this information to communicate with you, including sending statements, news, alerts and
                        marketing communications. <br><br> We use this information to deal with inquiries and other
                        requests made by or about you, including customer service issues, relating to the Service. <br><br>
                        We use this information to operate, maintain and provide to you the features and functionality of the Service.
                    </td>
                    <td>The processing is necessary for our legitimate interests, namely for marketing purposes, and for
                        communicating with you effectively and responding to your queries. <br><br> The processing is necessary for the
                        performance of a contract and to take steps prior to entering into a contract (namely our Terms of Service).
                    </td>
                </tr>
                <tr>
                    <td>Correspondence and comments. When you contact us directly, e.g. by email, phone, mail, or when you
                        interact with customer service, we will record your comments and opinions.</td>
                    <td>To address your questions, issues, and concerns and resolve your customer service issues.</td>
                    <td>The processing is necessary for our legitimate interests, namely communicating with you
                        effectively for the purposes of resolving your issues.</td>
                </tr>
                <tr>
                    <td>Recruiting details. Contact information and basic personal details (as set out above); driver’s
                        license, vehicle information, background check, driver’s abstract, professional details,
                        regulatory certifications, and employment information such as resume, references, and proof of
                        work eligibility.
                    </td>
                    <td>We use this information to facilitate recruiting.</td>
                    <td>The processing is necessary for our legitimate interests, namely assessing your suitability for a role with us.</td>
                </tr>
                <tr>
                    <td>All personal information set out above.</td>
                    <td>We will use all the personal information we collect to operate, maintain and provide to you the
                        features and functionality of the Service, to monitor and improve the Service, our Website and
                        business, for internal operations, including troubleshooting, data analysis, testing, research,
                        statistical and survey purposes, to keep the Website safe and secure and to help us develop new
                        products and services.</td>
                    <td>The processing is necessary for our legitimate interest, namely to administer and improve the
                        Service, our business and develop new services.</td>
                </tr>
            </table>
            <br><br>
            The following table (“Table 2”) sets out the categories of personal information we collect about you
            automatically, how we use that information, and the legal basis which we rely upon to process the information
            and determine applicable retention periods:
            <br><br>
            Personal Information Collected Automatically
            <table>
                <tr>
                    <th>Category of Personal Information</th>
                    <th>How we use it</th>
                    <th>Legal Basis for the Processing</th>
                </tr>
                <tr>
                    <td>Information about how you access and use the Service. For example, the website from which you
                        came and the website to which you are going when you leave our Website, your social media
                        profiles, how frequently you access the Service, the time you access the Service and how long
                        you use it for, whether you open emails or click the links contained in emails, whether you
                        access the Service from multiple devices, and other actions you take on the Service. We also
                        gather information, which may include Internet protocol (IP) addresses, browser type, internet
                        service provider (ISP), referring/exit pages, operating system, date/time stamp, and/or
                        clickstream data.</td>
                    <td>We use this information to: <br>
                        Conduct market analysis, monitor the Service and how it is used in order to improve our business and help us develop new products and services;
                        <br>
                        Generate marketing leads and determine news, alerts and other products and services that may be of interest to you for marketing purposes.
                    </td>
                    <td>
                        The processing is necessary for our legitimate interests, namely: to conduct relevant analysis
                        to improve the Service generally and for marketing purposes.
                    </td>
                </tr>
                <tr>
                    <td>Information about your device. We also collect information about the computer, tablet,
                        smartphone or other electronic devices you use to connect to the Service. This information can
                        include details about the type of device, unique device identifying numbers, operating systems,
                        browsers, and applications connected to the Service through the device, your Internet service
                        provider or mobile network, your IP address.</td>
                    <td>We use this information to: <br>
                        Enable the Service to be presented to you on your device; and <br>
                        Operate, maintain and provide to you the features and functionality of the Service. <br>
                        We use this information to monitor and improve the Service and business and to help us develop new products and services.
                    </td>
                    <td>The processing is necessary for the performance of a contract (namely our Terms of Service). <br>
                        The processing is necessary for our legitimate interests, namely to tailor the Service to the user and to improve the Service generally.
                    </td>
                </tr>
            </table>
            We may link or combine the personal information we collect and/or receive about you and the information we
            collect automatically. This allows us to provide you with a personalized experience regardless of how you
            interact with us. <br>
            We may anonymize and aggregate any of the personal information we collect (so that it does not identify you).
            We may use anonymized information for purposes that include testing our IT systems, research, data analysis,
            improving the Service and developing new products and features. We may also share such anonymized information
            with others. <br><br>
            <span class="legal-subtitle">4. Disclosure of your personal information</span> <br>
            1. We do not sell or rent your personal information to third parties for their direct marking purposes without
            your explicit consent.
            <br>
            2. We may share your personal information with any member of our group, which includes our subsidiaries.
            We will not share your personal information with any third parties except as described in this privacy
            policy or in connection with the Service. We may share your information with selected third parties, including:
            <br>
            (a)Third Party Service Providers and Partners: Business partners, vendors, suppliers, and subcontractors who
            perform services on our behalf (these companies are authorized to use your personal information only as necessary to provide these services to us);
            <br>
            (b)Technical Providers: Analytics and search engine providers that assist us in the improvement and optimization of our Website;
            <br>
            (c)Payment Processors: Payment processors for the purpose of fulfilling relevant payment transactions;
            <br>
            (d)Third Parties in a Business Transaction: Personal information may be disclosed to third parties in connection
            with a transaction, such as a merger, sale of assets or shares, reorganization, financing, change of control or
            acquisition of all or a portion of our business. If we are involved in a merger, acquisition, or sale of all or a
            portion of its assets, you will be notified via email and/or a prominent notice on our Website of any change in
            ownership or uses of your personal information, as well as any choices you may have regarding your personal information.
            <br>
            3. In certain situations, we may be required to disclose personal information in response to lawful requests
            by public authorities, including to meet law enforcement requirements.
            <br>
            4. We may be under a duty to disclose or share your personal data in order to comply with any legal obligation,
            or in order to enforce or apply our terms and conditions of service
            <a href="https://www.storetodoorcanada.com/terms-conditions">(https://www.storetodoorcanada.com/terms-conditions)</a>
            and other agreements; or to protect the rights, property, or safety of us, our customers, or others. This includes
            exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.
            <br>
            5. Testimonials. With consent, we may display personal testimonials of satisfied customers on our site, along
            with other endorsements. If you wish to update or delete your testimonial, you can contact us at support@storetodoorcanada.com.
            <br><br>
            <span class="legal-subtitle">5. Marketing and Advertising</span> <br>
            1. From time to time we may contact you with relevant information about the Service and our other products
            and services. Most messages will be sent electronically. For some messages, we may use personal information
            we collect about you to help us determine the most relevant information to share with you.
            <br>
            2. If you do not want to receive such messages from us, you will be able to change your marketing preferences
            at any time by following the unsubscribe link at the bottom of our emails.
            <br><br>
            <span class="legal-subtitle">6. Storing and Transferring your Personal Information</span> <br>
            1. Security. All information you provide to us is stored on secure servers in a controlled environment with
            limited access. Any payment transactions will be encrypted in transit using TLS technology. Where we have
            given you (or where you have chosen) a password which enables you to access certain parts of our Service,
            you are responsible for keeping this password confidential. Subscribers should not share their password with
            anyone.<br>
            2. While no transmission of information via the internet is completely secure, we take reasonable measures to
            protect your personal information. We cannot guarantee the security of your personal information transmitted
            to our Website; any transmission is at your own risk. Once we have received your information, we will use
            strict procedures and security features to try to prevent unauthorized access.<br>
            3. International Transfers of your Personal Information. The personal information we collect may be
            transferred to and stored in countries outside of the jurisdiction you are in where we and our third-party
            service providers have operations. If you are located in the European Union (“EU“), your personal information
            will be processed outside of the EU including in the United States; these international transfers of your
            personal information are made pursuant to appropriate safeguards, such as standard data protection clauses
            adopted by the European Commission. If you wish to inquire further about these safeguards used, please
            contact us using the details set out at the end of this privacy policy.<br><br>
            <span class="legal-subtitle">7. Retaining your Information</span> <br>
            1. We will only retain your personal information for as long as necessary to fulfill the purposes we collected
            it for, including for the purposes of our legitimate business interests and satisfying any legal or
            reporting requirements. <br>
            2. To determine the appropriate retention period for personal information, we consider the amount, nature, and
            sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure of
            your personal information, the purposes for which we process your personal information and the applicable
            legal requirements. <br><br>
            <span class="legal-subtitle">8. Your Rights in Respect of your Personal Information </span> <br>
            1. In accordance with applicable privacy law, you have the following rights in respect of your personal information that we hold: <br>
            (a)Right of access and portability. The right to obtain access to your personal information along with
            certain information, and to receive that personal information in a commonly used format and to have it ported
            to another data controller. <br>
            (b)Right to rectification. The right to obtain rectification of your personal information without undue delay
            where that personal information is inaccurate or incomplete. <br>
            (c)Right to erasure. The right to obtain the erasure of your personal information without undue delay in
            certain circumstances, such as where the personal information is no longer necessary in relation to the
            purposes for which it was collected or processed. <br>
            (d)Right to restriction. The right to obtain the restriction of the processing undertaken by us on your
            personal information in certain circumstances, such as where the accuracy of the personal information is
            contested by you, for a period enabling us to verify the accuracy of that personal information. <br>
            (e)Right to object. The right to object, on grounds relating to your particular situation, to the processing
            of your personal information, and to object to the processing of your personal information for direct
            marketing purposes, to the extent it is related to such direct marketing. <br>
            2. If you wish to exercise one of these rights, please contact us using the contact details at the end of this
            privacy policy. Upon request, we will provide you with information about whether we hold any of your personal
            information. You may access, correct or request deletion of your personal information by logging into your account,
            or by contacting us at support@storetodoorcanada.com. We will respond to your request within 30 days. <br>
            3. If you reside in the European Union (EU), we are the controller of your personal information for purposes
            of European data protection legislation. You also have the right to lodge a complaint to your local data
            protection authority. Information about how to contact your local data protection authority is available at
            <a href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.html">ec.europa.eu/justice/data-protection/bodies/authorities/index_en.html.
            </a> <br><br>
            <span class="legal-subtitle">9. Cookies and Similar Technologies </span><br>
            1. Our Service uses cookies and similar technologies (collectively referred to as cookies) to distinguish you
            from other users of our Service. This helps us to provide you with good service. This helps us enhance and
            personalize your user experience, to monitor and improve our Website and services, and for other internal
            purposes. As is true of most websites, we gather certain information automatically. This information we may
            collect is described in detail in Table 2. <br>
            2. We use the following types of cookies: <br>
            (a)Strictly necessary cookies. These cookies are required for the essential operation of our Service such as
            to authenticate you and prevent fraudulent use. <br>
            (b)Analytical/performance cookies. These cookies allow us to recognize and count the number of visitors and
            to see how visitors move around our Service when they are using it. This helps us to improve the way our
            Service works, for example, by ensuring that you can find information easily. <br>
            (c)Functionality cookies. These cookies are used to recognize you when you return to our Service. This
            enables us to personalize our content for you, greet you by name and remember your preferences (for example,
            your choice of language or region). <br>
            (d)Targeting cookies. These cookies record your visit to our Service, the pages you have visited and the
            links you have followed. We will use this information to make our Service and the advertising displayed on
            it more relevant to your interests. We may also share this information with third parties for this purpose. <br>
            3. Please note that third parties (including, for example, advertising networks and providers of external
            services like web traffic analysis services) may also use cookies, over which we have no control. These
            cookies are likely to be analytical/performance cookies or targeting cookies. <br>
            4. We may partner with a third party to either display advertising on our Website or to manage our
            advertising on other sites. Our third-party partner may use technologies such as cookies to gather information
            about your activities on this Website and other sites in order to provide you advertising based upon your
            browsing activities and interests. If you wish to not have this information used to serve you interest-based
            ads, you may opt-out by contacting us. Please note this does not opt you out of being served ads. You will
            continue to receive generic ads. <br>
            5. You block cookies by activating the setting on your browser that allows you to refuse the setting of all
            or some cookies. However, if you use your browser settings to block all cookies (including strictly necessary
            cookies) you may not be able to access all or parts of our site. <br><br>
            <span class="legal-subtitle">10. Links to Third Party Sites</span><br>
            1.The Service may, from time to time, contain links to and from third party websites. If you follow a link
            to any of these websites, please note that these websites have their own privacy policies and that we do not
            accept any responsibility or liability for their policies. Please check the individual policies before you
            submit any information to those websites. <br>
            2.Some of the pages on our Website may utilize framing techniques to serve content to/from our partners
            while preserving the look and feel of our Website. Please be aware that you are providing your personal
            information to these third parties and not us. <br><br>
            <span class="legal-subtitle">11. Use of Services by Minors</span><br>
            1. The Service is not directed at persons under 16 and we do not intend to collect personal information from
            children under 16 in our capacity as a controller. If you become aware that a child has provided us with
            personal information without appropriate consent, then please contact us using the details below so that we
            can take the appropriate steps in accordance with our legal obligations and this privacy policy. <br><br>
            <span class="legal-subtitle">12. Your Canadian Privacy Rights</span><br>
            1. The Personal Information Protection and Electronic Documents Act Schedule 1 Clause 4.9 permits Canadian
            residents that are users of our website to request certain information regarding the existence, use, and
            disclosure of personal information and authorizes access to that information. To make such a request, please
            send an email to support@storetodoorcanada.com. <br><br>
            <span class="legal-subtitle">13. Changes to this Policy</span><br>
            1. We may update this privacy policy from time to time and so you should review this page periodically. When
            we change this privacy policy in a material way, we will update the “last modified” date at the end of this
            privacy policy. Changes to this privacy policy are effective when they are posted on this page. <br><br>
            <span class="legal-subtitle">14. Notice to you</span><br>
            1. If we need to provide you with information about something, whether for legal, marketing or other
            business-related purposes, we will select what we believe is the best way to get in contact with you.
            We will usually do this through email or by placing a notice on our Website. The fact that we may send
            notices to you will not stop you from being able to opt out of certain types of contact as described in
            this privacy policy. <br><br>
            <span class="legal-subtitle">15. Contacting Us</span><br>
            1. Regardless of your location, any questions, comments, and requests regarding this privacy policy are
            welcome and should be addressed to our Data Protection Officer, Lindsey Isaak, at lindsey@storetodoorcanada.com.
            Communication can also be addressed to: <br>
            StoreToDoor Technologies Inc. <br>
            {{getAddress()}}
            <br><br>
            This privacy policy was last modified on August 16, 2021














        </div>
    </div>
    <div class="sticky-footer-container modal-footer-flex-end pt-3">
        <button (click)="cancel()"
                class="custom-button neutral-button mr-3" i18n>
            Sign Out
        </button>
        <button (click)="continue()"
                class="custom-button preferred-button" i18n>
            I Agree
        </button>
    </div>
</div>
