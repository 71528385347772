import {DatatableData} from '../../protocols/datatable-data';
import {GenericCacheItem} from '../../shared/generic-cache-item';
import {Selectable} from '../../protocols/selectable';

export class PickupLocationCity extends GenericCacheItem implements DatatableData, Selectable {
    public id: number;
    public city: string = '';
    public state: string = '';
    public country: string = '';

    cachedTime: number;
    getChildrenUniqueIds(): string[] {
        return [];
    }

    getColor(): string {
        return '';
    }

    getTextColor(): string {
        return '';
    }

    getSelectionTitle(): string {
        return `${this.city}, ${this.state}, ${this.country}`;
    }

    getSelectionUniqueIdentifier(): any {
        return this.id;
    }

    getSelectionValue(): any {
        return this.id;
    }

}
