<div class="shadow-container">
  <div *ngIf="!subscribeFlag">
    <img [src]="unsubscribeLocation.imageUrl" alt="Company Logo" class="logo">
    <h2 class="company-name">{{unsubscribeLocation.company}}</h2>
    <p>Are you sure you no longer want to receive emails from us?</p>
    <button class="unsubscribe-button" (click)="onSave()">Unsubscribe</button>
  </div>
  <div *ngIf="subscribeFlag">
    <img [src]="unsubscribeLocation.imageUrl" alt="Company Logo" class="logo">
    <h2 class="company-name">{{unsubscribeLocation.company}}</h2>
    <h2 class="unsubscribe-text">{{confirmationMessage}}</h2>
    <p class="paddingTop">
      you will no longer receive email from us.<br> if you have unsubscribed in error, please contact <a
        href="#">{{unsubscribeLocation.email}}</a> <br> {{unsubscribeLocation.phone}}
    </p>
  </div>
</div>