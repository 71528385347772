export enum NavItemId {
  Dashboard,
  Users,
  Companies,
  Drivers,
  Profile,
  SignOut,
  Taxes,
  InvalidateSession,
  CreateOrders,
  ManageOrders,
  Employees,
  Roles,
  Locations,
  Billing,
  Deliveries,
  Compensation,
  ClientAlerts,
  Orders,
  FuelSurcharges,
  EmailLogs,
  SentEmailLogs,
  UnsubscribedEmailLogs,
  Reports,
  AdminSentEmailLogs,
  AdminUnsubscribedEmailLogs,
  SMSLogs,
  SentSMSLogs,
  UnsubscribedSMSLogs,
  AdminSentSMSLogs,
  AdminUnsubscribedSMSLogs,
}
