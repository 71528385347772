import {RolePermission} from '../enum/shared/permissions.enum';
import {Inject, Injectable} from '@angular/core';
@Injectable({
    providedIn: 'root',
})
export abstract class PermissionRule {
    permissions: RolePermission[];

    constructor(@Inject('permissions') permissions: RolePermission[]) {
        this.permissions = permissions ?? [];
    }
    check(userPermissions: RolePermission[]): boolean {
        return true;
    }
}
