import { NavItemId } from '../enum/shared/nav-item-id.enum';
import { RolePermission } from '../enum/shared/permissions.enum';
import { RequireOnePermission } from './permission-rules/RequireOnePermission';
import { PermissionRule } from '../base/PermissionRule';
import { Type } from '@angular/core';
import { BaseIconComponent } from 'src/app/icons/components/base-icon-component/base-icon-component';
import { LogoutOutlineIconComponent } from 'src/app/icons/components/logout-outline/logout-outline.icon.component';
import { UserCircleOutlineIconComponent } from 'src/app/icons/components/user-circle-outline/user-circle-outline.icon.component';
import { ReceiptTaxSolidIconComponent } from 'src/app/icons/components/receipt-tax-solid/receipt-tax-solid.icon.component';
import { TruckSolidIconComponent } from 'src/app/icons/components/truck-solid/truck-solid.icon.component';
import { OfficeBuildingSolidIconComponent } from 'src/app/icons/components/office-building-solid/office-building-solid.icon.component';
import { UsersSolidIconComponent } from 'src/app/icons/components/users-solid/users-solid.icon.component';
import {
  ClipboardListOutlineIconComponent
} from '../../icons/components/clipboard-list-outline/clipboard-list-outline.icon.component';
import {
  ClipboardListSolidIconComponent
} from '../../icons/components/clipboard-list-solid/clipboard-list-solid.icon.component';
import {
  IdentificationOutlineIconComponent
} from '../../icons/components/identification-outline/identification-outline.icon.component';
import {
  IdentificationSolidIconComponent
} from '../../icons/components/identification-solid/identification-solid.icon.component';
import { ViewGridOutlineIconComponent } from '../../icons/components/view-grid-outline/view-grid-outline.icon.component';
import { ViewGridSolidIconComponent } from '../../icons/components/view-grid-solid/view-grid-solid.icon.component';
import { CashOutlineIconComponent } from '../../icons/components/cash-outline/cash-outline.icon.component';
import { CashSolidIconComponent } from '../../icons/components/cash-solid/cash-solid.icon.component';
import {
  LocationMarkerOutlineIconComponent
} from '../../icons/components/location-marker-outline/location-marker-outline.icon.component';
import {
  LocationMarkerSolidIconComponent
} from '../../icons/components/location-marker-solid/location-marker-solid.icon.component';
import {
  CurrencyDollarOutlineIconComponent
} from '../../icons/components/currency-dollar-outline/currency-dollar-outline.icon.component';
import {
  CurrencyDollarSolidIconComponent
} from '../../icons/components/currency-dollar-solid/currency-dollar-solid.icon.component';
import { BellOutlineIconComponent } from '../../icons/components/bell-outline/bell-outline.icon.component';
import { BellSolidIconComponent } from '../../icons/components/bell-solid/bell-solid.icon.component';
import { LogoutSolidIconComponent } from '../../icons/components/logout-solid/logout-solid.icon.component';
import { UserCircleSolidIconComponent } from '../../icons/components/user-circle-solid/user-circle-solid.icon.component';
import {
  ReceiptTaxOutlineIconComponent
} from '../../icons/components/receipt-tax-outline/receipt-tax-outline.icon.component';
import { TruckOutlineIconComponent } from '../../icons/components/truck-outline/truck-outline.icon.component';
import {
  OfficeBuildingOutlineIconComponent
} from '../../icons/components/office-building-outline/office-building-outline.icon.component';
import { UsersOutlineIconComponent } from '../../icons/components/users-outline/users-outline.icon.component';
import {
  PlusCircleOutlineIconComponent
} from '../../icons/components/plus-circle-outline/plus-circle-outline.icon.component';
import { PlusCircleSolidIconComponent } from '../../icons/components/plus-circle-solid/plus-circle-solid.icon.component';
import { GasStationOutlineIconComponent } from 'src/app/icons/components/gas-station-outline/gas-station-outline.icon.component';
import { GasStationSolidIconComponent } from '../../icons/components/gas-station-solid/gas-station-solid.icon.component';
import { EmailLogsOutlineIconComponent } from 'src/app/icons/components/email-logs-outline/email-logs-outline.icon.components';
import { EmailLogsSolidIconComponent } from 'src/app/icons/components/email-logs-solid/email-logs-solid.icon.components';
import { SentEmailOutLine } from 'src/app/icons/components/sent-email-outline/sent-email-outline.icon.component';
import { MailBoxSolidIconComponent } from 'src/app/icons/components/mailbox-unsubscribe-solid/mailbox-unsubscribe-solid.icon.component';
import { SubMenuArrowRightComponent } from 'src/app/icons/components/sub-menu-arrow-right-solid/sub-menu-arrow-right-solid';
import { SubMenuArrowRightsComponent } from 'src/app/icons/components/sub-menu-arrow-rights-solid copy/sub-menu-arrow-rights-solid';
import { SentEmailSolid } from 'src/app/icons/components/sent-email-solid/sent-email-solid.icon.component';
import { MailBoxOutlineIconComponent } from 'src/app/icons/components/mailbox-unsubscribe-outline/mailbox-unsubscribe-outline.icon.component';
import { BehaviorSubject } from 'rxjs';
import { ReportSolidIconComponent } from 'src/app/icons/components/report-solid/report-solid.icon.component';
import { ReportOutlineIconComponent } from 'src/app/icons/components/report-outline/report-outline.icon.component';
import { SentSMSOutlineIconComponent } from 'src/app/icons/components/sent-sms-outline/sent-sms-outline';
import { SentSMSSolidIconComponent } from 'src/app/icons/components/sent-sms-solid/sent-sms-solid';
import { unsubscribeSMSOutlineIconComponent } from 'src/app/icons/components/sms-unsubscribe-outline/sms-unsubscribe-outline';
import { unsubscribeSMSSolidIconComponent } from 'src/app/icons/components/sms-unsubscribe-solid/sms-unsubscribe-solid';



export class NavItem {
  public id: number;
  public name: string;
  public iconSrc: string;
  public iconFilledSrc: string;
  public routerPath: string;
  public locationPicker: boolean;
  public showSubmenu:boolean;
  public subMenu: any[]
  public permissionRule?: PermissionRule;
  public icon?: Type<BaseIconComponent>;
  public iconFilled?: Type<BaseIconComponent>;
  public subMenuicon?: Type<BaseIconComponent>
  public subMenuiconFilled?: Type<BaseIconComponent>
  
  static generateSignOutNavItem(): NavItem {
    return {
      id: NavItemId.SignOut,
      name: $localize`Sign Out`,
      routerPath: '/sign-out',
      iconSrc: 'assets/icons/dark/outline/logout.svg',
      iconFilledSrc: 'assets/icons/dark/solid/logout.svg',
      locationPicker: false,
      subMenu: [],
      icon: LogoutOutlineIconComponent,
      iconFilled: LogoutSolidIconComponent,
      showSubmenu:false
    };
  }

  static generateProfileNavItem(): NavItem {
    return {
      id: NavItemId.Profile,
      name: $localize`Profile`,
      routerPath: '/profile',
      subMenu: [],
      iconSrc: 'assets/icons/dark/outline/user-circle.svg',
      iconFilledSrc: 'assets/icons/dark/solid/user-circle.svg',
      locationPicker: false,
      icon: UserCircleOutlineIconComponent,
      iconFilled: UserCircleSolidIconComponent,
      showSubmenu:false
    };
  }

  static generateTaxesNavItem(): NavItem {
    return {
      id: NavItemId.Taxes,
      name: $localize`Taxes`,
      routerPath: '/taxes',
      subMenu: [],
      iconSrc: 'assets/icons/dark/outline/receipt-tax.svg',
      iconFilledSrc: 'assets/icons/dark/solid/receipt-tax.svg',
      locationPicker: false,
      permissionRule: new RequireOnePermission([RolePermission.ViewTaxesTab]),
      icon: ReceiptTaxOutlineIconComponent,
      iconFilled: ReceiptTaxSolidIconComponent,
      showSubmenu:false
    };
  }

  static generateDriversNavItem(): NavItem {
    return {
      id: NavItemId.Drivers,
      name: $localize`Drivers`,
      routerPath: '/drivers',
      subMenu: [],
      iconSrc: 'assets/icons/dark/outline/truck.svg',
      iconFilledSrc: 'assets/icons/dark/solid/truck.svg',
      locationPicker: false,
      permissionRule: new RequireOnePermission([RolePermission.ViewDriversTab]),
      icon: TruckOutlineIconComponent,
      iconFilled: TruckSolidIconComponent,
      showSubmenu:false
    };
  }

  static generateAdminCompaniesNavItem(): NavItem {
    return {
      id: NavItemId.Companies,
      name: $localize`Companies`,
      routerPath: '/companies',
      subMenu: [],
      iconSrc: 'assets/icons/dark/outline/office-building.svg',
      iconFilledSrc: 'assets/icons/dark/solid/office-building.svg',
      locationPicker: false,
      permissionRule: new RequireOnePermission([RolePermission.ViewCompaniesTab]),
      icon: OfficeBuildingOutlineIconComponent,
      iconFilled: OfficeBuildingSolidIconComponent,
      showSubmenu:false
    };
  }

  static generateAdminUsersNavItem(): NavItem {
    return {
      id: NavItemId.Users,
      name: $localize`Admins`,
      routerPath: '/users',
      subMenu: [],
      iconSrc: 'assets/icons/dark/outline/users.svg',
      iconFilledSrc: 'assets/icons/dark/solid/users.svg',
      locationPicker: false,
      permissionRule: new RequireOnePermission([RolePermission.ViewAdminsTab]),
      icon: UsersOutlineIconComponent,
      iconFilled: UsersSolidIconComponent,
      showSubmenu:false
    };
  }

  static generateInvalidateSessionNavItem(): NavItem {
    return {
      id: NavItemId.InvalidateSession,
      name: $localize`Invalidate Session`,
      routerPath: '',
      subMenu: [],
      iconSrc: 'assets/icons/dark/outline/user-circle.svg',
      iconFilledSrc: 'assets/icons/dark/solid/user-circle.svg',
      locationPicker: false,
      icon: UserCircleOutlineIconComponent,
      iconFilled: UserCircleSolidIconComponent,
      showSubmenu:false
    };
  }

  static generateCreateOrdersNavItem(): NavItem {
    return {
      id: NavItemId.CreateOrders,
      name: $localize`Create Orders`,
      subMenu: [],
      routerPath: '/create-orders',
      iconSrc: 'assets/icons/dark/outline/plus-circle.svg',
      iconFilledSrc: 'assets/icons/dark/solid/plus-circle.svg',
      locationPicker: false,
      permissionRule: new RequireOnePermission([RolePermission.ViewCreateOrdersTab]),
      icon: PlusCircleOutlineIconComponent,
      iconFilled: PlusCircleSolidIconComponent,
      showSubmenu:false
    };
  }

  static generateManageOrdersNavItem(): NavItem {
    return {
      id: NavItemId.ManageOrders,
      name: $localize`Manage Orders`,
      routerPath: '/manage-orders',
      subMenu: [],
      iconSrc: 'assets/icons/dark/outline/clipboard-list.svg',
      iconFilledSrc: 'assets/icons/dark/solid/clipboard-list.svg',
      locationPicker: false,
      permissionRule: new RequireOnePermission([RolePermission.ViewManageOrdersTab]),
      icon: ClipboardListOutlineIconComponent,
      iconFilled: ClipboardListSolidIconComponent,
      showSubmenu:false
    };
  }

  static generateOrdersNavItem(): NavItem {
    return {
      id: NavItemId.Orders,
      name: $localize`Orders`,
      routerPath: '/orders',
      subMenu: [],
      iconSrc: 'assets/icons/dark/outline/clipboard-list.svg',
      iconFilledSrc: 'assets/icons/dark/solid/clipboard-list.svg',
      locationPicker: false,
      permissionRule: new RequireOnePermission([RolePermission.ViewOrdersTab]),
      icon: ClipboardListOutlineIconComponent,
      iconFilled: ClipboardListSolidIconComponent,
      showSubmenu:false
    };
  }

  static generateEmployeesNavItem(): NavItem {
    return {
      id: NavItemId.Employees,
      name: $localize`Employees`,
      routerPath: '/employees',
      subMenu: [],
      iconSrc: 'assets/icons/dark/outline/identification.svg',
      iconFilledSrc: 'assets/icons/dark/solid/identification.svg',
      locationPicker: false,
      permissionRule: new RequireOnePermission([RolePermission.ViewEmployeesTab]),
      icon: IdentificationOutlineIconComponent,
      iconFilled: IdentificationSolidIconComponent,
      showSubmenu:false
    };
  }

  static generateRolesNavItem(): NavItem {
    return {
      id: NavItemId.Roles,
      name: $localize`Roles`,
      routerPath: '/roles',
      subMenu: [],
      iconSrc: 'assets/icons/dark/outline/view-grid.svg',
      iconFilledSrc: 'assets/icons/dark/solid/view-grid.svg',
      locationPicker: false,
      permissionRule: new RequireOnePermission([RolePermission.ViewRolesTab]),
      icon: ViewGridOutlineIconComponent,
      iconFilled: ViewGridSolidIconComponent,
      showSubmenu:false
    };
  }

  static generateBillingNavItem(): NavItem {
    return {
      id: NavItemId.Billing,
      name: $localize`Billing`,
      routerPath: '/billing',
      subMenu: [],
      iconSrc: 'assets/icons/dark/outline/cash.svg',
      iconFilledSrc: 'assets/icons/dark/solid/cash.svg',
      locationPicker: false,
      permissionRule: new RequireOnePermission([RolePermission.ViewBillingTab]),
      icon: CashOutlineIconComponent,
      iconFilled: CashSolidIconComponent,
      showSubmenu:false
    };
  }
  static generalEmailLogsNavItem(): NavItem {
    return {
      id: NavItemId.EmailLogs,
      routerPath: '/sent-email-logs',
      name: $localize`Notification Logs`,
      subMenu: [{
        id: NavItemId.SentEmailLogs,
        name: $localize`Sent Emails`,
        routerPath: '/sent-email-logs',
        iconSrc: 'assets/icons/dark/outline/mailbox-sent.svg',
        iconFilledSrc: 'assets/icons/dark/solid/mailbox-sent.svg',
        locationPicker: false,
        permissionRule: new RequireOnePermission([RolePermission.ViewEmailLogsTab]),
        icon: SentEmailOutLine,
        iconFilled: SentEmailSolid
      },
      {
        id: NavItemId.UnsubscribedEmailLogs,
        name: $localize`Unsubscribed Emails`,
        routerPath: '/unsubscribed-email-logs',
        iconSrc: 'assets/icons/dark/outline/mailbox-unsubscribe.svg',
        iconFilledSrc: 'assets/icons/dark/solid/mailbox-unsubscribe.svg',
        locationPicker: false,
        permissionRule: new RequireOnePermission([RolePermission.ViewEmailLogsTab]),
        icon: MailBoxOutlineIconComponent,
        iconFilled: MailBoxSolidIconComponent
      },
      {
        id: NavItemId.SentSMSLogs,
        name: $localize`Sent SMS`,
        routerPath: '/sent-sms-logs',
        iconSrc: 'assets/icons/dark/outline/sent-sms-log.svg',
        iconFilledSrc: 'assets/icons/dark/solid/sent-sms-log.svg',
        locationPicker: false,
        permissionRule: new RequireOnePermission([RolePermission.ViewSMSLogsTab]),
        icon: SentSMSOutlineIconComponent,
        iconFilled: SentSMSSolidIconComponent
      },
      {
        id: NavItemId.UnsubscribedSMSLogs,
        name: $localize`Unsubscribed SMS`,
        routerPath: '/unsubscribed-sms-logs',
        iconSrc: 'assets/icons/dark/outline/sms-unsubscribe.svg',
        iconFilledSrc: 'assets/icons/dark/solid/sms-unsubscribe.svg',
        locationPicker: false,
        permissionRule: new RequireOnePermission([RolePermission.ViewSMSLogsTab]),
        icon: unsubscribeSMSOutlineIconComponent,
        iconFilled: unsubscribeSMSSolidIconComponent
      }],
      iconSrc: 'assets/icons/dark/outline/email-logs.svg',
      iconFilledSrc: 'assets/icons/dark/solid/email-logs.svg',
      locationPicker: false,
      permissionRule: new RequireOnePermission([RolePermission.ViewEmailLogsTab]),
      icon: EmailLogsOutlineIconComponent,
      iconFilled: EmailLogsSolidIconComponent,
      subMenuicon: SubMenuArrowRightComponent,
      subMenuiconFilled: SubMenuArrowRightsComponent,
      showSubmenu:false
    };
  }

  static generateAdminReportNavItem(): NavItem {
    return {
      id: NavItemId.Reports,
      name: $localize`Notification Logs`,
      routerPath: '/admin-sent-email-logs',
      subMenu: [{
        id: NavItemId.AdminSentEmailLogs,
        name: $localize`Sent Emails`,
        routerPath: '/admin-sent-email-logs',
        iconSrc: 'assets/icons/dark/outline/mailbox-sent.svg',
        iconFilledSrc: 'assets/icons/dark/solid/mailbox-sent.svg',
        locationPicker: false,
        permissionRule: new RequireOnePermission([RolePermission.ViewAdminEmailLogsTab]),
        icon: SentEmailOutLine,
        iconFilled: SentEmailSolid
      },
      {
        id: NavItemId.AdminUnsubscribedEmailLogs,
        name: $localize`Unsubscribed Emails`,
        routerPath: '/admin-unsubscribed-email',
        iconSrc: 'assets/icons/dark/outline/mailbox-unsubscribe.svg',
        iconFilledSrc: 'assets/icons/dark/solid/mailbox-unsubscribe.svg',
        locationPicker: false,
        permissionRule: new RequireOnePermission([RolePermission.ViewAdminEmailLogsTab]),
        icon: MailBoxOutlineIconComponent,
        iconFilled: MailBoxSolidIconComponent
      },
      {
        id: NavItemId.AdminSentSMSLogs,
        name: $localize`Sent SMS`,
        routerPath: '/admin-sent-sms-logs',
        iconSrc: 'assets/icons/dark/outline/sent-sms-log.svg',
        iconFilledSrc: 'assets/icons/dark/solid/sent-sms-log.svg',
        locationPicker: false,
        permissionRule: new RequireOnePermission([RolePermission.ViewAdminSMSLogsTab]),
        icon: SentSMSOutlineIconComponent,
        iconFilled: SentSMSSolidIconComponent
      },
      {
        id: NavItemId.AdminUnsubscribedSMSLogs,
        name: $localize`Unsubscribed SMS`,
        routerPath: '/admin-unsubscribed-sms',
        iconSrc: 'assets/icons/dark/outline/sms-unsubscribe.svg',
        iconFilledSrc: 'assets/icons/dark/solid/sms-unsubscribe.svg',
        locationPicker: false,
        permissionRule: new RequireOnePermission([RolePermission.ViewAdminSMSLogsTab]),
        icon: unsubscribeSMSOutlineIconComponent,
        iconFilled: unsubscribeSMSSolidIconComponent
      }],
      iconSrc: 'assets/icons/dark/outline/document-report.svg',
      iconFilledSrc: 'assets/icons/dark/solid/document-report.svg',
      locationPicker: false,
      permissionRule: new RequireOnePermission([RolePermission.ViewAdminReport]),
      icon: ReportOutlineIconComponent,
      iconFilled: ReportSolidIconComponent,
      subMenuicon: SubMenuArrowRightComponent,
      subMenuiconFilled: SubMenuArrowRightsComponent,
      showSubmenu:false
    };
  }

  static generateLocationsNavItem(): NavItem {
    return {
      id: NavItemId.Locations,
      name: $localize`Locations`,
      routerPath: '/locations',
      subMenu: [],
      iconSrc: 'assets/icons/dark/outline/location-marker.svg',
      iconFilledSrc: 'assets/icons/dark/solid/location-marker.svg',
      locationPicker: false,
      permissionRule: new RequireOnePermission([RolePermission.ViewLocationsTab]),
      icon: LocationMarkerOutlineIconComponent,
      iconFilled: LocationMarkerSolidIconComponent,
      showSubmenu:false
    };
  }

  static generateDeliveriesNavItem(): NavItem {
    return {
      id: NavItemId.Deliveries,
      name: $localize`Deliveries`,
      routerPath: '/deliveries',
      subMenu: [],
      iconSrc: 'assets/icons/dark/outline/clipboard-list.svg',
      iconFilledSrc: 'assets/icons/dark/solid/clipboard-list.svg',
      locationPicker: false,
      permissionRule: new RequireOnePermission([RolePermission.ViewDeliveriesTab]),
      icon: ClipboardListOutlineIconComponent,
      iconFilled: ClipboardListSolidIconComponent,
      showSubmenu:false
    };
  }

  static generateCompensationNavItem(): NavItem {
    return {
      id: NavItemId.Compensation,
      name: $localize`Compensation`,
      routerPath: '/compensation',
      subMenu: [],
      iconSrc: 'assets/icons/dark/outline/currency-dollar.svg',
      iconFilledSrc: 'assets/icons/dark/solid/currency-dollar.svg',
      locationPicker: false,
      permissionRule: new RequireOnePermission([RolePermission.ViewCompensationTab]),
      icon: CurrencyDollarOutlineIconComponent,
      iconFilled: CurrencyDollarSolidIconComponent,
      showSubmenu:false
    };
  }
  static generateClientAlertsNavItem(): NavItem {
    return {
      id: NavItemId.ClientAlerts,
      name: $localize`Client Alerts`,
      routerPath: '/client-alerts',
      subMenu: [],
      iconSrc: 'assets/icons/dark/outline/bell.svg',
      iconFilledSrc: 'assets/icons/dark/solid/bell.svg',
      locationPicker: false,
      permissionRule: new RequireOnePermission([RolePermission.ViewClientAlertsTab]),
      icon: BellOutlineIconComponent,
      iconFilled: BellSolidIconComponent,
      showSubmenu:false
    };
  }
  static generateFuelSurchargesNavItem(): NavItem {
    return {
      id: NavItemId.FuelSurcharges,
      name: $localize`Fuel Surcharges`,
      routerPath: '/fuel-surcharges',
      subMenu: [],
      iconSrc: 'assets/icons/dark/outline/gas-station.svg',
      iconFilledSrc: 'assets/icons/dark/solid/gas-station.svg',
      locationPicker: false,
      permissionRule: new RequireOnePermission([RolePermission.ViewFuelSurchargesTab]),
      icon: GasStationOutlineIconComponent,
      iconFilled: GasStationSolidIconComponent,
      showSubmenu:false
    };
  }

  // static generalSMSLogsNavItem(): NavItem {
  //   return {
  //     id: NavItemId.SMSLogs,
  //     routerPath: '/sent-sms-logs',
  //     name: $localize`SMS Logs`,
  //     subMenu: [{
  //       id: NavItemId.SentSMSLogs,
  //       name: $localize`Sent SMS`,
  //       routerPath: '/sent-sms-logs',
  //       iconSrc: 'assets/icons/dark/outline/sent-sms-log.svg',
  //       iconFilledSrc: 'assets/icons/dark/solid/sent-sms-log.svg',
  //       locationPicker: false,
  //       permissionRule: new RequireOnePermission([RolePermission.ViewSMSLogsTab]),
  //       icon: SentSMSOutlineIconComponent,
  //       iconFilled: SentSMSSolidIconComponent
  //     },
  //     {
  //       id: NavItemId.UnsubscribedSMSLogs,
  //       name: $localize`Unsubscribed SMS`,
  //       routerPath: '/unsubscribed-sms-logs',
  //       iconSrc: 'assets/icons/dark/outline/sms-unsubscribe.svg',
  //       iconFilledSrc: 'assets/icons/dark/solid/sms-unsubscribe.svg',
  //       locationPicker: false,
  //       permissionRule: new RequireOnePermission([RolePermission.ViewSMSLogsTab]),
  //       icon: unsubscribeSMSOutlineIconComponent,
  //       iconFilled: unsubscribeSMSSolidIconComponent
  //     }],
  //     iconSrc: 'assets/icons/dark/outline/email-logs.svg',
  //     iconFilledSrc: 'assets/icons/dark/solid/email-logs.svg',
  //     locationPicker: false,
  //     permissionRule: new RequireOnePermission([RolePermission.ViewSMSLogsTab]),
  //     icon: EmailLogsOutlineIconComponent,
  //     iconFilled: EmailLogsSolidIconComponent,
  //     subMenuicon: SubMenuArrowRightComponent,
  //     subMenuiconFilled: SubMenuArrowRightsComponent,
  //     showSubmenu:false
  //   };
  // }
  
}
