import {Component} from '@angular/core';
import {BaseComponent} from '../../../../models/base/base-component';
import {AccountDomainModel} from '../../../../domainModels/account-domain-model';
import {ActivatedRoute, ChildrenOutletContexts, Router} from '@angular/router';
import '../../../../utils/subscription.extensions';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {fadeRouteAnimations} from '../../../shared/animations/fade-route-animations';

@Component({
  selector: 'app-login-container',
  templateUrl: './login-container.component.html',
  styleUrls: ['./login-container.component.scss'],
  animations: [fadeRouteAnimations]
})
export class LoginContainerComponent extends BaseComponent {

  constructor(private accountDomainModel: AccountDomainModel,
              private modalService: NgbModal,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private contexts: ChildrenOutletContexts) {
    super();
  }

  setupBindings() {
  }

  setupViews() {
  }

  getRouteAnimationData() {
    return this.contexts.getContext('primary')?.route?.snapshot?.data.title;
  }
}
