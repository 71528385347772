<div class="login-container row" stdChatWidgetHider>
  <div class="h-100 d-none d-sm-none d-md-block col-md-4 col-lg-6 col-xl-8 p-0">
    <img class="login-splash" [src]="'assets/img/login-splash.png'" alt="">
    <img [src]="'assets/logo/light/logo-filled.svg'" alt="logo" class="login-logo">
  </div>
  <div class="h-100 col-12 col-sm-12 col-md-8 col-lg-6 col-xl-4 p-0" [@fadeRouteAnimations]="getRouteAnimationData()">
    <router-outlet></router-outlet>
  </div>
</div>

