import {Component} from '@angular/core';
import {BaseModal} from '../../../../models/base/base-modal';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import AddressUtils from '../../../../utils/address-utils';

@Component({
  selector: 'app-user-agreement-modal',
  templateUrl: './user-agreement-modal.component.html',
  styleUrls: ['./user-agreement-modal.component.scss']
})
export class UserAgreementModalComponent extends BaseModal {

  constructor(
    private activeModal: NgbActiveModal,
  ) {
    super();
  }

  cancel() {
    this.activeModal.close(false)
  }

  setupBindings() {
  }

  setupViews() {
  }

  continue() {
    this.activeModal.close(true);
  }

  getAddress() {
    const address = AddressUtils.getStoreToDoorAddress();
    return `${address.street}, ${address.city}, ${address.postalCode}`;
  }
}
