import {
  Component,
  ElementRef,
  Input,
  Optional,
  Self,
  ViewEncapsulation,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControlDirective,
  NgControl,
} from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { StdBaseFormFieldInput } from '../../../../std-forms/inputs/std-base-form-field-input';
import { DATE_INPUT_FORMAT } from '../../date-constants';
import { StdFormControlProviders } from '../../../../std-forms/std-form-control-providers';

@Component({
  selector: 'std-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [StdFormControlProviders.formField(DateInputComponent)],
})
export class DateInputComponent
  extends StdBaseFormFieldInput<any>
  implements MatFormFieldControl<any>, ControlValueAccessor
{
  constructor(
    @Optional() @Self() public ngControl: NgControl,
    @Optional() @Self() public formControlDir: FormControlDirective,
    public _elementRef: ElementRef
  ) {
    super(ngControl, _elementRef);
  }
  @Input() public label: string;
  @Input() public placeholder: string = DATE_INPUT_FORMAT;
  readonly controlType: string = 'std-date';
}
