import { Component } from '@angular/core';
import {BaseIconComponent} from '../base-icon-component/base-icon-component';

@Component({
  selector: 's2d-icon-cash-outline',
  templateUrl: `./cash-outline.html`,
  styleUrls: ['./cash-outline.scss'],
})
export class CashOutlineIconComponent extends BaseIconComponent {
}
