import { Component } from '@angular/core';
import {BaseIconComponent} from '../base-icon-component/base-icon-component';

@Component({
  selector: 's2d-icon-bell-solid',
  templateUrl: `./bell-solid.html`,
  styleUrls: ['./bell-solid.scss'],
})
export class BellSolidIconComponent extends BaseIconComponent {
}
