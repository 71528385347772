import { DatatableColumnFilterType } from '../models/shared/stylesheet/datatable-column-filter';
import AddressUtils from './address-utils';
import {NumberUtils} from './number-utils';

export class TypeUtils {
  // static ExampleTypeEnum: typeof ExampleTypeEnum = ExampleTypeEnum;
  static DatatableColumnFilterType: typeof DatatableColumnFilterType = DatatableColumnFilterType;
  static NumberUtils: typeof NumberUtils = NumberUtils;
  static AddressUtils: typeof AddressUtils = AddressUtils;
}
