<div class="modal-body sticky" stdChatWidgetHider>
    <div class="sticky-header-container">
        <div class="modal-header">
            <div class="modal-title" i18n>
                Terms & Conditions
            </div>
        </div>
    </div>
    <div class="sticky-body-container">
        <!-- Modal Body -->
        <div class="legal-body" i18n>
            <span class="legal-subtitle">1. Acceptance of the Website Terms and Conditions of Use.</span>
            <br>
            1. These website terms and conditions of use for https://www.app.storetodoorcanada.com, constitute a legal agreement
            and are entered into by and between you and StoreToDoor Technologies Inc. ("Company," "we," "us," "our").
            The following terms and conditions, together with any documents and/or additional terms they expressly incorporate
            by reference (collectively, these "Terms and Conditions"), govern your access to and use, including any content,
            functionality, and services offered on or through https://www.app.storetodoorcanada.com (the "Website").
            <br>
            2. By using the website, you accept and agree to be bound and comply with these terms and conditions and our privacy policy,
            found at <a href="https://www.storetodoorcanada.com/privacy-policy">https://www.storetodoorcanada.com/privacy-policy</a>,
            incorporated herein by reference. If you do not agree to
            these terms and conditions or the privacy policy, you must not access or use the website.
            <br>
            3. By using this Website, you represent and warrant that you are the legal age of majority under applicable law to
            form a binding contract with the Company and meet all of the foregoing eligibility requirements. If you do not
            meet all of these requirements, you must not access or use the Website.
            <br><br>
            <span class="legal-subtitle">2. Modifications to the Terms and Conditions and to the Website</span>
            <br>
            1. We reserve the right in our sole discretion to revise and update these terms and conditions from time to time.
            Any and all such modifications are effective immediately upon posting and apply to all access to and continued use of the Website.
            You agree to periodically review the terms and conditions in order to be aware of any such modifications and your
            continued use shall be your acceptance of these.
            <br>
            2. The information and material on this Website, and the Website may be changed, withdrawn or terminated at any
            time in our sole discretion without notice. We will not be liable if for any reason all or any part of the Website
            is restricted to users or unavailable at any time or for any period.
            <br><br>
            <span class="legal-subtitle">3. Your Use of the Website and Account Set-Up and Security</span>
            <br>
            1. Users are responsible for obtaining their own access to the Website and for the Website's availability and performance.
            Users are required to ensure that all persons who access the Website through a user's internet connection are
            aware of these Terms and Conditions and comply with them. Users are responsible for any security breaches or
            performance issues relating to accessing the Website.
            <br>
            2. The Website including content or areas of the Website may require user registration. It is a condition of
            your use of the Website that all the information you provide on the Website is correct, current, and complete.
            <br>
            3. Your provision of registration information and any submissions you make to the Website through any
            functionality such as applications, chat rooms, e-mail, message boards, personal or interest group web pages,
            profiles, forums, bulletin boards and other such functions (collectively, "Interactive Functions") constitutes
            your consent to all actions we take with respect to such information consistent with our Privacy Policy
            <a href="https://www.storetodoorcanada.com/privacy-policy">https://www.storetodoorcanada.com/privacy-policy</a>.
            <br>
            4. Any user name, password, or any other piece of information chosen by you or provided to you as part of our
            security procedures, must be treated as confidential, and you must not disclose it to any other person or entity.
            You must exercise caution when accessing your account from a public or shared computer so that others are not
            able to view or record your password or other personal information. You understand and agree that should you
            be provided an account, your account is personal to you and you agree not to provide any other person with
            access to this Website or portions of it using your user name, password, or other security information. You
            agree to notify us immediately of any unauthorized access to or use of your user name or password or any other
            breach of security. You also agree to ensure that you logout from your account at the end of each session.
            You are responsible for any password misuse or any unauthorized access.
            <br>
            5. We reserve the right at any time and from time to time, to disable or terminate your account, any user name,
            password, or other identifier, whether chosen by you or provided by us, in our sole discretion for any or no
            reason, including any violation of any provision of these Terms and Conditions.
            <br>
            6. You are prohibited from attempting to circumvent and from violating the security of this Website including
            without limitation:
            (a) accessing content and data that is not intended for you;
            <br>
            (b) attempting to breach or
            breaching the security and/or authentication measures which are not authorized;
            <br>
            (c) restrict disrupt or
            disable service to users, hosts, servers or networks;
            <br>
            (d) illicitly reproducing TCP/IP packet header;
            <br>
            (e) disrupting network services and otherwise disrupting Website owner's ability to monitor the Website;
            <br>
            (f) use any robot, spider, or other automatic device, process, or means to access the Website for any purpose,
            including monitoring or copying any of the material on the Website;
            <br>
            (g) introduce any viruses, trojan horses, worms, logic bombs, or other material that is malicious or
            technologically harmful;
            <br>
            (h) attack the Website via a denial-of-service attack, distributed denial-of-service attack, flooding,
            mailbombing or crashing; and
            <br>
            (i) otherwise attempt to interfere with the proper working of the Website.
            <br><br>
            <span class="legal-subtitle">4. Intellectual Property Rights and Ownership</span>
            <br>
            1. You understand and agree that the Website and its entire contents, features, and functionality, including
            but not limited to all information, software, code, text, displays, graphics, photographs, video, audio,
            design, presentation, selection, and arrangement, are owned by the Company, its licensors, or other
            providers of such material and are protected in all forms by intellectual property laws including without
            limitation, copyright, trademark, patent, trade secret, and any other proprietary rights.
            <br>
            2. The Company name, the Company logo, and all related names, logos, product and service names, designs,
            images and slogans are trademarks of the Company or its affiliates or licensors. You must not use such
            marks without the prior written permission of the Company. Other names, logos, product and service names,
            designs, images and slogans mentioned or which appear on this Website are the trademarks of their respective
            owners. Use of any such property, except as expressly authorized, shall constitute a violation of the rights
            of the property owner and may be a violation of federal or other laws and could subject the violator to legal action.
            <br>
            3. You may only use the Website for your personal and non-commercial use. You shall not directly or indirectly
            reproduce, compile for an internal database, distribute, modify, create derivative works of, publicly display,
            publicly perform, republish, download, store, or transmit any of the material on our Website, in any form or
            medium whatsoever except: (i) your computer and browser may temporarily store or cache copies of materials
            being accessed and viewed; (ii)a reasonable number of copies for personal use only may be printed keeping
            any proprietary notices thereon, which may only be used for non-commercial and lawful personal use and not
            for further reproduction, publication, or distribution of any kind on any medium whatsoever; and (iii) in
            the event social media features are provided with respect to certain content are on our site, you may take
            such actions as our site permits for such features.
            <br>
            4. Users are not permitted to modify copies of any materials from this site nor delete or alter any
            copyright, trademark, or other proprietary rights notices from copies of materials from this site. You must
            not access or use for any commercial purposes any part of the Website or any services or materials available
            through the Website.
            <br>
            5. If you print off, copy or download any part of our site in breach of these Terms and Conditions, your
            right to use our site will cease immediately and you must, at our option, return or destroy any copies of
            the materials you have made. You have no right, title, or interest in or to the Website or to any content
            on the Website, and all rights not expressly granted are reserved by the Company. Any use of the Website
            not expressly permitted by these Terms and Conditions is a breach of these Terms and Conditions and may
            violate copyright, trademark, and other intellectual property laws.
            <br><br>
            <span class="legal-subtitle">5. Conditions of Use and User Submissions and Site Content Standards</span>
            <br>
            1. As a condition of your access and use you agree that you may use the Website only for lawful purposes and
            in accordance with these Terms and Conditions.
            <br>
            2. The following content standards apply to any and all content, material, and information a user submits,
            posts, publishes, displays, or transmits (collectively, "submit") to the website, to other users or other
            persons (collectively, "User Submissions") and any and all Interactive Functions. Any and all User Submissions
            must comply with all applicable federal, provincial, local, and international laws and regulations.
            <br>
            3. Without limiting the foregoing you warrant and agree that your use of the Website and any User Submissions
            shall not:
            <br>
            a) In any manner violate any applicable federal, provincial, local, or international law or regulation including,
            without limitation, any laws regarding the export of data or software, patent, trademark, trade secret, copyright,
            or other intellectual property, legal rights (including the rights of publicity and privacy of others) or contain
            any material that could give rise to any civil or criminal liability under applicable laws or regulations or that
            otherwise may be in conflict with these Terms and Conditions and our Privacy Policy, found at
            <a href="https://www.storetodoorcanada.com/privacy-policy">https://www.storetodoorcanada.com/privacy-policy</a>.
            <br>
            b) Include or contain any material that is exploitive, obscene, harmful, threatening, abusive, harassing, hateful,
            defamatory, sexually explicit or pornographic, violent, inflammatory, or discriminatory based on race, sex, religion,
            nationality, disability, sexual orientation, or age or other such prohibited ground or be otherwise objectionable;
            <br>
            c) Involve stalking, attempting to exploit any individual or harm minors in any way by exposing them to inappropriate
            content or otherwise nor ask for personal information;
            <br>
            d) Involve, provide or contribute any false, inaccurate or misleading information;
            <br>
            e) Include sending, knowingly receiving, uploading, downloading, using, or reusing any material that does
            not comply with these Terms and Conditions;
            <br>
            f) Impersonate or attempt to impersonate the Company, a Company employee, another user, or any other person
            or entity (including, without limitation, by using email addresses, or screen names associated with any of the foregoing);
            <br>
            g) Transmit, or procure the sending of, any advertisements or promotions [without our prior written consent],
            commercial activities or sales, including without limitation any "spam", "junk mail", "chain letter", contests,
            sweepstakes and other sales promotions, barter, or advertising or any other similar solicitation;
            <br>
            h) Include engaging in any other conduct that restricts or inhibits anyone's use or enjoyment of the Website,
            or which, as determined by us, may harm the Company or users of the Website or expose them to liability;
            <br>
            i) Include causing annoyance, inconvenience, or needless anxiety or be likely to upset, embarrass, or alarm
            any other person;
            <br>
            j) Promote any illegal activity, or advocate, promote, or assist any unlawful act; and/or
            <br>
            k) Give the impression that they originate from or are endorsed by us or any other person or entity, if this
            is not the case.
            <br><br>
            <span class="legal-subtitle">6. User Submissions: Grant of License</span>
            <br>
            1. The Website may contain Interactive Functions allowing User Submissions on or through the Website.
            <br>
            2. None of the User Submissions you submit to the Website will be subject to any confidentiality by the Company.
            By providing any User Submission to the Website, you grant us and our affiliates and service providers, and
            each of their and our respective licensees, successors, and assigns the right to a world-wide, royalty free,
            perpetual, irrevocable, non-exclusive license to use, reproduce, modify, perform, display, distribute, and
            otherwise disclose to third parties any such material for any purpose and according to your account settings
            and/or incorporate such material into any form, medium or technology throughout the world without compensation
            to you. You waive any moral rights or other rights of authorship as a condition of submitting any User Submission.
            <br>
            3. By submitting the User Submissions you declare and warrant that you own or have the necessary rights to
            submit the User Submissions and have the right to grant the license hereof to us and our affiliates and service
            providers, and each of their and our respective licensees, successors, and assigns to the User Submissions and
            comply with these Terms and Conditions. You represent and warrant that all User Submissions comply with
            applicable laws and the User Submissions and Site Content Standards set out in these Terms and Conditions.
            <br>
            4. You understand and agree that you, not the Company, are fully responsible for any User Submissions you
            submit or contribute, and you are fully responsible and legally liable including to any third party for
            such content and its accuracy. We are not responsible or legally liable to any third party for the content
            or accuracy of any User Submissions submitted by you or any other user of the Website.
            <br><br>
            <span class="legal-subtitle">7. Site Monitoring and Enforcement, Suspension and Termination</span>
            <br>
            1. Company has the right, without provision of notice to:
            <br>
            a) Remove or refuse to post on the Website any User Submissions for any or no reason in our sole discretion;
            <br>
            b) At all times, to take such actions with respect to any User Submission deemed necessary or appropriate in
            our sole discretion, including without limitation, for violating the Website and User Submissions and Site
            Content Standards or Terms and Conditions;
            <br>
            c) Take appropriate legal action, including without limitation, referral to law enforcement, regulatory
            authority or harmed party for any illegal or unauthorized use of the Website. Without limiting the foregoing,
            we have the right to fully cooperate with any law enforcement authorities or court order requesting or
            directing us to disclose the identity or other information of anyone posting any materials on or through
            the Website; and/or
            <br>
            d) Terminate or suspend your access to all or part of the Website for any or no reason, including without
            limitation, any violation of these Terms and Conditions.
            <br>
            2. You waive and hold harmless the company and its affiliates, licensees and service provider from any and
            all claims resulting from any action taken by the company and any of the foregoing parties relating to any,
            investigations by either the company or such parties or by law enforcement authorities.
            <br>
            3. We have no obligation, nor any responsibility to any party to monitor the Website or use, and do not and
            cannot undertake to review material that you or other users submit to the Website. We cannot ensure prompt
            removal of objectionable material after it has been posted and we have no liability for any action or inaction
            regarding transmissions, communications, or content provided by any user or third party.
            <br><br>
            <span class="legal-subtitle">8. No Reliance</span>
            <br>
            1. The content on our Website is provided for general information purposes only. It is not intended to amount
            to advice on which you should rely. You must obtain more specific or professional advice before taking, or
            refraining from, any action or inaction on the basis of the content on our site.
            <br>
            2. Although we make reasonable efforts to update the information on our Website, we make no representations,
            warranties or guarantees, whether express or implied, that the content on our Website is accurate, complete
            or up to date. Your use of the Website is at your own risk and the Company has no responsibility or
            liability whatsoever for your use of this Website.
            <br>
            3. This Website may include content provided by third parties, including from other users and third-party
            licensors. All statements and/or opinions expressed in any such third party content, other than the content
            provided by the Company, are solely the opinions and the responsibility of the person or entity providing
            those materials. Such materials do not necessarily reflect the opinion of the Company. The Company is not
            responsible, or liable to you or any third party, for the content or accuracy of any third party materials.
            <br><br>
            <span class="legal-subtitle">9. Privacy</span>
            <br>
            1. Any user information and User Submissions will be deemed our property and your submissions and use of our
            site constitutes consent to the collection, use, reproduction, hosting, transmission and disclosure of any such
            user content submissions in compliance with our Privacy Policy, found at
            <a href="https://www.storetodoorcanada.com/privacy-policy">https://www.storetodoorcanada.com/privacy-policy</a>,
            as we deem necessary for use of the Website and provision of services.
            <br>
            2. By using this Website you are consenting to the use of cookies which allow a server to recall previous
            requests or registration and/or IP addresses to analyze website use patterns. You can set your browser to
            notify you before you receive a cookie, giving you the chance to decide whether to accept it. You can also
            set your browser to turn off cookies. If you do, however, some areas of the Website may not function adequately.
            <br><br>
            <span class="legal-subtitle">10. Third Party Websites</span>
            <br>
            1. For your convenience, this Website may provide links or pointers to third party sites. We make no
            representations about any other websites that may be accessed from this Website. If you choose to
            access any such sites, you do so at your own risk. We have no control over the contents of any such third
            party sites, and accept no responsibility for such sites or for any loss or damage that may arise from your
            use of them. You are subject to any terms and conditions of such third party sites.
            <br>
            2. Such links to third party sites from the Website, may include links to certain social media features that
            enable you to link or transmit on your own or using certain third party websites, certain limited content
            from this Website. You may only use these features when they are provided by us and solely with respect to
            the content identified. Such features and links to third party sites are subject to any additional terms
            and conditions we provide with respect to such features.
            <br>
            3. You may link to our homepage, provided you do so in a way that is fair and legal and does not damage
            our reputation or take advantage of it. You must not establish a link in such a way as to suggest any form
            of association, approval or endorsement on our part where none exists. Our site must not be framed on any
            other site, nor may you create a link to any part of our site other than the homepage. We reserve the right
            to withdraw linking permission without notice. The website in which you are linking must comply in all
            respects with the Conditions of Use and User Submissions and Site Content Standards. You agree to cooperate
            with us in causing any unauthorized framing or linking to immediately stop.
            <br><br>
            <span class="legal-subtitle">11. Online Purchases</span>
            <br>
            1. Certain sections of the Website may allow you to make purchases from us or from other merchants. If you make
            a purchase from us on or through our Website, all information obtained during your purchase or transaction
            and all of the information that you give as part of the transaction, such as your name, address, method of
            payment, credit card number, and billing information, may be collected by both us, the merchant, and our
            payment processing company. Please review our Privacy Policy for how we comply with securing your personal
            data.
            <br>
            2. Your participation, correspondence or business dealings with any affiliate, individual or company found
            on or through our Website, all purchase terms, conditions, representations or warranties associated with
            payment, refunds, and/or delivery related to your purchase, are solely between you and the merchant. You
            agree that we shall not be responsible or liable for any loss, damage, refunds, or other matters of any
            sort that incurred as the result of such dealings with a merchant.
            <br>
            3. Payment processing companies and merchants may have privacy and data collection practices that are
            different from ours. We have no responsibility or liability for these independent policies of the payment
            processing companies and merchants. In addition, when you make certain purchases through our Website, you
            may be subject to the additional terms and conditions of a payment processing company, merchant or us that
            specifically apply to your purchase. For more information regarding a merchant and its terms and conditions
            that may apply, visit that merchant’s Website and click on its information links or contact the merchant
            directly.
            <br>
            4. You release us, our affiliates, our payment processing company, and merchants from any damages that you
            incur, and agree not to assert any claims against us or them, arising from your purchase through or use of
            our Website.
            <br><br>
            <span class="legal-subtitle">12. Geographic Restrictions</span>
            <br>
            1. The owner of the Website is based in the Province of Saskatchewan Canada. We provide this Website for
            use only by persons located in Canada. This site is not intended for use in any jurisdiction where its
            use is not permitted. If you access the site from outside Canada you do so at your own risk and you are
            responsible for compliance with local laws of your jurisdiction.
            <br><br>
            <span class="legal-subtitle">13. Disclaimer of Warranties</span>
            <br>
            1. You understand and agree that your use of the website, its content, and any services or items found or
            attained through the website is at your own risk. The website, its content, and any services or items found
            or attained through the website are provided on an "as is" and "as available" basis, without any warranties
            of any kind, either express or implied including, but not limited to, the implied warranties of
            merchantability, fitness for a particular purpose, or non-infringement. The foregoing does not affect
            any warranties that cannot be excluded or limited under applicable law.
            <br>
            2. Neither the company nor any affiliates nor their respective directors, officers, employees, agents or
            service providers makes any warranty, representation or endorsement with respect to the completeness,
            security, reliability, suitability, accuracy, currency or availability of the website or its contents.
            Without limiting the foregoing, neither the company nor any affiliates nor their respective directors,
            officers, employees, agents or service providers represents or warrants that the website, its content,
            or any services or items found or attained through the website will be accurate, reliable, error-free, or
            uninterrupted, that defects will be corrected, that our site or the server that makes it available are free
            of viruses or other harmful components.
            <br>
            3. We cannot and do not guarantee or warrant that files or data available for downloading from the internet
            or the Website will be free of viruses or other destructive code. You are solely and entirely responsible
            for your use of the Website and your computer, internet and data security. To the fullest extent provided
            by law, we will not be liable for any loss or damage caused by denial-of-service attack, distributed
            denial-of-service attack, overloading, flooding, mailbombing or crashing, viruses, trojan horses, worms,
            logic bombs, or other technologically harmful material that may infect your computer equipment,
            computer programs, data, or other proprietary material due to your use of the website or any services or
            items found or attained through the website or to your downloading of any material posted on it, or on any
            website linked to it.
            <br><br>
            <span class="legal-subtitle">14. Limitation on Liability</span>
            <br>
            1. UNDER NO CIRCUMSTANCE WILL THE COMPANY, ITS AFFILIATES AND THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES,
            AGENTS, OR SERVICE PROVIDERS BE LIABLE FOR NEGLIGENCE, GROSS NEGLIGENCE, NEGLIGENT MISREPRESENTATION,
            FUNDAMENTAL BREACH, DAMAGES OF ANY KIND, UNDER ANY LEGAL THEORY, INCLUDING ANY DIRECT, INDIRECT, SPECIAL,
            INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED TO, PERSONAL INJURY, PAIN AND
            SUFFERING, EMOTIONAL DISTRESS, LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS,
            LOSS OF USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF
            CONTRACT, BREACH OF PRIVACY OR OTHERWISE, EVEN IF THE PARTY WAS ALLEGEDLY ADVISED OR HAD REASON TO KNOW,
            ARISING OUT OF OR IN CONNECTION WITH YOUR USE, OR INABILITY TO USE, RELIANCE ON, THE WEBSITE, ANY LINKED
            WEBSITES OR SUCH OTHER THIRD PARTY WEBSITES, NOR ANY SITE CONTENT, MATERIALS, POSTING OR INFORMATION THEREON.
            <br><br>
            <span class="legal-subtitle">15. Indemnification</span>
            <br>
            1. To the maximum extent permitted by applicable law, you agree to defend, indemnify, and hold harmless the
            Company, its affiliates, and their respective directors, officers, employees, agents, service providers,
            contractors, licensors, suppliers, successors, and assigns from and against any claims, liabilities, damages,
            judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or
            relating to your breach of these Terms and Conditions or your use of the Website, including, but not limited
            to, your User Submissions, third party sites, any use of the Website's content, services, and products other
            than as expressly authorized in these Terms and Conditions.
            <br><br>
            <span class="legal-subtitle">16. Governing Law and Jurisdiction</span>
            <br>
            1. The Website and these Terms and Conditions will be governed by and construed in accordance with the laws
            of the Province of Saskatchewan and the federal laws of Canada applicable therein, without giving effect to
            any choice or conflict of law provision, principle or rule (whether of the laws of the Province of Saskatchewan
            or any other jurisdiction) and notwithstanding your domicile, residence or physical location.
            <br>
            2. Any action or proceeding arising out of[or relating to this Website and under these Terms and Conditions
            will be instituted in the courts of the Province of Saskatchewan and/or the Federal Court of Canada, and
            each party irrevocably submits to the exclusive jurisdiction of such courts in any such action or proceeding.
            You waive any and all objections to the exercise of jurisdiction over you by such courts and to the venue
            of such courts.
            <br><br>
            <span class="legal-subtitle">17. Waiver</span>
            <br>
            1. No waiver under these Terms and Conditions is effective unless it is in writing and signed by an
            authorized representative of the party waiving its right. No failure to exercise, or delay in exercising,
            any right, remedy, power or privilege arising from these Terms and Conditions operates, or may be construed,
            as a waiver thereof. No single or partial exercise of any right, remedy, power or privilege hereunder
            precludes any other or further exercise thereof or the exercise of any other right, remedy, power or privilege.
            <br><br>
            <span class="legal-subtitle">18. Severability</span>
            <br>
            1. If any term or provision of these Terms and Conditions is invalid, illegal or unenforceable in any
            jurisdiction, such invalidity, illegality or unenforceability shall not affect any other term or provision
            of these Terms and Conditions or invalidate or render unenforceable such term or provision in any other
            jurisdiction.
            <br><br>
            <span class="legal-subtitle">19. Entire Agreement</span>
            <br>
            1. The Terms and Conditions and our Privacy Policy constitute the sole and entire agreement between you and
            StoreToDoor Technologies Inc. regarding the Website and supersedes all prior and contemporaneous understandings,
            agreements, representations and warranties, both written and oral, regarding such subject matter.
            <br><br>
            <span class="legal-subtitle">20. Reporting and Contact</span>
            <br>
            1. This website is operated by StoreToDoor Technologies Inc., {{getAddress()}}.
            <br>
            2. Should you become aware of misuse of the website including libelous or defamatory conduct, you must
            report it to the Company at support@storetodoorcanada.com.
            <br>
            3. All other feedback, comments, requests for technical support, and other communications relating to the
            Website should be directed to support@storetodoorcanada.com.
            <br><br>
            Dated Effective August 16, 2021
        </div>
    </div>
    <div class="sticky-footer-container modal-footer-flex-end pt-3">
        <button (click)="cancel()"
                class="custom-button neutral-button mr-3" i18n>
            Sign Out
        </button>
        <button (click)="continue()"
                class="custom-button preferred-button" i18n>
            I Agree
        </button>
    </div>
</div>
