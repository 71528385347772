import {MatDateFormats} from "@angular/material/core";
import {DateTimeFormat} from "./date-time.format";

export const DATE_INPUT_FORMAT = DateTimeFormat.YearMonthDay_hyphen;
export const STD_DATE_FORMATS: MatDateFormats = {
    parse: {
        dateInput: DATE_INPUT_FORMAT,
    },
    display: {
        dateInput: DATE_INPUT_FORMAT,
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};