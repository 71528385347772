import { Component } from '@angular/core';
import {BaseIconComponent} from '../base-icon-component/base-icon-component';

@Component({
  selector: 's2d-icon-plus-circle-solid',
  templateUrl: `./plus-circle-solid.html`,
  styleUrls: ['./plus-circle-solid.scss'],
})
export class PlusCircleSolidIconComponent extends BaseIconComponent {
}
